<template lang="html">
  <div class="">
    <Navbar />
    <main>
      <Alert :success="destination.success.length != 0" :fail="destination.failed.length != 0" />
      <div class="topbar has-image">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-8 col-md-6">
              <div class="d-flex align-items-md-center">
                <div class="my-2 mr-4 mr-md-5">
                  <button @click="$router.go(-1)" class="btn bordered-custom-round btn-round" type="button">
                    <span class="sr-only">Zpět</span>
                    <img src="../assets/img/ico-back.svg" alt="Zpět" class="mx-auto" />
                  </button>
                </div>
                <div v-if="destination.temp.Fetched == true" class="">
                  <div :key="d.name" v-for="d in destinationData">
                    <h1 class="mb-1">{{ d.name }}</h1>
                    <p style="font-weight: 600">
                      <flag :squared="false" class="flag flag-lg" :iso="`${d.iso}`" />
                      {{ d.country }}
                    </p>
                    <div class="d-md-none">
                      <div
                        v-if="destination.Deleting"
                        style=""
                        class="spinner-border spinner-border-sm myspinner mr-1 mt-3"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <button
                        @click.prevent="$_deletefun"
                        class="btn bordered-custom-round btn-round mt-3 mr-2"
                        type="button"
                      >
                        <span class="sr-only">Delete</span>
                        <img width="20px" src="../assets/img/delete.svg" alt="Delete" class="mx-auto" />
                      </button>
                      <router-link :to="`/destination/update/${d.id}`">
                        <button class="btn btn-primary btn-round d-md-none mt-3" type="button">
                          <span class="sr-only font-weight-bold">Editovat destinaci</span>
                          <img src="../assets/img/ico-edit-white.svg" alt="Editovat" class="mx-auto" />
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 d-none d-md-block col-md-6 order-lg-last text-md-right">
              <div
                v-if="destination.Deleting"
                style=""
                class="spinner-border spinner-border-sm myspinner mr-1"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <button
                v-if="!loadingExport"
                @click.prevent="export2File()"
                class="btn bordered-custom-round btn-round mr-2"
                type="button"
              >
                <span class="sr-only">Exportovat</span>
                <img width="20px" src="../assets/img/ico-attachment.svg" alt="Export" class="mx-auto" />
              </button>
              <div v-if="loadingExport" class="d-inline-block mr-2">
                <div style="" class="spinner-border spinner-border-sm myspinner mr-1 mt-3" role="status"></div>
                <small class="">Exportuji..</small>
              </div>
              <button @click.prevent="$_deletefun" class="btn bordered-custom-round btn-round mr-2" type="button">
                <span class="sr-only">Delete</span>
                <img width="20px" src="../assets/img/delete.svg" alt="Delete" class="mx-auto" />
              </button>
              <router-link
                v-if="destination.temp.Fetched === true"
                :to="`/destination/update/${destination.temp.data[0].id}`"
              >
                <button style="" type="button" class="btn btn-primary d-none d-md-inline-block button-style-round">
                  <span class="">Editovat destinaci</span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row align-items-center mb-2">
          <div class="col-md-9 col-7 d-none d-md-block">
            <h2 class="mb-2">Seznam termínů</h2>
          </div>
          <div class="col-md-3 text-right">
            <p style="font-weight: 600" class="small mb-1">
              <img src="../assets/img/ico-items.svg" alt="Celkem" class="mr-2" />
              {{ this.term.current.data.length }}&nbsp;termínů
            </p>
          </div>
        </div>
        <div class="card">
          <ul class="nav pseudotable flex-column">
            <li class="nav-item pseudoheader">
              <span class="form-row">
                <span class="col-sm-3 table-head">Kód</span>
                <span class="col-sm-4 table-head">Datum</span>
                <span class="col-sm-5 text-right table-head">Účastníci</span>
              </span>
            </li>
            <li v-if="this.term.current.Fetching" class="d-flex justify-content-center">
              <div class="spinner-border myspinner" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </li>
            <ul v-else v-for="term in terms" :key="term.id" class="list-unstyled">
              <li class="nav-item">
                <router-link
                  class="row form-row nav-link"
                  :to="`/term/${term.id}`"
                  :style="[term.custom ? { 'background-color': 'rgba(217, 217, 217, 0.56)' } : {}]"
                >
                  <div class="col-sm-3">
                    {{ term.code }}
                  </div>
                  <div class="col-sm-4">
                    <span
                      :style="`--background:SlateBlue;`"
                      :data-letters="`i`"
                      class="col-md col-sm-6 my-text-bold"
                      v-if="term.custom"
                    ></span>
                    <span :data-letters="`i`" class="col-md col-sm-6 my-text-bold" v-if="!term.custom"></span>
                    <span class="my-text-bold">
                      {{ changeDateFormatTo(term.startDate) }} - {{ changeDateFormatTo(term.endDate) }}
                    </span>
                  </div>
                  <div class="col-sm-5 text-right">
                    <span class="my-text-bold">
                      <span class="persons">
                        <span v-if="term.noOfParticipants <= 10">
                          <img src="../assets/img/ico-person.svg" alt="" />
                        </span>
                        <span v-if="term.noOfParticipants > 10">
                          <img src="../assets/img/ico-person.svg" alt="" />
                          <img src="../assets/img/ico-person.svg" alt="" />
                        </span>
                        <span v-if="term.noOfParticipants > 21">
                          <img src="../assets/img/ico-person.svg" alt="" />
                          <img src="../assets/img/ico-person.svg" alt="" />
                          <img src="../assets/img/ico-person.svg" alt="" />
                        </span>
                      </span>
                      {{ term.noOfParticipants }}
                    </span>
                  </div>
                </router-link>
              </li>
            </ul>
            <li v-if="this.term.current.data.length == 0">
              <span>Žádný nenalezen</span>
            </li>
          </ul>
        </div>
        <p class="text-center d-sm-none mt-4">
          <a href="">
            <img src="../assets/img/ico-logout.svg" alt="Odhlásit se" class="mr-2" />
            Odhlásit se
          </a>
        </p>
      </div>
    </main>
  </div>
</template>

<script>
/* eslint-disable */
import Navbar from '@/components/Navbar.vue'
import Alert from '@/components/Alert.vue'
import '../assets/js/jquery.min.js'
import '../assets/js/bootstrap.bundle.min.js'
import { mapState, mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import { saveAs } from 'file-saver'
import { config } from '@/services/config.js'

export default {
  components: {
    Navbar,
    Alert,
  },
  data() {
    return {
      loadingExport: false,
    }
  },

  computed: {
    ...mapState({ destination: 'destination' }),
    ...mapState({ term: 'term' }),
    getTitle() {
      if (this.destination.temp.data[0]) {
        return this.destination.temp.data[0].name
      }
    },
    destinationData() {
      return this.destination.temp.data
    },

    terms() {
      let code = decodeURIComponent(this.$router.currentRoute.params.code)
      if (code === 'code') {
        return this.term.current.data.filter((t) => !t.code)
      } else return this.term.current.data.filter((t) => t.code === code)
    },

    /* termsSortedByCode() {
      let codes = new Array()

      this.terms.forEach((term) => {
        let idx = codes.findIndex((c) => c.code == term.code)
        if (idx === -1) {
          codes.push({
            code: term.code,
            terms: [term],
          })
        } else {
          const termIdx = codes[idx].terms.findIndex(
            (t) => t.startDate == term.startDate && t.endDate == term.endDate
          )
          if (termIdx !== -1) {
            codes[idx].terms[termIdx].noOfParticipants += term.noOfParticipants
          } else {
            codes[idx].terms.push(term)
          }
        }
      })

      // sort
      codes.sort((a, b) => {
        if (a.code == null) return -1
        if (b.code == null) return 1

        if (a.code < b.code) {
          return -1
        }
        if (a.code > b.code) {
          return 1
        }

        // names must be equal
        return 0
      })

      codes.forEach((c) =>
        c.terms.sort((a, b) => {
          if (a.startDate < b.startDate) {
            return -1
          }
          if (a.startDate > b.startDate) {
            return 1
          }

          // names must be equal
          return 0
        })
      )

      return codes
    },
  */
  },

  created() {
    document.title = this.getTitle
    const destinationID = this.$router.currentRoute.params.id
    this.clearmsg()
    this.getDestinationById(destinationID)
    this.getListById(destinationID)
  },

  mounted() {},

  watch: {
    getTitle() {
      document.title = this.destination.temp.data[0].name
    },
  },

  methods: {
    getAuthToken() {
      const userData = window.localStorage.getItem('user')
      const user = userData ? JSON.parse(userData) : null
      if (user) {
        return `Bearer ${user.data.api_token}`
      }
    },

    getBasicHeaders() {
      return {
        headers: {
          Accept: 'application/json',
          Authorization: this.getAuthToken(),
        },
      }
    },

    getRandomColor() {
      const colors = [
        'Tomato',
        'Orange',
        'DodgerBlue',
        'MediumSeaGreen',
        'Gray',
        'SlateBlue',
        'Violet',
        'LightGray',
      ]
      return colors[Math.floor(Math.random() * colors.length)]
    },
    ...mapActions('destination', [
      'create',
      'get_All_Destination',
      'getDestinationById',
      '_delete',
      'update',
      'clearmsg',
    ]),
    ...mapActions('term', ['get_All_Term', 'getListById']),
    $_deletefun() {
      const destinationID = this.$router.currentRoute.params.id
      this._delete(destinationID)
    },
    async export2File() {
      this.loadingExport = true
      var desdtionation_id = this.destination.temp.data[0].id
      const requestOptions = {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Accept:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Authorization: this.getAuthToken(),
        },
      }
      const res = await axios
        .get(
          `${config.getUrl}/destination/export/${desdtionation_id}`,
          requestOptions
        )
        .then((response) => {
          //  console.log(response)
          this.forceFileDownload(response)
          this.loadingExport = false
        })
        .catch((e) => {
          this.loadingExport = false
        })
    },
    forceFileDownload(response) {
      let timestamp = Date.now()
      let filename = `destionationExport-${timestamp}.xlsx`
      var blob = new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      saveAs(blob, filename)
    },
    changeDateFormatTo(date) {
      const [dd, mm, yy] = date.split(/-/g)
      return `${yy}. ${mm}. ${dd}`
    },
  },
}
</script>

<style lang="css" scoped>
.bordered-custom-round {
  background-color: white;
  color: black;
  border: 2px solid #da4c43;
  border-radius: 20px !important;
  font-weight: bold;
}
</style>
