import axios from 'axios'
import { config } from './config.js'
export const invoiceService = {
  create,
  getAll,
  getListById,
  getInvoiceById,
  update,
  delete: _delete,
}

function getAuthToken() {
  const userData = window.localStorage.getItem('user')
  const user = userData ? JSON.parse(userData) : null
  if (user) {
    return `Bearer ${user.data.api_token}`
  }
}

function getBasicHeaders() {
  return {
    headers: {
      Accept: 'application/json',
      Authorization: getAuthToken(),
    },
  }
}

async function getAll(id) {
  try {
    const res = await axios.get(`${config.getUrl}/invoice/list/${id}`, getBasicHeaders())
    console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function create(invoice) {
  //  console.log(invoice, '@@@')
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
    },
    ...invoice,
  }
  // console.log(requestOptions,'@@@')
  try {
    const res = await axios.post(`${config.setUrl}/invoice/create`, requestOptions, getBasicHeaders())
    //  console.log(res.data)
    if (typeof res.data.public_html_url !== 'undefined') {
      return res.data
    } else {
      return Promise.reject(new Error('Failed to create invoice'))
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

async function getListById(id) {
  try {
    const res = await axios.get(`${config.getUrl}/invoice/list/${id}`, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function getInvoiceById(id) {
  try {
    const res = await axios.get(`${config.getUrl}/invoice/${id}`, getBasicHeaders())
    //    console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function update(invoice) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
    },
    ...invoice,
  }
  //  console.log(invoiceType,'@@@')
  try {
    const res = await axios.put(`${config.setUrl}/invoice/${invoice._id}`, requestOptions, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
async function _delete(id) {
  try {
    const res = await axios.get(`${config.getUrl}/invoice/delete/${id}`, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}
