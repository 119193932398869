import axios from 'axios'
import { config } from './config.js'
export const addressService = {
  create,
  getAll,
  getBaseAll,
  getById,
  update,
  delete: _delete,
}

function getAuthToken() {
  const userData = window.localStorage.getItem('user')
  const user = userData ? JSON.parse(userData) : null
  if (user) {
    return `Bearer ${user.data.api_token}`
  }
}

function getBasicHeaders() {
  return {
    headers: {
      Accept: 'application/json',
      Authorization: getAuthToken(),
    },
  }
}

async function getAll() {
  try {
    const res = await axios.get(`${config.getUrl}/address/list`, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function getBaseAll() {
  try {
    const res = await axios.get(`${config.getUrl}/address/base-list`, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function create(address) {
  //  console.log(address, '@@@')
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
    },
    ...address,
  }
  //  console.log(addressType,'@@@')
  try {
    const res = await axios.post(`${config.setUrl}/contact/add`, requestOptions, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    //    console.log(error.response.data)
    return Promise.reject(error)
  }
}

async function getById(id) {
  try {
    const res = await axios.get(`${config.getUrl}/address/${id}`, getBasicHeaders())
    // console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function update(address) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
    },
    ...address,
  }
  //  console.log(addressType,'@@@')
  try {
    const res = await axios.put(`${config.setUrl}/contact/update/`, requestOptions, getBasicHeaders())
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
async function _delete(id) {
  try {
    const res = await axios.delete(`${config.getUrl}/contact/${id}`, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}
