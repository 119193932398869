var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"fixed-top"},[_c('nav',{staticClass:"navbar navbar-expand-md navbar-dark"},[_c('div',{staticClass:"container"},[_c('router-link',{staticClass:"navbar-brand d-none d-sm-block mr-5",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../assets/img/barel-logo-small.svg"),"alt":"barel"}})]),_c('ul',{staticClass:"navbar-nav nav-fill flex-row mr-auto"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active:
                (this.$route.name == 'Home')
                  | (this.$route.name == 'DestinationDetail')
                  | (this.$route.name == 'TermDetail') ||
                this.$route.name == 'ParticipantDetails' ||
                this.$route.name == 'EditParticipant' ||
                this.$route.name == 'EmailParticipant' ||
                this.$route.name == 'AddDestination' ||
                this.$route.name == 'EditDestination',
            },attrs:{"to":"/"}},[_c('span',{staticClass:"text-capitalize"},[_vm._v("Destinace")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",class:{
              active:
                (this.$route.name == 'Address')
                  | (this.$route.name == 'AddressDetail')
                  | (this.$route.name == 'EditAddress') || this.$route.name == 'EmailContact',
            },attrs:{"to":"/address"}},[_c('span',{staticClass:"text-capitalize"},[_vm._v("Adresář")])])],1)]),_c('div',{staticClass:"dropdown d-none d-sm-block ml-auto"},[_c('a',{staticClass:"btn-user dropdown-toggle",staticStyle:{"color":"white"},attrs:{"role":"button","id":"dropdownUser","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('img',{staticClass:"profile-img",attrs:{"src":_vm.account.user.data.profile_pic_url,"onerror":"this.onerror=null;this.src='../assets/img/profile.jpg';"}}),_c('span',{staticClass:"d-none d-lg-inline",attrs:{"id":"user-name"}},[_vm._v(_vm._s(_vm.account.user.data.name))])]),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"dropdownUser"}},[_c('div',{staticClass:"dropdown-items"},[_c('button',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.$_logout()}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("../assets/img/ico-logout.svg"),"alt":"Odhlásit se"}}),_vm._v(" Odhlásit se ")])])])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }