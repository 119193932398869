<template>
  <div :class="{ loader: true, fadeout: !isLoading }"><img src="../assets/img/barel-logo.svg" /></div>
</template>

<script>
export default {
  name: 'LoadingScreen',
  props: ['isLoading'],
}
</script>

<style>
.loader {
  background-color: white;
  bottom: 0;
  color: orange;
  display: block;
  font-size: 32px;
  left: 0;
  overflow: hidden;
  position: fixed;
  padding-top: 2em;
  right: 0;
  font-family: 'arial';
  text-align: center;
  top: 0;
}

.fadeout {
  animation: fadeout 1s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
