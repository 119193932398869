<template>
  <div>
    <div v-if="success" class="alert alert-success alert-dismissible fade show mb-0" role="alert">
      <div class="container custom-alert">
        <img src="../assets/img/ico-alert-success.svg" alt="Success" />
        <strong>Tak tohle se moc povedlo.</strong>
        Jste skvělí!
        <button type="button" class="close custom-alert-button" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div v-if="fail" class="alert alert-danger alert-dismissible fade show mb-0" role="alert">
      <div class="container custom-alert">
        <img src="../assets/img/ico-alert-danger.svg" alt="Error" />
        <strong>Tak tohle se moc nepovedlo.</strong>
        Zadejte to znovu.
        <button type="button" class="close custom-alert-button" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Alert',
  props: {
    success: Boolean,
    fail: Boolean,
  },
}
</script>
