import { participantService } from '../services'
import router from '../router.js'
const state = {
  participants: {
    data: [],
    Fetching: '',
    Fetched: 'false',
  },
  current: {
    data: [],
    Fetching: '',
    Fetched: 'false',
  },
  temp: {
    data: [],
    Fetching: null,
    Fetched: null,
  },
  emailList: {
    data: [],
    Fetching: 'true',
    Fetched: 'false',
  },
  comment: {
    data: [],
    Fetching: null,
    Fetched: null,
  },
  contract: {
    data: [],
    Fetching: null,
    Fetched: null,
  },
  email: {
    data: [],
    Fetching: null,
    Fetched: null,
  },
  template: {
    data: [],
    Fetching: null,
    Fetched: null,
  },
  msg: '',
  success: '',
  failed: '',
  Fetching: null,
  Fetched: null,
  Deleted: null,
  Deleting: null,
  uncompleted: {
    data: [],
    Fetching: null,
    Fetched: null,
  },
}

const actions = {
  clearmsgPar({ commit }) {
    commit('clearAllMsg')
  },
  getTemplates({ commit }) {
    participantService.getTemplates().then(
      template => commit('successTemplate', template),
      error => commit('failureTemplate', error)
    )
  },
  getUncomplete({ commit }) {
    participantService.getUncomplete().then(
      participant => commit('successUncomplete', participant),
      error => commit('failureUncomplete', error)
    )
  },
  sendEmail({ commit }, email) {
    commit('FetchRequestEmail')
    participantService.sendEmail(email).then(
      email => commit('SuccessEmail', email),
      error => commit('FailureEmail', error)
    )
  },
  get_All_Contract({ commit }, id) {
    commit('FetchRequestContract')

    participantService.getAllContract(id).then(
      contract => commit('SuccessContract', contract),
      error => commit('FailureContract', error)
    )
  },
  get_All_Comment({ commit }, id) {
    commit('FetchRequestComment')

    participantService.getAllComment(id).then(
      comment => commit('SuccessComment', comment),
      error => commit('FailureComment', error)
    )
  },
  createComment({ commit }, comment) {
    commit('FetchRequestComment')
    participantService
      .createComment(comment)
      .then(newcomment =>
        participantService.getAllComment(comment.participant_id).then(
          newcomment => commit('SuccessComment', newcomment),
          error => commit('FailureComment', error)
        )
      )
      .catch(error => commit('FailureComment', error))
    //  error => console.log(error.toString())
  },
  createContract({ commit }, contract) {
    commit('FetchRequestContract')
    participantService
      .createContract(contract)
      .then(newcontract =>
        participantService.getAllContract(contract.id).then(
          newcontract => commit('SuccessContract', newcontract),
          error => commit('FailureContract', error)
        )
      )
      .catch(error => commit('FailureComment', error))
    //  error => console.log(error.toString())
  },
  get_All_Participant({ commit }) {
    commit('getAllRequest')

    participantService.getAll().then(
      participant => commit('getAllSuccess', participant),
      error => commit('getAllFailure', error)
    )
  },
  getListById({ commit }, id) {
    commit('getParticipantRequest')

    participantService.getListById(id).then(
      participant => commit('getCurrentSuccess', participant),
      error => commit('getCurrentFailure', error)
    )
  },

  getParticipantById({ commit }, id) {
    commit('getParticipantRequest')

    participantService.getParticipantById(id).then(
      participant => {
        //  commit('updateEmailList', participant),
        commit('getCurrentParticipantSuccess', participant)
      },
      error => commit('getCurrentFailure', error)
    )
  },

  update({ commit }, participant) {
    commit('FetchRequest')
    const id = participant.id
    participantService.update(participant).then(
      participant => {
        commit('successMsg')
        router.push(`/participant/${id}`)
      },
      error => commit('failedMsg', error)

      // error => console.log(error.toString())
    )
  },
  create({ commit }, participant) {
    commit('FetchRequest')
    participantService.create(participant).then(
      participant => {
        commit('successMsg')
        router.push(`/participant/${participant.id}`)
      },
      error => commit('failedMsg', error)
    )
    //  error => console.log(error.toString())
  },
  _delete({ commit }, payload) {
    commit('deleteRequest')

    participantService.delete(payload.id).then(
      participant => {
        commit('deleteParticipantSuccess', payload.id)
        router.push(`/`)
      },
      error => commit('deleteParticipantFailure', error)
    )
  },
  _storno({ commit }, participantID) {
    participantService.storno(participantID).then(
      // update particopant
      participant => {
        //commit("deleteParticipantSuccess", payload.id);
        //router.push(`/`);
      },
      error => commit('deleteParticipantFailure', error)
    )
  },
  _deleteComment({ commit }, id) {
    //  commit('deleteRequest', id)

    participantService._deleteComment(id).then(
      participant => commit('DeleteComment', id)
      // error => console.log(error)
    )
  },
}
/* eslint-disable*/
const mutations = {
  clearAllMsg(state) {
    (state.success = ""),
      (state.failed = ""),
      (state.msg = ""),
      (state.Fetched = null),
      (state.Fetching = null),
      (state.temp.Fetched = null),
      (state.temp.Fetching = null),
      (state.Deleted = null),
      (state.Deleting = ""),
      (state.comment.Fetched = null),
      (state.comment.Fetching = null);
    (state.contract.Fetched = null), (state.contract.Fetching = null);
  },
  FetchRequestContract(state) {
    (state.contract.Fetched = false), (state.contract.Fetching = true);
  },
  FetchRequestComment(state) {
    (state.comment.Fetched = false), (state.comment.Fetching = true);
  },
  FetchRequest(state) {
    (state.Fetched = false), (state.Fetching = true);
  },
  FetchRequestEmail(state) {
    (state.email.Fetched = false), (state.email.Fetching = true);
  },
  SuccessEmail(state, email) {
    //    state.success = "Request was successfull, Created successfully",
    (state.email.Fetched = true),
      (state.email.Fetching = false),
      (state.email.data = { ...email }),
      (state.success = "Request was successfull, Created successfully");
  },
  FailureEmail(state, error) {
    //  state.failed = "Request was unsuccessfull, Failed",
    (state.email.Fetched = true),
      (state.email.Fetching = false),
      (state.failed = "Request was unsuccessfull, Failed");
  },
  successTemplate(state, template) {
    (state.template.Fetched = true),
      (state.template.Fetching = false),
      (state.template = { data: template });
  },
  failureTemplate(state, error) {
    (state.template.Fetched = true), (state.template.Fetching = false);
  },
  successUncomplete(state, participants) {
    (state.uncompleted.Fetched = true),
      (state.uncompleted.Fetching = false),
      (state.uncompleted = { data: participants });
  },
  failureUncomplete(state, error) {
    (state.uncompleted.Fetched = true), (state.uncompleted.Fetching = false);
  },
  SuccessContract(state, contract) {
    //    state.success = "Request was successfull, Created successfully",
    (state.contract.Fetched = true),
      (state.contract.Fetching = false),
      (state.contract.data = [...contract]);
  },
  FailureContract(state, error) {
    //  state.failed = "Request was unsuccessfull, Failed",
    (state.contract.Fetched = true), (state.contract.Fetching = false);
  },
  SuccessComment(state, comment) {
    //    state.success = "Request was successfull, Created successfully",
    (state.comment.Fetched = true),
      (state.comment.Fetching = false),
      (state.comment = { ...comment });
  },
  FailureComment(state, error) {
    //  state.failed = "Request was unsuccessfull, Failed",
    (state.comment.Fetched = true), (state.comment.Fetching = false);
  },
  successMsg(state) {
    (state.success = "Request was successfull, Created successfully"),
      (state.Fetched = true),
      (state.Fetching = false);
  },
  failedMsg(state, error) {
    (state.failed = error.response.data),
      (state.Fetched = true),
      (state.Fetching = false);
  },
  getAllRequest(state) {
    (state.participants.Fetched = false), (state.participants.Fetching = true);
  },
  getParticipantRequest(state) {
    (state.current.Fetched = false), (state.current.Fetching = true);
  },
  getAllSuccess(state, participant) {
    (state.participants = { data: participant }),
      (state.participants.Fetched = true),
      (state.participants.Fetching = false),
      (state.emailList = { ...participant }),
      (state.msg = "Request is successfull.");
  },
  getCurrentSuccess(state, participant) {
    (state.current = { ...participant }),
      (state.current.Fetched = true),
      (state.current.Fetching = false),
      (state.emailList = { ...participant });
  },
  getCurrentParticipantSuccess(state, participant) {
    (state.temp = { ...participant }),
      (state.temp.Fetched = true),
      (state.temp.Fetching = false),
      (state.emailList = { ...participant });
  },
  getCurrentFailure(state, error) {
    state.msg = { error };
  },
  getAllFailure(state, error) {
    state.msg = { error };
  },
  DeleteComment(state, id) {
    // remove deleted user from state
    state.comment.data = state.comment.data.filter(
      comment => comment.id !== id
    );
  },

  deleteParticipantSuccess(state, id) {
    // remove deleted user from state
    (state.Deleting = false),
      (state.Deleted = true),
      (state.success = "Participant is Deleted"),
      (state.msg = "Participant is Deleted."),
      (state.temp.data = state.temp.data.filter(
        participant => participant._id !== id
      ));
  },
  deleteParticipantFailure(state, error) {
    (state.msg = { error }), (state.failed = "Participant is Deleted");
    (state.Deleting = false), (state.Deleted = true);
  },
  deleteRequest(state) {
    (state.Deleting = true), (state.Deleted = false);
  },
};

export const participant = {
  namespaced: true,
  state,
  actions,
  mutations
};
