import axios from 'axios'
import { config } from './config.js'
export const destinationService = {
  create,
  getAll,
  getDestinationById,
  update,
  getAllPackages,
  delete: _delete,
}

function getAuthToken() {
  const userData = window.localStorage.getItem('user')
  const user = userData ? JSON.parse(userData) : null
  if (user) {
    return `Bearer ${user.data.api_token}`
  }
}

function getBasicHeaders() {
  return {
    headers: {
      Accept: 'application/json',
      Authorization: getAuthToken(),
    },
  }
}

async function getAllPackages(id) {
  try {
    const res = await axios.get(`${config.getUrl}/package/${id}`, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function getAll() {
  try {
    const res = await axios.get(`${config.getUrl}/destination/list`, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function create(destination) {
  //  console.log(destination, '@@@')
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
    },
    ...destination,
  }
  //  console.log(destinationType,'@@@')
  try {
    const res = await axios.post(`${config.setUrl}/destination/add`, requestOptions, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function getDestinationById(id) {
  try {
    const res = await axios.get(`${config.getUrl}/destination/${id}`, getBasicHeaders())
    // console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function update(destination) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
    },
    ...destination,
  }
  //  console.log(destinationType,'@@@')
  try {
    const res = await axios.put(`${config.setUrl}/destination/update`, requestOptions, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
async function _delete(id) {
  try {
    const res = await axios.delete(`${config.getUrl}/destination/${id}`, getBasicHeaders())
    console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}
