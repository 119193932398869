<template lang="html">
  <header class="fixed-top">
    <nav class="navbar navbar-expand-md navbar-dark">
      <div class="container">
        <router-link class="navbar-brand d-none d-sm-block mr-5" to="/">
          <img src="../assets/img/barel-logo-small.svg" alt="barel" />
        </router-link>
        <ul class="navbar-nav nav-fill flex-row mr-auto">
          <li class="nav-item">
            <router-link
              v-bind:class="{
                active:
                  (this.$route.name == 'Home')
                    | (this.$route.name == 'DestinationDetail')
                    | (this.$route.name == 'TermDetail') ||
                  this.$route.name == 'ParticipantDetails' ||
                  this.$route.name == 'EditParticipant' ||
                  this.$route.name == 'EmailParticipant' ||
                  this.$route.name == 'AddDestination' ||
                  this.$route.name == 'EditDestination',
              }"
              class="nav-link"
              to="/"
            >
              <span class="text-capitalize">Destinace</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              v-bind:class="{
                active:
                  (this.$route.name == 'Address')
                    | (this.$route.name == 'AddressDetail')
                    | (this.$route.name == 'EditAddress') || this.$route.name == 'EmailContact',
              }"
              class="nav-link"
              to="/address"
            >
              <span class="text-capitalize">Adresář</span>
            </router-link>
          </li>
        </ul>
        <div class="dropdown d-none d-sm-block ml-auto">
          <a
            style="color: white"
            class="btn-user dropdown-toggle"
            role="button"
            id="dropdownUser"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              v-bind:src="account.user.data.profile_pic_url"
              onerror="this.onerror=null;this.src='../assets/img/profile.jpg';"
              class="profile-img"
            />
            <span id="user-name" class="d-none d-lg-inline">{{ account.user.data.name }}</span>
          </a>
          <div class="dropdown-menu" aria-labelledby="dropdownUser">
            <div class="dropdown-items">
              <button @click="$_logout()" class="dropdown-item">
                <img src="../assets/img/ico-logout.svg" alt="Odhlásit se" class="mr-2" />
                Odhlásit se
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
/* eslint-disable */
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      activeClass: 'active',
    }
  },
  mounted() {
    const loggedIn = localStorage.getItem('user')
    //  console.log(loggedIn, '#$')
    if (!loggedIn) {
      this.$router.push('/login')
    }
  },
  computed: {
    ...mapState({
      account: 'account',
    }),
  },
  methods: {
    ...mapActions('account', ['logout']),
    $_logout() {
      this.logout()
    },
  },
}
</script>

<style lang="css" scoped>
#user-name {
  font-size: 14px;
  color: white;
  font-weight: bold;
}
.nav-item {
  font-weight: bold;
}
.btn-user {
  color: white;
}
.dropdown-item {
  color: white;
}
.profile-img:hover {
  cursor: pointer;
}
</style>
