<template>
  <div id="app">
    <LoadingScreen :isLoading="isLoading" />
    <div v-if="!isLoading">
      <div v-if="isOffline" style="">
        <div class="alert alert-warning fade show mb-0" role="alert">
          <span>You are not connected to the internet !</span>
        </div>
      </div>

      <router-view />
    </div>
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap');
@import './assets/css/compiled/index.css';

#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
<script>
import LoadingScreen from './components/LoadingScreen'

export default {
  name: 'App',
  components: {
    LoadingScreen,
  },
  data() {
    return { isLoading: true }
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false
    }, 3000)
  },
}
</script>
