<template lang="html">
  <div class="">
    <Navbar />
    <main>
      <Alert :success="participant.success.length !== 0" :fail="participant.failed.length !== 0" />
      <div class="topbar has-image">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-4 col-md-2 col-2">
              <div class="d-flex align-items-md-center">
                <div class="my-2 mr-4 mr-md-5">
                  <button class="btn bordered-custom-round btn-round" type="button" @click="$router.go(-1)">
                    <span class="sr-only">Zpět</span>
                    <img src="../assets/img/ico-back.svg" alt="Zpět" class="mx-auto" />
                  </button>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-4 col-10 text-lg-center">
              <h1 class="mb-1">Upravit účastníka</h1>
              <p v-if="destination.temp.data.length !== 0" class="d-md-none">
                <flag :squared="false" class="flag flag-lg" :iso="`${destination.temp.data[0].iso}`" />
                {{ destination.temp.data[0].name }}
              </p>
            </div>
            <div class="col-lg-4 col-md-6 d-none d-md-block text-right">
              <p v-if="destination.temp.data.length !== 0">
                <flag :squared="false" class="flag flag-lg" :iso="`${destination.temp.data[0].iso}`" />
                {{ destination.temp.data[0].name }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="participant.temp.Fetching" class="d-flex justify-content-center">
        <div class="spinner-border myspinner" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div v-for="item in participant.temp.data" v-else :key="item.id" class="container">
        <div class="card">
          <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form">
            <form class="card-body">
              <h2 class="card-title">1. Základní údaje a adresa</h2>
              <div class="form-row">
                <div class="col-md">
                  <fieldset class="px-3 pt-2">
                    <div class="form-group">
                      <label for="addParticipantName">Jméno a příjmení:</label>
                      <ValidationProvider v-slot="{ errors }" name="name" rules="required">
                        <vue-typeahead-bootstrap
                          id="addParticipantName"
                          ref="nameTypeahead"
                          v-model="item.name"
                          class=""
                          :data="contactData"
                          @hit="findData($event)"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label for="addParticipantEmail">E-mail:</label>
                      <ValidationProvider v-slot="{ errors }" name="email" rules="required|email">
                        <input
                          id="addParticipantEmail"
                          v-model="item.email"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label for="addParticipantPhone">Telefon:</label>
                      <ValidationProvider v-slot="{ errors }" name="phone" rules="required">
                        <input
                          id="addParticipantPhone"
                          v-model="item.phone"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label for="addParticipantBirth">Datum narození:</label>
                      <ValidationProvider v-slot="{ errors }" name="dob" rules="required">
                        <date-pick
                          v-model="item.dob"
                          :input-attributes="{ readonly: true }"
                          class=""
                          :display-format="'DD. MM. YYYY'"
                        ></date-pick>
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label for="addParticipantIdCardNum">Číslo občanského průkazu:</label>
                      <ValidationProvider v-slot="{ errors }" name="dob" rules="required">
                        <input
                          id="addParticipantIdCardNum"
                          v-model="item.id_card_number"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </fieldset>
                </div>
                <div class="col-md">
                  <fieldset class="px-3 pt-2">
                    <div class="form-group">
                      <label for="addParticipantStreet">Ulice:</label>
                      <ValidationProvider v-slot="{ errors }" name="street" rules="required">
                        <input
                          id="addParticipantStreet"
                          v-model="item.street"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label for="addParticipantCity">Město:</label>
                      <ValidationProvider v-slot="{ errors }" name="city" rules="required">
                        <input
                          id="addParticipantCity"
                          v-model="item.city"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label for="addParticipantZIP">PSČ:</label>
                      <ValidationProvider v-slot="{ errors }" name="zip" rules="required">
                        <input
                          id="addParticipantZIP"
                          v-model="item.zip"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label for="addParticipantCountry">Stát:</label>
                      <ValidationProvider v-slot="{ errors }" name="country" rules="required">
                        <select
                          id="addParticipantCountry"
                          v-model="item.country"
                          class="custom-select custom-select-sm"
                        >
                          <option disabled value="country" selected>Země</option>
                          <option v-for="country in closeCountriesData" :key="country.id" :value="`${country.name}`">
                            {{ country.human_name }}
                          </option>
                        </select>
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label for="addParticipantCountry">Státní příslušnost:</label>
                      <ValidationProvider v-slot="{ errors }" name="country" rules="required">
                        <select
                          id="addParticipantCountry"
                          v-model="item.nationality"
                          class="custom-select custom-select-sm"
                        >
                          <option disabled value="country" selected>Země</option>
                          <option v-for="country in closeCountriesData" :key="country.id" :value="`${country.name}`">
                            {{ country.human_name }}
                          </option>
                        </select>
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </fieldset>
                </div>
              </div>
              <hr class="my-4" />
              <h2 class="card-title">2. Spolucestující</h2>
              <fieldset v-for="(passenger, index) in getPassangeres(item.passengers)" :key="index" class="mt-2">
                <div class="row mt-2 px-3">
                  <div class="col-md-6 form-group">
                    <label for="addTravellerName">Jméno a příjmení:</label>
                    <ValidationProvider v-slot="{ errors }" name="participant" rules="">
                      <input
                        id="addTravellerName"
                        v-model="passenger.name"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="addTravellerBirthDate">Datum narození:</label>
                    <ValidationProvider v-slot="{ errors }" name="participant" rules="">
                      <date-pick
                        v-model="passenger.dob"
                        :input-attributes="{ readonly: true }"
                        class=""
                        :display-format="'DD. MM. YYYY'"
                      ></date-pick>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="addTravellerCardIdNumber">Číslo občanského průkazu:</label>
                    <ValidationProvider v-slot="{ errors }" name="participant" rules="">
                      <input
                        id="addTravellerCardIdNumber"
                        v-model="passenger.id_card_number"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="addTravellerStreet">Ulice:</label>
                    <ValidationProvider v-slot="{ errors }" name="street" rules="required">
                      <input
                        id="addTravellerStreet"
                        v-model="passenger.street"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="addTravellerCity">Město:</label>
                    <ValidationProvider v-slot="{ errors }" name="city" rules="required">
                      <input
                        id="addTravellerCity"
                        v-model="passenger.city"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="addTravellerZip">PSČ:</label>
                    <ValidationProvider v-slot="{ errors }" name="zip" rules="required">
                      <input
                        id="addTravellerZip"
                        v-model="passenger.zip"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="addTravellerCountry">Stát:</label>
                    <ValidationProvider v-slot="{ errors }" name="participant" rules="">
                      <select
                        id="addTravellerCountry"
                        v-model="passenger.country"
                        class="custom-select custom-select-sm"
                      >
                        <option disabled value="country" selected>Země</option>
                        <option v-for="country in closeCountriesData" :key="country.id" :value="`${country.name}`">
                          {{ country.human_name }}
                        </option>
                      </select>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="addTravellerCountry">Státní příslušnost:</label>
                    <ValidationProvider v-slot="{ errors }" name="participant" rules="">
                      <select
                        id="addTravellerCountry"
                        v-model="passenger.nationality"
                        class="custom-select custom-select-sm"
                      >
                        <option disabled value="country" selected>Země</option>
                        <option v-for="country in closeCountriesData" :key="country.id" :value="`${country.name}`">
                          {{ country.human_name }}
                        </option>
                      </select>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <img
                    class="delete-button mx-4 mb-2 ml-auto"
                    width="30px"
                    src="../assets/img/cancel.svg"
                    alt="delete"
                    @click.prevent="deletePassenger(index)"
                  />
                </div>
              </fieldset>
              <fieldset v-for="(pass, index) in pass" :key="`${index}-pass`" class="mt-2 px-3">
                <div class="row mt-2">
                  <div class="col-md-6 form-group">
                    <label for="addTravellerName">Jméno a příjmení:</label>
                    <input id="addTravellerName" v-model="pass.name" type="text" class="form-control form-control-sm" />
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="addTravellerName">Datum narození:</label>
                    <date-pick
                      v-model="pass.birth_date"
                      :input-attributes="{ readonly: true }"
                      class=""
                      :display-format="'DD. MM. YYYY'"
                    ></date-pick>
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="addTravellerIdCardNum">Číslo občanského průkazu:</label>
                    <input
                      id="addTravellerIdCardNum"
                      v-model="pass.id_card_number"
                      type="text"
                      class="form-control form-control-sm"
                    />
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="addTravellerStreet">Ulice:</label>
                    <input
                      id="addTravellerStreet"
                      v-model="pass.street"
                      type="text"
                      class="form-control form-control-sm"
                    />
                  </div>
                  <div class="col-md-6 form-group pr-md-5">
                    <label for="addTravellerCity">Město:</label>
                    <input id="addTravellerCity" v-model="pass.city" type="text" class="form-control form-control-sm" />
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="addTravellerZip">PSČ:</label>
                    <input id="addTravellerZip" v-model="pass.zip" type="text" class="form-control form-control-sm" />
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="addTravellerCountry">Stát:</label>
                    <select id="addTravellerCountry" v-model="pass.country" class="custom-select custom-select-sm">
                      <option disabled value="country" selected>Země</option>
                      <option v-for="country in closeCountriesData" :key="country.id" :value="`${country.name}`">
                        {{ country.human_name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="addTravellerCountry">Státní příslušnost:</label>
                    <select id="addTravellerCountry" v-model="pass.nationality" class="custom-select custom-select-sm">
                      <option disabled value="country" selected>Státní příslušnost</option>
                      <option v-for="country in closeCountriesData" :key="country.id" :value="`${country.name}`">
                        {{ country.human_name }}
                      </option>
                    </select>
                  </div>
                  <img
                    class="delete-button mx-4 mb-2 ml-auto"
                    width="30px"
                    src="../assets/img/cancel.svg"
                    alt="delete"
                    @click.prevent="deleteRowPass(index)"
                  />
                </div>
              </fieldset>
              <a class="link-new my-3 ml-3 ml-sm-4" @click.prevent="addRowPass">
                <span style="font-weight: 400; font-size: 16px" class="text-primary">
                  přidat dalšího spolucestujícího
                </span>
                &nbsp;&nbsp;
                <img src="../assets/img/ico-new.svg" alt="přidat další Termín" />
              </a>
              <hr class="my-4" />
              <h2 class="card-title">3. Termíny, balíčky</h2>
              <!-- aktualně přidaně balíčky -->
              <fieldset v-for="(termdata, index) in item.terms" :key="`${index}-term`" class="mb-2">
                <div class="row">
                  <div class="col-lg-6 col-md-9 d-sm-flex align-items-center pr-md-5">
                    <strong class="tabs-title pl-3">{{ index + 1 }}. termín</strong>
                  </div>
                  <div class="col-md-1 ml-auto pt-3 text-right pr-4">
                    <img
                      style="margin: 12px"
                      class="delete-button"
                      width="30px"
                      src="../assets/img/cancel.svg"
                      alt="delete"
                      @click.prevent="deleteTerm(index)"
                    />
                  </div>
                </div>

                <hr class="dotted" />
                <div class="tab-content px-3">
                  <div v class="row">
                    <div class="col-md-6 pr-md-5">
                      <div v-if="termdata.custom === 1" class="form-group">
                        <div class="">
                          <label for="">Kód:</label>
                          <input
                            v-model="termdata.code"
                            :disabled="termdata.custom !== 1"
                            type="text"
                            class="form-control form-control-sm"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 pr-md-5">
                      <div class="form-group">
                        <label for="addParticipantDate01SelectDate">Datum:</label>

                        <!-- <input
                          v-if="termdata.custom"
                          type="text"
                          disabled
                          class="form-control form-control-sm"
                          :value="
                            `${changeDateFormatTo(
                              termdata.startDate
                            )} - ${changeDateFormatTo(termdata.endDate)}`
                          "
                        /> -->
                        <div v-if="termdata.custom" class="form-row">
                          <div class="col-sm form-group">
                            <label for="addParticipantDate02DefineFrom">Datum od:</label>
                            <date-pick
                              v-model="termdata.startDate"
                              :input-attributes="{ readonly: true }"
                              class=""
                              :display-format="'DD. MM. YYYY'"
                            ></date-pick>
                          </div>
                          <div class="col-sm form-group">
                            <label for="addParticipantDate02DefineTo">Datum do:</label>
                            <date-pick
                              v-model="termdata.endDate"
                              :input-attributes="{ readonly: true }"
                              class=""
                              :display-format="'DD. MM. YYYY'"
                            ></date-pick>
                          </div>
                        </div>
                        <select
                          v-else
                          id="addParticipantDate01SelectDate"
                          v-model="termdata.term_id"
                          class="custom-select custom-select-sm"
                        >
                          <option v-for="term1 in getTerms()" :key="'T' + term1.id" :value="`${term1.id}`">
                            {{
                              `${changeDateFormatTo(term1.startDate)} - ${changeDateFormatTo(term1.endDate)} #${
                                term1.code
                              }`
                            }}
                          </option>
                        </select>
                      </div>

                      <div class="form-group">
                        <label for="addParticipantDate01SelectVoucher">Dárkový kupón:</label>
                        <input id="" v-model="termdata.coupon" type="text" class="form-control form-control-sm" />
                      </div>
                    </div>
                    <div class="col-md-6 pl-md-5">
                      <div class="form-group">
                        <label for="addParticipantDate01SelectPackage">Balíček:</label>
                        <div v-if="termdata.custom">
                          <input v-model="termdata.package_name" type="text" class="form-control form-control-sm" />
                          <div class="form-group">
                            <label :for="`package_price-${index}`">Cena:</label>
                            <input
                              :id="`package_price-${index}`"
                              v-model="termdata.price"
                              type="text"
                              class="form-control form-control-sm"
                            />
                            <!-- <span class="error">{{ errors[0] }}</span> -->
                          </div>
                        </div>
                        <select
                          v-else
                          id="addParticipantDate01SelectPackage"
                          v-model="termdata.package_id"
                          class="custom-select custom-select-sm addParticipantDate01SelectPackage"
                          @change="updateInitPrice(index, $event)"
                        >
                          <option
                            v-for="p in destination.temp.data[0].packages.filter(p => p.custom != 1)"
                            :key="p.id"
                            :value="p.id"
                          >
                            {{ p.name }}
                            <span>{{ p.price }} Kč</span>
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="addParticipantDate01SelectTransport">Doprava:</label>
                        <select
                          id="addParticipantDate01SelectTransport"
                          v-model="termdata.transport_id"
                          class="custom-select custom-select-sm addParticipantDate01SelectTransport"
                        >
                          <option
                            v-for="transport in destination.temp.data[0].transports"
                            :key="transport.id"
                            :value="transport.id"
                          >
                            {{ transport.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <!-- pridat balicek -->
              <fieldset v-for="(term, index) in term" :key="index">
                <div class="row">
                  <div class="col-lg-6 col-md-9 d-sm-flex align-items-center pr-md-5">
                    <strong class="tabs-title pl-3">{{ index + 2 }}. termín</strong>
                    <!-- tabs -->
                    <ul :id="`formTabs${index}`" class="nav nav-tabs form-tabs flex-fill" role="tablist">
                      <li class="nav-item">
                        <a
                          :id="`date${index}-existing-tab`"
                          class="nav-link"
                          data-toggle="tab"
                          :href="`#date${index}-existing`"
                          role="tab"
                          :aria-controls="`date${index}-existing`"
                          aria-selected="false"
                        >
                          vybrat z existujících
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          :id="`date${index}-manual-tab`"
                          class="nav-link active"
                          data-toggle="tab"
                          :href="`#date${index}-manual`"
                          role="tab"
                          :aria-controls="`date${index}-manual`"
                          aria-selected="true"
                        >
                          zadat ručně
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-1 ml-auto pt-3 text-right pr-4">
                    <img
                      class="delete-button"
                      width="30px"
                      src="../assets/img/cancel.svg"
                      alt="delete"
                      @click.prevent="deleteRowTerm(index)"
                    />
                  </div>
                </div>
                <hr class="dotted" />
                <!-- existujicí -->
                <div class="tab-content px-3">
                  <div
                    :id="`date${index}-existing`"
                    class="tab-pane"
                    role="tabpanel"
                    :aria-labelledby="`date${index}-existing-tab`"
                  >
                    <div class="row">
                      <div class="col-md-6 pr-md-5">
                        <div class="form-group">
                          <label for="addParticipantDate02SelectDate">Datum:</label>
                          <select
                            id="addParticipantDate02SelectDate"
                            v-model="term.term_id"
                            class="custom-select custom-select-sm"
                          >
                            <option v-for="term3 in getTerms()" :key="term3.id" :value="`${term3.id}`">
                              {{
                                `${changeDateFormatTo(term3.startDate)} - ${changeDateFormatTo(term3.endDate)} #${
                                  term3.code
                                }`
                              }}
                            </option>
                          </select>
                        </div>

                        <div class="form-group">
                          <label for="addParticipantDate02SelectVoucher">Dárkový kupón:</label>
                          <input id="" v-model="term.coupon" type="text" class="form-control form-control-sm" />
                        </div>
                      </div>
                      <div class="col-md-6 pl-md-5">
                        <div class="form-group">
                          <label for="addParticipantDate02SelectPackage">Balíček:</label>
                          <select
                            id="addParticipantDate02SelectPackage"
                            v-model="term.package_id"
                            class="custom-select custom-select-sm"
                            @change="addValue($event, index)"
                          >
                            <option
                              v-for="p1 in destination.temp.data[0].packages.filter(p => p.custom != 1)"
                              :key="p1.id"
                              :value="p1.id"
                            >
                              {{ p1.name }}
                              <span>{{ p1.price }} Kč</span>
                            </option>
                          </select>
                        </div>
                        <div class="form-group">
                          <label for="addParticipantDate02SelectTransport">Doprava:</label>
                          <select
                            id="addParticipantDate02SelectTransport"
                            v-model="term.transport_id"
                            class="custom-select custom-select-sm"
                          >
                            <option
                              v-for="transport in destination.temp.data[0].transports"
                              :key="transport.id"
                              :value="transport.id"
                            >
                              {{ transport.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- individualni -->
                  <div
                    :id="`date${index}-manual`"
                    class="tab-pane active"
                    role="tabpanel"
                    :aria-labelledby="`date${index}-manual-tab`"
                  >
                    <div class="row">
                      <div class="col-md-6 pr-md-5">
                        <div class="form-row">
                          <div class="col-sm form-group">
                            <label for="addParticipantDate02DefineFrom">Datum od:</label>
                            <date-pick
                              v-model="term.startDate"
                              :input-attributes="{ readonly: true }"
                              class=""
                              :display-format="'DD. MM. YYYY'"
                            ></date-pick>
                          </div>
                          <div class="col-sm form-group">
                            <label for="addParticipantDate02DefineTo">Datum do:</label>
                            <date-pick
                              v-model="term.endDate"
                              :input-attributes="{ readonly: true }"
                              class=""
                              :display-format="'DD. MM. YYYY'"
                            ></date-pick>
                          </div>
                        </div>
                        <div class="form-group">
                          <div class="">
                            <label for="">Kód:</label>
                            <input v-model="term.code" type="text" class="form-control form-control-sm" required />
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="addParticipantDate02DefineVoucher">Dárkový kupón:</label>
                          <input
                            id="addParticipantDate02DefineVoucher"
                            v-model="term.coupon"
                            type="text"
                            class="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 pl-md-5">
                        <div class="form-group">
                          <label for="addParticipantDate02DefinePackage">Balíček:</label>
                          <input
                            id="addParticipantDate02DefinePackage"
                            v-model="term.packageName"
                            type="text"
                            class="form-control form-control-sm"
                          />
                        </div>
                        <div class="form-group">
                          <label for="addParticipantDate02SelectTransport">Doprava:</label>
                          <select
                            id="addParticipantDate02SelectTransport"
                            v-model="term.transport_id"
                            class="custom-select custom-select-sm"
                          >
                            <option
                              v-for="transport in destination.temp.data[0].transports"
                              :key="transport.id"
                              :value="transport.id"
                            >
                              {{ transport.name }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group">
                          <label :for="`package_price-${index}`">Cena:</label>
                          <input
                            :id="`package_price-${index}`"
                            v-model="term.price"
                            type="text"
                            class="form-control form-control-sm"
                          />
                          <!-- <span class="error">{{ errors[0] }}</span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <a class="link-new my-3 ml-3 ml-sm-4" @click.prevent="addRowTerm">
                <span style="font-weight: 400; font-size: 16px" class="text-primary">přidat další termín</span>
                &nbsp;&nbsp;
                <img src="../assets/img/ico-new.svg" alt="přidat další balíček" />
              </a>
              <!-- Overview -->
              <fieldset class="mt-4 px-3">
                <div class="row">
                  <div class="col-md-6 pr-md-5">
                    <div class="form-row price-overall">
                      <div class="col mt-2">Cena celkem:</div>
                      <div class="col text-right">
                        <strong>{{ totalItem }} Kč</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row align-items-center my-2">
                  <div class="col-md pr-md-5">
                    <div class="form-row align-items-center">
                      <div class="col-lg-9 col-sm-8 col-6">Přidat platbu:</div>
                      <div class="col-lg-3 col-sm-4 col-6">
                        <ValidationProvider v-slot="{ errors }" name="paid" rules="numeric">
                          <input
                            id="addDestinationPackagePrice"
                            v-model="item.paid"
                            type="text"
                            class="form-control form-control-sm"
                          />
                          <span class="error">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="col-md pl-md-5">
                    <div class="progress mt-5 mt-md-2 mb-2">
                      <div
                        class="progress-bar progress-bar-striped bg-info"
                        role="progressbar"
                        :style="`width: ${calcPercent(totalItem)}%`"
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <div class="form-row">
                      <p class="col-lg m-0">
                        Uhrazeno:
                        <strong>{{ calcPercent(totalItem) }}%</strong>
                      </p>
                      <p class="col-lg text-lg-right m-0">
                        Zbývá uhradit:
                        <strong>{{ calcDiff(totalItem) }} Kč</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </fieldset>
              <!-- actions -->
              <div class="row">
                <div class="col-md-3">
                  <button
                    type="button"
                    class="button-style-round btn btn-primary mt-4 ml-3 ml-sm-4 mb-3"
                    @click.prevent="$_emitdata()"
                  >
                    Uložit účastníka
                  </button>
                </div>
                <div class="col">
                  <div
                    v-if="participant.Fetching"
                    style="margin-top: 26px"
                    class="spinner-border myspinner"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <p class="text-center d-sm-none mt-4">
          <a href="">
            <img src="../assets/img/ico-logout.svg" alt="Odhlásit se" class="mr-2" />
            Odhlásit se
          </a>
        </p>
      </div>
    </main>
  </div>
</template>

<script>
/* eslint-disable */
import Navbar from '@/components/Navbar.vue'
import Alert from '@/components/Alert.vue'
import '../assets/js/jquery.min.js'
import '../assets/js/bootstrap.bundle.min.js'
import {config} from '@/services/config.js'
import {mapState, mapActions, mapGetters} from 'vuex'
import axios from 'axios'

export default {
  components: {
    Navbar,
    Alert
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },

  data() {
    return {
      selectedName: null,
      addressData: [],
      prices: [],
      initprices: [],
      prevRoute: null,
      startDate: '',
      endDate: '',
      price: 0,
      packageName: '',
      coupon: '',
      pass: [],
      term: [],
      pname: '',
      term_id: '',
      package_id: '',
      transport_id: '',
      countriesData: [],
      closeCountriesData: [],
      formData: {
        name: '',
        email: '',
        street: '',
        city: '',
        phone: '',
        dob: '',
        zip: '',
        country: '',
        paid: '',
        destinationId: '',
        participantId: '',
        passengers: [],
        terms: []
      }
    }
  },

  computed: {
    ...mapState({destination: 'destination'}),
    ...mapState({currentTerm: 'term'}),
    ...mapState({address: 'address'}),
    ...mapState({participant: 'participant'}),

    contactData() {
      const arr = []
      this.address.addresss.data.forEach((e) => arr.push(`${e.name} #${e.id}`))
      //      console.log(arr)
      return arr
    },
    getTitle() {
      if (typeof this.destination.temp.data[0] != 'undefined')
        return this.destination.temp.data[0].name
    },
    totalItem: function () {
      var self = this
      let sum = 0

      sum += parseFloat(this.price) || 0
      // console.log(sum)
      this.prices.forEach(function (item) {
        sum += parseFloat(item.price) || 0
        // console.log(sum)
      })
      this.initprices.forEach(function (item) {
        sum += parseFloat(item.price) || 0
      })

      //this.participant.temp.data[0].terms.forEach(function(item) {
      //sum += parseFloat(item.price) || 0
      //})
      // console.log(sum)
      this.term.forEach(function (item) {
        if (!Number.isNaN(item.price)) {
          //    console.log('NOT NaN')
          sum += parseFloat(item.price) || 0
        }
      })

      return sum
    }
  },

  created() {
    document.title = this.getTitle
    if (this.destination.temp.data.length === 0) {
      this.$router.push('/')
    }
    this.clearmsgPar()
    this.listCountries()
    this.get_All_Address()
    this.setInitPrices()
    const participantID = this.$router.currentRoute.params.id
    //  console.log(participantID)
    this.getParticipantById(participantID)
  },

  mounted() {
    // console.log(this.participant.temp.data)
    // console.log(this.$refs.nameTypeahead.inputValue)
    this.$refs.nameTypeahead.inputValue = this.participant.temp.data[0].name
    // console.log(this.$refs.nameTypeahead.inputValue)
  },

  watch: {
    getTitle() {
      if (typeof this.destination.temp.data[0] != 'undefined')
        document.title = this.destination.temp.data[0].name
    }
  },

  methods: {
    ...mapActions('participant', [
      'getParticipantById',
      'create',
      '_delete',
      'update',
      'clearmsgPar'
    ]),

    getAuthToken() {
      const userData = window.localStorage.getItem('user')
      const user = userData ? JSON.parse(userData) : null
      if (user) {
        return `Bearer ${user.data.api_token}`
      }
    },

    getBasicHeaders() {
      return {
        headers: {
          Accept: 'application/json',
          Authorization: this.getAuthToken()
        }
      }
    },

    getPassangeres(pass) {
      if (!pass) return []
      // console.log(pass)
      let passangeres = pass.filter((item) => item.name !== null)
      passangeres = passangeres.map((p) => {
        if (!p.dob && p.birth_date) {
          p.dob = p.birth_date
          delete p.birth_date
        }
        return p
      })
      // console.log(passangeres)
      return passangeres
    },
    ...mapActions('destination', ['get_All_Packages']),
    ...mapActions('address', ['get_All_Address', 'getById']),
    findData(event) {
      // <name> #<id>
      const customer_id = event.split(' #')[1]
      const userData = this.address.addresss.data.find(
        (user) => user.id == customer_id
      )

      this.participant.temp.data[0].name = userData.name
      this.participant.temp.data[0].email = userData.email
      this.participant.temp.data[0].street = userData.street
      this.participant.temp.data[0].city = userData.city
      this.participant.temp.data[0].country = userData.country
      this.participant.temp.data[0].zip = userData.zip
      this.participant.temp.data[0].phone = userData.phone
      this.participant.temp.data[0].dob = userData.dob
      this.participant.temp.data[0].nationality = userData.nationality
      this.participant.temp.data[0].id_card_number = userData.id_card_number
      this.pass = userData.passengers
    },
    calcDiff(value) {
      if (value < this.participant.temp.data[0].paid_amount) {
        return 0
      } else {
        return Math.abs(value - this.participant.temp.data[0].paid_amount)
      }
    },
    calcPercent(value) {
      if (value !== 0) {
        const result = (
          (this.participant.temp.data[0].paid_amount / value) *
          100
        ).toFixed(2)
        if (result > 100) {
          return 100
        } else {
          return result
        }
      } else {
        return 0
      }
    },
    addValue(event, key) {
      // console.log(key)
      const id = event.target.value
      const obj = this.destination.temp.data[0].packages.find((o) => o.id == id)
      if (typeof obj != 'undefined') {
        var index = this.prices.findIndex((x) => x.id == key)
        if (index === -1) {
          this.prices.push({
            id: key,
            price: obj.price
          })
        } else {
          this.prices[index].price = obj.price
        }
      }
    },
    deleteTerm(index) {
      //console.log(index)
      this.participant.temp.data[0].terms.splice(index, 1)
      this.initprices.splice(index, 1)
    },
    deletePassenger(index) {
      this.participant.temp.data[0].passengers.splice(index, 1)
    },
    $_getPackage(id) {
      // console.log(id)
      this.get_All_Packages(id)
    },
    changeDateFormatTo(date) {
      const [dd, mm, yy] = date.split(/-/g)
      return `${yy}. ${mm}. ${dd}`
    },
    async $_emitdata() {
      const isValid = await this.$refs.observer[0].validate()
      if (!isValid) {
        // console.log(await this.$refs.observer[0].validate())
        // console.log('Error')
      } else {
        //    console.log(this.term)
        this.formData = this.participant.temp.data[0]
        //    console.log(this.formData)
        this.formData.terms.push(...this.term)
        if (this.formData.passengers) {
          this.formData.passengers.push(...this.pass)
        } else {
          this.formData.passengers = []
          this.formData.passengers.push(...this.pass)
        }
        this.formData.destinationId = this.destination.temp.data[0].id
        this.formData.participantId = this.$router.currentRoute.params.id
        // console.log(this.formData)

        this.update(this.formData)

        window.scrollTo(0, 0)

        this.term = []
        this.pass = []
      }
    },
    addRowPass() {
      // console.log('add pass')
      this.pass.push({
        name: '',
        birth_date: '',
        street: '',
        city: '',
        zip: '',
        id_card_number: '',
        country: '',
        nationality: ''
      })
    },
    deleteRowPass(index) {
      this.pass.splice(index, 1)
    },

    addRowTerm() {
      this.term.push({
        startDate: '',
        endDate: '',
        coupon: '',
        package_id: '',
        transport_id: '',
        code: '',
        term_id: '',
        packageName: '',
        price: ''
      })
    },
    deleteRowTerm(index) {
      this.term.splice(index, 1)
      var key = this.prices.findIndex((x) => x.id == index)
      this.prices.splice(key, 1)
    },

    async listCountries() {
      const res = await axios.get(
        `${config.getUrl}/countries/all`,
        this.getBasicHeaders()
      )
      this.countriesData = res.data
      this.closeCountriesData = this.countriesData.filter(
        (item) => item.close == 1
      )
    },
    setInitPrices() {
      var i = 0
      this.participant.temp.data[0].terms.forEach(
        function (item) {
          this.initprices.push({
            id: i,
            price: item.price
          })
        }.bind(this)
      )
    },
    updateInitPrice(index, event) {
      const obj = this.destination.temp.data[0].packages.find(
        (o) => o.id == event.target.value
      )
      this.initprices[index].price = obj.price
    },

    getTerms() {
      const deleted_status = 4

      return this.destination.temp.data[0].terms.filter(
        (t) => t.custom != 1 && t.status != deleted_status
      )
    }
  },
}
</script>

<style lang="css" scoped>
.error {
  color: red !important;
}

.bordered-custom-round {
  background-color: white;
  color: black;
  border: 2px solid #da4c43;
  border-radius: 20px !important;
  font-weight: bold;
}
</style>
