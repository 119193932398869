import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full'
import { store } from './store'
import './registerServiceWorker'
import './../node_modules/jquery/dist/jquery.min.js'
// import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/js/bootstrap.bundle.min.js'
// import './../node_modules/bootstrap/dist/js/bootstrap.min.js'
import VueQuillEditor from 'vue-quill-editor'
import DatePick from 'vue-date-pick'
import 'vue-date-pick/dist/vueDatePick.css'
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import FlagIcon from 'vue-flag-icon'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap' // better
import VueOffline from 'vue-offline'
Vue.use(VueOffline)
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)
// Global registration
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)

Vue.use(FlagIcon)
Vue.use(VueQuillEditor /* { default global options } */)
Vue.use(Vuex, VueAxios, axios)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('DatePick', DatePick)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
