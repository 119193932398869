import { destinationService } from '../services'
import router from '../router.js'
const state = {
  destinations: {
    data: [],
    Fetching: 'true',
    Fetched: 'false',
  },
  current: {
    data: [],
    Fetching: 'true',
    Fetched: 'false',
  },
  temp: {
    data: [],
    Fetching: null,
    Fetched: null,
  },
  packages: {
    data: [],
    Fetching: 'true',
    Fetched: 'false',
  },
  msg: '',
  success: '',
  failed: '',
  Fetching: null,
  Fetched: null,
  Deleting: null,
  Deleted: null,
}

const actions = {
  clearmsg({ commit }) {
    commit('clearAllMsg')
  },
  get_All_Packages({ commit }, id) {
    commit('getAllPackagesRequest')

    destinationService.getAllPackages(id).then(
      packages => commit('getAllPackageSuccess', packages),
      error => commit('getAllFailure', error)
    )
  },

  get_All_Destination({ commit }) {
    commit('getAllRequest')

    destinationService.getAll().then(
      destination => commit('getAllSuccess', destination),
      error => commit('getAllFailure', error)
    )
  },
  getDestinationById({ commit }, id) {
    commit('getDestinationRequestTemp')
    destinationService.getDestinationById(id).then(
      destination => commit('getCurrentDestinationSuccess', destination),
      error => commit('getCurrentFailure', error)
    )
  },

  update({ commit }, destination) {
    const id = destination[0].id
    commit('FetchRequest')
    destinationService.update(destination).then(
      destination => {
        commit('successMsg')
        router.push(`/destination/${id}`)
      },
      error => commit('failedMsg', error)

      // error => console.log(error.toString())
    )
  },
  create({ commit }, destination) {
    commit('FetchRequest')
    destinationService.create(destination).then(
      destination => {
        commit('successMsg')
        //  console.log(destination)
        router.push(`/destination/${destination.id}`)
      },
      error => commit('failedMsg', error)
    )
  },
  _delete({ commit }, id) {
    commit('deleteRequest')

    destinationService.delete(id).then(
      destination => {
        commit('deleteDestinationSuccess', id)
        router.push(`/`)
      },
      error => commit('deleteDestinationFailure', error)
      // error => console.log(error)
    )
  },
}
/* eslint-disable*/
const mutations = {
  clearAllMsg(state){
    state.success = '',
    state.failed = '',
    state.msg = '',
    state.Fetched = null,
    state.Fetching = null
    state.temp.Fetched = null,
    state.temp.Fetching = null
    state.Deleted = null,
    state.Deleting = null

  },
  FetchRequest(state){
    state.Fetched = false,
    state.Fetching = true
  },
  successMsg(state){
    state.success = "Request was successfull, Created successfully",
    state.Fetched = true,
    state.Fetching = false
  },
  failedMsg(state,error){
    state.failed = "Request was unsuccessfull, Failed"
    state.Fetched = true,
    state.Fetching = false
  },
  getAllRequest (state) {
    state.destinations.Fetched =  false,
    state.destinations.Fetching =  true
  },
  getAllPackagesRequest (state) {
    state.packages.Fetched =  false,
    state.packages.Fetching =  true
  },
  getDestinationRequest (state) {
    state.current.Fetched =  false,
    state.current.Fetching =  true
  },
  getDestinationRequestTemp (state) {
    state.temp.Fetched =  false,
    state.temp.Fetching =  true
  },
  getAllPackageSuccess (state, packages) {
    state.packages = { ...packages },
    state.packages.Fetched =  true,
    state.packages.Fetching = false,
    state.msg = "Request is successfull."
  },
  getAllSuccess (state, destination) {
    state.destinations = { ...destination },
    state.destinations.Fetched =  true,
    state.destinations.Fetching = false,
    state.msg = "Request is successfull."
  },
  getCurrentSuccess (state, destination) {
    state.current = { ...destination },
    state.current.Fetched = true,
    state.current.Fetching = false
  },
  getCurrentDestinationSuccess (state, destination) {
    state.temp = { ...destination },
    state.temp.Fetched = true,
    state.temp.Fetching = false
  },
  getCurrentFailure (state, error) {
    state.msg = { error }
  },
  getAllFailure (state, error) {
    state.msg = { error }
  },
  deleteDestinationSuccess (state, id) {
    // remove deleted user from state
    state.Deleting = false,
    state.Deleted = true,
    state.success = "Destination is Deleted",
    state.msg = "Destination is Deleted.",
    state.destinations.data = state.destinations.data.filter(destination => destination._id !== id)
  },
  deleteDestinationFailure (state, error) {
    state.msg = { error },
    state.failed = "Destination is Deleted"
    state.Deleting = false,
    state.Deleted = true
  },
   deleteRequest(state){
    state.Deleting = true,
    state.Deleted = false
  },
}

export const destination = {
  namespaced: true,
  state,
  actions,
  mutations
}
