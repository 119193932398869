<template lang="html">
  <div class="">
    <Navbar />
    <main>
      <div v-if="participant.temp.Fetched == true">
        <Alert :success="participant.success.length != 0" :fail="participant.failed.length != 0" />
        <Alert :success="invoice.success.length != 0" :fail="invoice.failed.length != 0" />
        <div v-for="item in this.participant.temp.data" :key="item.id">
          <div class="topbar has-image">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="d-flex align-items-md-center">
                    <div class="my-2 mr-4 mr-md-5">
                      <button @click="$router.go(-1)" class="btn bordered-custom-round btn-round" type="button">
                        <span class="sr-only">Zpět</span>
                        <img src="../assets/img/ico-back.svg" alt="Zpět" class="mx-auto" />
                      </button>
                    </div>
                    <div>
                      <h1 class="mb-1 my-text-black">{{ item.name }}</h1>
                      <p v-if="destination.temp.data.length != 0">
                        <flag :squared="false" class="flag flag-lg" :iso="`${destination.temp.data[0].iso}`" />
                        <span class="my-text-bold">
                          {{ destination.temp.data[0].name }}
                        </span>
                        | Vytvořeno: {{ convertDate(item.created_at) }}
                        <b v-if="item.storno">- Stornováno</b>
                      </p>
                      <div class="d-md-none mt-3">
                        <div
                          v-if="participant.Deleting"
                          style=""
                          class="spinner-border spinner-border-sm myspinner mr-1 mt-3"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                        <button
                          @click.prevent="$_deletefun"
                          class="btn bordered-custom-round btn-round mr-2"
                          type="button"
                        >
                          <span class="sr-only">Delete</span>
                          <img width="20px" src="../assets/img/delete.svg" alt="Delete" class="mx-auto" />
                        </button>
                        <button
                          v-if="!item.storno"
                          @click.prevent="__storno"
                          class="btn bordered-custom-round btn-round mr-2"
                          type="button"
                        >
                          <span class="sr-only">Storno</span>
                          <img width="20px" src="../assets/img/ico-delete.svg" alt="Delete" class="mx-auto" />
                        </button>
                        <router-link to="/email/participant">
                          <button class="btn bordered-custom-round btn-round mr-2" type="button">
                            <span class="sr-only">Zpráva</span>
                            <img src="../assets/img/ico-message.svg" alt="Zpráva" class="mx-auto" />
                          </button>
                        </router-link>
                        <router-link :to="`/participant/update/${item.id}`">
                          <button type="button" class="btn btn-primary btn-round">
                            <span class="sr-only">Editovat účastníka</span>
                            <img src="../assets/img/ico-edit-white.svg" alt="Editovat účastníka" />
                          </button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 d-none d-md-block text-right">
                  <div
                    v-if="participant.Deleting"
                    style=""
                    class="spinner-border spinner-border-sm myspinner mr-1 mt-3"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <button
                    @click.prevent="$_deletefun"
                    class="btn bordered-custom-round btn-round mr-2"
                    type="button"
                    title="Smazat"
                  >
                    <span class="sr-only">Delete</span>
                    <img width="20px" src="../assets/img/delete.svg" alt="Delete" class="mx-auto" />
                  </button>
                  <button
                    v-if="!item.storno"
                    @click.prevent="__storno"
                    class="btn bordered-custom-round btn-round mr-2"
                    type="button"
                    title="Storno"
                  >
                    <span class="sr-only">Storno</span>
                    <img width="20px" src="../assets/img/ico-delete.svg" alt="Delete" class="mx-auto" />
                  </button>
                  <router-link to="/email/participant">
                    <button class="btn bordered-custom-round btn-round mr-2" type="button">
                      <span class="sr-only">Zpráva</span>
                      <img src="../assets/img/ico-message.svg" alt="Zpráva" class="mx-auto" />
                    </button>
                  </router-link>
                  <router-link :to="`/participant/update/${item.id}`">
                    <button style="" type="button" class="btn btn-primary button-style-round">
                      <span class="button-font">Editovat účastníka</span>
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="form-row">
              <div class="col-md-7">
                <div class="form-row">
                  <div class="col-sm-6">
                    <div class="card">
                      <div class="card-body">
                        <h2 class="card-title my-text-black">
                          <img src="../assets/img/ico-basic-info.svg" alt="Základní údaje" />
                          Základní údaje
                        </h2>
                        <table class="table table-striped table-sm m-0">
                          <tbody>
                            <tr>
                              <td class="my-text-bold">{{ item.name }}</td>
                            </tr>
                            <tr>
                              <td>
                                <a class="my-text-bold" :href="`mailto:${item.email}`">
                                  {{ item.email }}
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <a class="my-text-bold" :href="`tel:${item.phone}`">
                                  {{ item.phone }}
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <img class="mr-2 mb-1" src="../assets/img/ico-birthday.svg" />
                                <span class="pt-2 my-text-bold">
                                  {{ changeDateFormatTo(item.dob) }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="card">
                      <div class="card-body">
                        <h2 class="card-title my-text-black">
                          <img src="../assets/img/ico-address.svg" alt="Základní údaje" />
                          Adresa
                        </h2>
                        <table class="table table-striped table-sm m-0">
                          <tbody>
                            <tr>
                              <td class="my-text-bold">{{ item.street }}</td>
                            </tr>
                            <tr>
                              <td class="my-text-bold">{{ item.city }}</td>
                            </tr>
                            <tr>
                              <td class="my-text-bold">{{ item.zip }}</td>
                            </tr>
                            <tr>
                              <td class="my-text-bold">
                                <flag class="flag flag-lg" :iso="`${item.iso}`" />
                                {{ item.country_trnaslated }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body">
                    <h2 class="card-title my-text-black">Seznam spolucestujících</h2>
                    <div v-if="item.passengers">
                      <table
                        class="table table-striped table-sm m-0 mt-4 border"
                        v-for="(p, index) in item.passengers"
                        :key="`${p}-${index}`"
                      >
                        <tbody>
                          <tr v-for="(x, index) in p" :key="`${x}-${index}`">
                            <td class="my-text-bold">{{ translatePassengerKey(index) }}</td>
                            <td class="my-text-bold">{{ x }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <span v-else>Žádný</span>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div v-if="invoice_length == 0" class="card">
                  <div class="card-body">
                    <a @click.prevent="$_createInvoice()" class="link-big">
                      <img src="../assets/img/ico-invoice-new.svg" alt="Faktura" class="mb-2" />
                      <br />
                      <span class="my-text-red">nová faktura</span>
                      <div v-if="invoice.Fetching" class="spinner-border spinner-border-sm myspinner" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </a>
                  </div>
                </div>

                <div v-if="contract_length == 0" class="card">
                  <div class="card-body">
                    <a @click.prevent="$_createContract()" class="link-big">
                      <img src="../assets/img/ico-contract-new.svg" alt="Smlouva" class="mb-2" />
                      <br />
                      <span class="my-text-red">nová smlouva</span>
                      <div
                        v-if="participant.contract.Fetching"
                        class="spinner-border spinner-border-sm myspinner"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                    </a>
                  </div>
                </div>
                <div v-if="invoice_length != 0" class="card">
                  <div class="card-body">
                    <div class="form-row">
                      <div class="col-sm m-0">
                        <h2 v-if="invoice_length != 0" class="card-title my-text-black">Faktura</h2>
                      </div>
                      <div class="col-sm text-sm-right m-0">
                        <a @click.prevent="$_createInvoice()" class="link-new float-right">
                          <span class="my-text-red">nová faktura</span>
                          <img src="../assets/img/ico-new.svg" alt="nová faktura" />
                        </a>
                        <div
                          v-if="invoice.Fetching"
                          class="spinner-border spinner-border-sm myspinner m-1"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                    <hr class="m-0" />
                    <div v-if="invoice_length != 0" class="comment d-flex align-items-center">
                      <a @click.prevent="openPdf(invoice.invoices.data[0].public_html_url)">
                        <img src="../assets/img/ico-invoice.svg" alt="Faktura" class="d-none d-lg-block mr-4" />
                      </a>
                      <div class="flex-fill">
                        <p
                          style="color: #007bff"
                          @click.prevent="openPdf(invoice.invoices.data[0].public_html_url)"
                          class="mb-0"
                        >
                          <a target="_blank">
                            <span class="my-text-red">Variabilní symbol:</span>
                            {{ invoice.invoices.data[0].number }}
                          </a>
                        </p>
                        <div class="progress my-2">
                          <div
                            class="progress-bar progress-bar-striped bg-info"
                            role="progressbar"
                            :style="`width: ${calcPercent(invoice.invoices.data[0].total)}%`"
                            aria-valuenow="50"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div class="form-row">
                          <p class="col-lg m-0">
                            <span style="color: #9da4a9">Uhrazeno:</span>
                            <span class="my-text-bold">{{ calcPercent(invoice.invoices.data[0].total) }}%</span>
                          </p>
                          <p class="col-lg text-lg-right m-0">
                            <span style="color: #9da4a9">Zbývá uhradit:</span>
                            <span class="my-text-bold">{{ calcDiff(invoice.invoices.data[0].total) }} Kč</span>
                            <br />
                            <span
                              v-bind:class="{
                                'text-danger': overdue(invoice.invoices.data[0].due_on),
                              }"
                            >
                              {{
                                invoice.invoices.data[0].due_on
                                  ? changeDateFormatTo(invoice.invoices.data[0].due_on)
                                  : ''
                              }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-else class=""></div>
                  </div>
                </div>
                <div v-if="contract_length != 0" class="card">
                  <div class="card-body">
                    <div class="form-row">
                      <div class="col-sm m-0">
                        <h2 class="card-title my-text-black">Cestovní smlouva</h2>
                      </div>
                      <div class="col-sm text-sm-right m-0">
                        <a @click.prevent="$_createContract()" class="link-new float-right">
                          <span class="my-text-red">nová smlouva</span>
                          <img src="../assets/img/ico-new.svg" alt="nová smlouva" />
                        </a>
                        <div
                          v-if="participant.contract.Fetching == true"
                          class="spinner-border spinner-border-sm myspinner m-1"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                    <hr class="m-0" />
                    <div class="comment d-flex align-items-center">
                      <img
                        @click.prevent="$_downloadContract(participant.contract.data[0].id)"
                        src="../assets/img/ico-contract.svg"
                        alt="Faktura"
                        class="d-none d-lg-block mr-4"
                      />
                      <div class="flex-fill">
                        <p @click.prevent="$_downloadContract(participant.contract.data[0].id)" class="mb-0">
                          <a href="">{{ participant.contract.data[0].name }}</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-7">
                <div class="card">
                  <div class="card-body">
                    <h2 class="card-title my-text-black">Komentáře</h2>
                    <hr class="m-0" />
                    <div
                      v-if="participant.comment.Fetching == true"
                      class="spinner-border spinner-border-sm myspinner"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div v-else class="">
                      <div v-if="participant.comment.data.length != 0">
                        <div
                          v-for="(comment, index) in participant.comment.data"
                          :key="`${index}-comm`"
                          class="comment d-flex"
                        >
                          <img
                            v-if="!isParticipantComment(comment)"
                            :src="getCommentImg(comment)"
                            alt="Trisna"
                            class="profile-img"
                          />
                          <span
                            v-if="isParticipantComment(comment)"
                            :style="`--background:Tomato;`"
                            :data-letters="`U`"
                            class=""
                          ></span>
                          <div class="flex-fill">
                            <p class="mb-0">
                              <span class="text-primary">
                                {{ convertDate(comment.created_at) }}&nbsp;
                                <span class="timestamp">
                                  {{ getMinHr(comment.created_at) }}
                                </span>
                              </span>
                              <a @click.prevent="deleteComment(comment.id)" class="link-delete float-right">
                                <span class="text-primary">smazat</span>
                                <img src="../assets/img/ico-delete.svg" alt="smazat" />
                              </a>
                            </p>
                            <p style="font-weight: 600" class="mb-0">
                              {{ comment.sender.name }}
                            </p>
                            <p class="my-text-bold">{{ comment.message }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr class="m-0" />
                    <form class="bg-gray-100">
                      <fieldset>
                        <div class="form-group">
                          <label for="formCommentAdd my-text-black">
                            <span class="my-text-bold">Přidat komentář</span>
                          </label>
                          <textarea
                            v-model="messageData.message"
                            class="form-control"
                            id="formCommentAdd"
                            rows="3"
                          ></textarea>
                          <div class="row">
                            <div style="margin-left: 12px" class="col-md-6">
                              <button @click.prevent="create_Comment()" class="btn bordered-custom mt-4" type="button">
                                <span style="font-weight: 600">Odeslat komentář</span>
                              </button>
                            </div>
                            <div class="col">
                              <div
                                v-if="participant.comment.Fetching"
                                style="margin-top: 26px"
                                class="spinner-border myspinner"
                                role="status"
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="card">
                  <div class="card-body">
                    <h2 class="card-title my-text-black">Přiřazené termíny</h2>
                    <ul v-for="(termdata, index) in item.terms" :key="index" class="nav date-list flex-column">
                      <li class="nav-item">
                        <a class="nav-link" tabindex="-1" aria-disabled="true">
                          <span class="d-block mb-1 my-text-bold">
                            {{ changeDateFormatTo(termdata.startDate) }} -
                            {{ changeDateFormatTo(termdata.endDate) }}
                          </span>
                          <span style="color: #9da4a9">
                            {{ termdata.destination_name }}
                            {{ termdata.code ? ` - ${termdata.code}` : '' }}
                          </span>
                          <br />
                          <span class="my-text-bold">{{ termdata.price }} Kč</span>
                          <span class="ml-5">Doprava: {{ termdata.transport_name }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <p class="text-center d-sm-none mt-4">
              <a href="">
                <img src="../assets/img/ico-logout.svg" alt="Odhlásit se" class="mr-2" />
                Odhlásit se
              </a>
            </p>
          </div>
        </div>
      </div>
      <div v-else class="d-flex justify-content-center mt-3">
        <div class="spinner-border spinner-border myspinner" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
/* eslint-disable */
import Navbar from '@/components/Navbar.vue'
import Alert from '@/components/Alert.vue'
import '../assets/js/jquery.min.js'
import '../assets/js/bootstrap.bundle.min.js'
import { config } from '@/services/config.js'
import { saveAs } from 'file-saver'
import { mapState, mapActions, mapGetters } from 'vuex'
import axios from 'axios'
export default {
  components: {
    Navbar,
    Alert,
  },
  data() {
    return {
      invoicelen: 0,
      messageData: {
        message: '',
        sender: '',
        participant_id: '',
      },
      id: this.$route.params.id,
    }
  },
  methods: {
    getAuthToken() {
      const userData = window.localStorage.getItem('user')
      const user = userData ? JSON.parse(userData) : null
      if (user) {
        return `Bearer ${user.data.api_token}`
      }
    },

    getBasicHeaders() {
      return {
        headers: {
          Accept: 'application/json',
          Authorization: this.getAuthToken(),
        },
      }
    },

    ...mapActions('participant', [
      'clearmsgPar',
      'createContract',
      'getParticipantById',
      '_delete',
      '_storno',
      'update',
      'get_All_Comment',
      'createComment',
      '_deleteComment',
      'get_All_Contract',
    ]),
    isParticipantComment(comment) {
      return comment.sender && !comment.sender.img
    },
    getCommentImg(comment) {
      return comment.sender && comment.sender.img
        ? comment.sender.img
        : require('../assets/' + '/img/profile.jpg')
    },
    ...mapActions('invoice', ['get_All_Invoice', 'createInvoice', 'clearmsg']),
    ...mapActions('destination', ['getDestinationById']),
    $_deletefun() {
      const id = this.destination.temp.data[0].name.id
      const payload = {
        did: id,
        id: this.id,
      }
      this._delete(payload)
    },
    __storno() {
      // alert(this.id);
      this._storno(this.id)
        .then(() => this.$router.push('/'))
        .catch(() => alert('error'))
    },
    calcDiff(value) {
      if (this.invoice.invoices.data[0].status === 'paid') {
        return 0
      }
      return Math.abs(value - this.invoice.invoices.data[0].paid_amount)
    },
    calcPercent(value) {
      if (this.invoice.invoices.data[0].status === 'paid') {
        //console.log('JJ')
        return 100
      } else {
        if (value != 0) {
          const result = (
            (this.invoice.invoices.data[0].paid_amount / value) *
            100
          ).toFixed(2)
          if (result > 100) {
            return 100
          } else {
            return result
          }
        } else {
          return 0
        }
      }
    },
    overdue(date = null) {
      if (date == null) return false
      var current = new Date()
      current.setSeconds(0)
      current.setMinutes(0)
      current.setHours(0)
      return Date.parse(date) < current
    },
    forceFileDownload(response) {
      var blob = new Blob([response.data], {
        type: 'application/pdf',
      })
      saveAs(blob, `${this.participant.temp.data[0].name}.pdf`)
    },
    async $_downloadContract(id) {
      const requestOptions = {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
          Authorization: this.getAuthToken(),
        },
      }
      const res = await axios
        .get(
          `${config.getUrl}/contract/pdf/${id}?t=${Date.now()}`,
          requestOptions
        )
        .then((response) => {
          //  console.log(response)
          this.forceFileDownload(response)
        })
    },
    create_Comment() {
      const id = this.$router.currentRoute.params.id
      this.messageData.participant_id = id
      this.messageData.sender = this.account.user.data.id
      this.createComment(this.messageData)
      this.messageData.message = ''
    },
    deleteComment(id) {
      this._deleteComment(id)
    },
    $_createInvoice() {
      const invoice = {
        id: this.$router.currentRoute.params.id,
      }
      this.createInvoice(invoice)
    },
    $_createContract() {
      const contract = {
        id: this.$router.currentRoute.params.id,
      }
      this.createContract(contract)
    },
    convertDate(date) {
      var d = new Date(date)
      var dd = d.getDate()

      var mm = d.getMonth() + 1
      var yyyy = d.getFullYear()
      var hour = d.getHours()
      var minutes = d.getMinutes()
      if (dd < 10) {
        dd = `0${dd}`
      }

      if (mm < 10) {
        mm = `0${mm}`
      }
      if (minutes < 10) {
        minutes = `0${minutes}`
      }
      if (hour < 10) {
        hour = `0${hour}`
      }
      return `${dd}. ${mm}. ${yyyy}`
    },
    getMinHr(date) {
      var d = new Date(date)
      var hour = d.getHours()
      var minutes = d.getMinutes()
      if (minutes < 10) {
        minutes = `0${minutes}`
      }
      if (hour < 10) {
        hour = `0${hour}`
      }
      return `(${hour}:${minutes})`
    },
    changeDateFormatTo(date) {
      if(!date) return ''

      const [dd, mm, yy] = date.split(/-/g)
      return `${yy}. ${mm}. ${dd}`
    },
    openPdf(x) {
      window.open(x, '_blank')
    },

    translatePassengerKey(key) {
      switch (key) {
        case 'first_name':
          return 'Jméno'

        case 'last_name':
          return 'Příjmení'
        case 'name':
          return 'Celé jméno'
        case 'country':
          return 'Země'
        case 'nationality':
          return 'Národnost'
        case 'street':
          return 'ulice'
        case 'city':
          return 'Město'
        case 'zip':
          return 'PSČ'
        case 'dob':
          return 'Datum narození'
        case 'birth_date':
          return 'Datum narození'
        case 'id_card_number':
          return 'Číslo občanského průkazu'
        default:
          return key
      }
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      document.title = this.getTitle
      this.clearmsg()
      this.clearmsgPar()
      this.getParticipantById(this.id)
      this.get_All_Invoice(this.id)
      console.log(this.id, this.participant_id, this.participant.temp.data[0])
      setTimeout(() => {
        console.log(this.id, this.participant_id, this.participant.temp.data[0])
        this.getDestinationById(this.participant.temp.data[0].destination_id)
        if (this.destination.temp.data.length == 0) {
          this.$router.push('/')
        }
      }, 500)

      //    this.getDestinationById(this.destination.temp.data[0].id)
      this.get_All_Comment(this.id)
      this.get_All_Contract(this.id)
      //  console.log()
    })
  },
  watch: {
    getTitle() {
      if (typeof this.destination.temp.data[0] != 'undefined')
        document.title = this.destination.temp.data[0].name
    },
  },
  computed: {
    participant_id() {
      return this.$route.params.id
    },
    getTitle() {
      if (typeof this.destination.temp.data[0] != 'undefined')
        return this.destination.temp.data[0].name
    },
    invoice_length() {
      return this.invoice.invoices.data.length
    },
    contract_length() {
      return this.participant.contract.data.length
    },
    ...mapState({
      participant: 'participant',
    }),
    ...mapState({
      invoice: 'invoice',
    }),
    ...mapState({
      account: 'account',
    }),
    ...mapState({
      destination: 'destination',
    }),
  },
}
</script>

<style lang="css" scoped>
.bordered-custom-round {
  background-color: white;
  color: black;
  border: 2px solid #da4c43;
  /* Green */
  border-radius: 20px !important;
  font-weight: bold;
}

.custom-alert {
  padding: 9px !important;
}

.custom-alert-button {
  margin: -5px !important;
}

.bordered-custom {
  background-color: white;
  color: #da4c43;
  border: 2px solid #da4c43;
  /* Green */
  border-radius: 20px !important;
  font-weight: bold;
}

.bordered-custom:hover {
  color: #fff;
  background-color: #da4c43;
  border-color: #da4c43;
}

.timestamp {
  color: black;
}

.link-delete:hover {
  cursor: pointer;
}
</style>
