<template lang="html">
  <div class="">
    <Navbar />
    <main>
      <Alert :success="address.success.length != 0" :fail="address.failed.length != 0" />
      <div class="topbar has-image">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-4 col-md-2 col-2">
              <div class="d-flex align-items-md-center">
                <div class="my-2 mr-4 mr-md-5">
                  <button class="btn bordered-custom-round btn-round" type="button" @click="$router.go(-1)">
                    <span class="sr-only">Zpět</span>
                    <img src="../assets/img/ico-back.svg" alt="Zpět" class="mx-auto" />
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-10 text-lg-center">
              <h1 class="mb-1">Přidat kontakt</h1>
              <p class="d-md-none">
                <img src="../assets/img/flags/flag-dominican-republic.svg" alt="Dominikána 2020" class="flag" />
                Boat trip Maledivy 2019/2020
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="card">
          <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form">
            <form data-vv-scope="addcontact" class="card-body">
              <h2 class="card-title">1. Základní údaje a adresa</h2>
              <div class="form-row">
                <div class="col-md">
                  <fieldset>
                    <div class="form-group">
                      <label for="addParticipantName">Jméno a příjmení:</label>
                      <ValidationProvider v-slot="{ errors }" name="name" rules="required">
                        <input
                          id="addParticipantName"
                          v-model="formData.name"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label for="addParticipantEmail">E-mail:</label>
                      <ValidationProvider v-slot="{ errors }" name="email" rules="required|email">
                        <input
                          id="addParticipantEmail"
                          v-model="formData.email"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label for="addParticipantPhone">Telefon:</label>
                      <ValidationProvider v-slot="{ errors }" name="phone" rules="required">
                        <input
                          id="addParticipantPhone"
                          v-model="formData.phone"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label for="addParticipantBirth">Datum narození:</label>
                      <ValidationProvider v-slot="{ errors }" name="dob" rules="required">
                        <date-pick
                          v-model="formData.dob"
                          :selectable-year-range="80"
                          :input-attributes="{ readonly: true }"
                          class=""
                          :display-format="'DD. MM. YYYY'"
                        ></date-pick>
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label for="addParticipantIdCardNum">Číslo občanského průkazu:</label>
                      <ValidationProvider v-slot="{ errors }" name="dob" rules="required">
                        <input
                          id="addParticipantIdCardNum"
                          v-model="formData.id_card_number"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </fieldset>
                </div>
                <div class="col-md">
                  <fieldset>
                    <div class="form-group">
                      <label for="addParticipantStreet">Ulice:</label>
                      <ValidationProvider v-slot="{ errors }" name="street" rules="required">
                        <input
                          id="addParticipantStreet"
                          v-model="formData.street"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label for="addParticipantCity">Město:</label>
                      <ValidationProvider v-slot="{ errors }" name="city" rules="required">
                        <input
                          id="addParticipantCity"
                          v-model="formData.city"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label for="addParticipantZIP">PSČ:</label>
                      <ValidationProvider v-slot="{ errors }" name="zip" rules="required">
                        <input
                          id="addParticipantZIP"
                          v-model="formData.zip"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label for="addParticipantCountry">Stát:</label>
                      <ValidationProvider v-slot="{ errors }" name="country" rules="required">
                        <select
                          id="addParticipantCountry"
                          v-model="formData.country"
                          class="custom-select custom-select-sm"
                        >
                          <option disabled value="country" selected>Země</option>
                          <option v-for="country in closeCountriesData" :key="country.id" :value="`${country.name}`">
                            {{ country.human_name }}
                          </option>
                        </select>
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group">
                      <label for="addParticipantCountry">Státní příslušnost:</label>
                      <ValidationProvider v-slot="{ errors }" name="country" rules="required">
                        <select
                          id="addParticipantCountry"
                          v-model="formData.nationality"
                          class="custom-select custom-select-sm"
                        >
                          <option disabled value="country" selected>Země</option>
                          <option v-for="country in countriesData" :key="country.id" :value="`${country.name}`">
                            {{ country.human_name }}
                          </option>
                        </select>
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </fieldset>
                </div>
              </div>
              <button
                style=""
                type="button"
                class="button-style-round btn btn-primary mt-4 ml-3 ml-sm-4 mb-3"
                @click.prevent="$_emitdata()"
              >
                Uložit účastníka
              </button>
            </form>
          </ValidationObserver>
        </div>
        <p class="text-center d-sm-none mt-4">
          <a href="">
            <img src="../assets/img/ico-logout.svg" alt="Odhlásit se" class="mr-2" />
            Odhlásit se
          </a>
        </p>
      </div>
    </main>
  </div>
</template>

<script>
/* eslint-disable-next-line vue/no-unused-vars */
import Navbar from '@/components/Navbar.vue'
import Alert from '@/components/Alert.vue'
import '../assets/js/jquery.min.js'
import '../assets/js/bootstrap.bundle.min.js'
import { config } from '@/services/config.js'
import { mapState, mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  components: {
    Navbar,
    Alert,
  },

  data() {
    return {
      startDate: '',
      endDate: '',
      price: '',
      packageName: '',
      coupon: '',
      countriesData: [],
      closeCountriesData: [],
      formData: {
        name: '',
        email: '',
        street: '',
        city: '',
        country: '',
        phone: '',
        dob: '',
        zip: '',
        paid: '',
        id_card_number: '',
        nationality: '',
      },
    }
  },

  computed: {
    ...mapState({ destination: 'destination' }),
    ...mapState({ currentTerm: 'term' }),
    ...mapState({ address: 'address' }),
  },

  created() {
    document.title = this.$route.name
    this.clearmsg()
    this.listCountries()
  },

  methods: {
    ...mapActions('address', ['create', '_delete', 'update', 'clearmsg']),
    ...mapActions('destination', ['get_All_Packages']),

    getAuthToken() {
      const userData = window.localStorage.getItem('user')
      const user = userData ? JSON.parse(userData) : null
      if (user) {
        return `Bearer ${user.data.api_token}`
      }
    },

    getBasicHeaders() {
      return {
        headers: {
          Accept: 'application/json',
          Authorization: this.getAuthToken(),
        },
      }
    },

    async $_emitdata() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        console.log('Error')
      } else {
        this.create(this.formData)
      }
      //    this.create(this.formData);
    },
    async listCountries() {
      const res = await axios.get(`${config.getUrl}/countries/all`, this.getBasicHeaders())
      this.countriesData = res.data
      this.closeCountriesData = this.countriesData.filter(item => item.close == 1)
    },
  },
}
</script>

<style lang="css" scoped>
.error {
  color: red !important;
}

.bordered-custom-round {
  background-color: white;
  color: black;
  border: 2px solid #da4c43;
  border-radius: 20px !important;
  font-weight: bold;
}

.custom-alert {
  padding: 9px !important;
}

.custom-alert-button {
  margin: -5px !important;
}
</style>
