import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/Login.vue'
import AddDestination from './views/AddDestination.vue'
import DestinationDetail from './views/DestinationDetail.vue'
import TermDetail from './views/TermDetail.vue'
import DestinationCodeDetail from './views/DestinationCodeDetail.vue'
import ParticipantDetails from './views/ParticipantDetails.vue'
import AddParticipant from './views/AddParticipant.vue'
import Address from './views/Address.vue'
import AddressDetail from './views/AddressDetail.vue'
import AddAddress from './views/AddContact.vue'
import EditAddress from './views/EditContact.vue'
import EditDestination from './views/EditDestination.vue'
import EditParticipant from './views/EditParticipant.vue'
import Email from './views/Email.vue'
import EmailContact from './views/EmailContact.vue'
Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/destination/add',
      name: 'AddDestination',
      component: AddDestination,
    },
    {
      path: '/destination/update/:id',
      name: 'EditDestination',
      component: EditDestination,
    },
    {
      path: '/destination/:id',
      name: 'DestinationDetail',
      component: DestinationDetail,
    },
    {
      path: '/destination/:id/code/:code',
      name: 'DestinationCodeDetailCode',
      component: DestinationCodeDetail,
    },
    {
      path: '/destination/:id/:code/',
      name: 'DestinationCodeDetail',
      component: DestinationCodeDetail,
    },
    {
      path: '/term/:id',
      name: 'TermDetail',
      component: TermDetail,
    },
    {
      path: '/participant/add',
      name: 'AddParticipant',
      component: AddParticipant,
    },
    {
      path: '/participant/update/:id',
      name: 'EditParticipant',
      component: EditParticipant,
    },
    {
      path: '/participant/:id',
      name: 'ParticipantDetails',
      component: ParticipantDetails,
    },
    {
      path: '/address',
      name: 'Address',
      component: Address,
    },
    {
      path: '/address/add',
      name: 'AddAddress',
      component: AddAddress,
    },
    {
      path: '/address/update/:id',
      name: 'EditAddress',
      component: EditAddress,
    },
    {
      path: '/address/:id',
      name: 'AddressDetail',
      component: AddressDetail,
    },
    {
      path: '/email/participant',
      name: 'EmailParticipant',
      component: Email,
    },
    {
      path: '/email/contact',
      name: 'EmailContact',
      component: EmailContact,
    },
  ],
})
