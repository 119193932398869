import axios from 'axios'
import { config } from './config.js'
import { cipher } from './cipher.js'
export const userService = {
  login,
  logout,
  getById,
  update,
}

function getAuthToken() {
  const userData = window.localStorage.getItem('user')
  const user = userData ? JSON.parse(userData) : null
  if (user) {
    return `Bearer ${user.data.api_token}`
  }
}

function getBasicHeaders() {
  return {
    headers: {
      Accept: 'application/json',
    },
  }
}

async function login(email, password) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
    },
    email,
    password,
    //...cipher(password),
  }
  //  console.log(requestOptions) // logging encryption
  // console.log(decipher(cipher(password).encryped)) // logging decryption
  try {
    await axios.post(`${config.setUrl}/login/`, requestOptions).then(user => {
      // login successful if there's a jwt token in the response
      //        console.log(user)
      if (user.data.message === 'success') {
        //      console.log(user.data)
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user))
        return user
      } else {
        return Promise.reject(new Error('Invalid Credentials'))
      }
    })
  } catch (error) {
    return Promise.reject(new Error('Invalid Credentials'))
  }
}

function logout() {
  localStorage.removeItem('user')
}

async function getById(id) {
  try {
    const res = await axios.get(`${config.getUrl}/user/${id}`, getBasicHeaders())

    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function update(user) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
    },
    ...user,
  }

  try {
    const res = await axios.put(`${config.setUrl}/user/${user._id}`, requestOptions, getBasicHeaders())
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}
