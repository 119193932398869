<template lang="html">
  <div class="">
    <Navbar />
    <main>
      <div class="topbar has-image">
        <div class="container">
          <div class="row align-items-center my-md-2">
            <div class="col-md-6 col-10">
              <h1 class="mb-0 mb-md-1">Adresář</h1>
            </div>
            <div class="col-md-6 col-2 text-right">
              <router-link to="/address/add">
                <button
                  style="border-radius: 20px !important"
                  type="button"
                  class="btn btn-primary d-none d-md-inline-block"
                >
                  Přidat kontakt
                  <img style="margin: 0px" src="../assets/img/ico-add.svg" alt="Přidat kontakt" class="ml-sm-2" />
                </button>
                <button type="button" class="btn btn-primary btn-round d-md-none">
                  <span class="sr-only">Přidat účastníka</span>
                  <img style="margin: 0px" src="../assets/img/ico-add.svg" alt="Přidat účastníka" />
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row align-items-center mb-2">
          <div class="col-lg-7 col-md-5 col-7 d-none d-md-block">
            <h2 class="mb-2">Seznam kontaktů</h2>
          </div>
          <div class="col-lg-3 col-md-4">
            <form @submit.prevent="searchData()">
              <div class="input-group mb-2">
                <input
                  @input="searchData()"
                  v-model="searchQuery"
                  style="margin-right: 3px"
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Hledat v seznamu"
                  id="filterSearch"
                  aria-describedby="filterSearchButton"
                  aria-label="Hledat v seznamu"
                />
                <div class="input-group-append">
                  <button
                    @click.prevent="searchData()"
                    class="btn btn-search btn-sm"
                    type="button"
                    id="filterSearchButton"
                  >
                    Vyhledat
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="col-lg-2 col-md-3 d-none d-sm-block text-right">
            <p class="small mb-1">
              <img src="../assets/img/ico-items.svg" alt="Celkem" class="mr-2" />
              <span class="my-text-bold">{{ this.filteredData.length }}&nbsp;kontaktů</span>
            </p>
          </div>
        </div>
        <div class="card">
          <ul class="nav pseudotable flex-column">
            <li class="nav-item pseudoheader">
              <span class="form-row">
                <span class="col-md col-sm-6 table-head">Jméno</span>
                <span class="col-md col-sm-6 table-head">Telefon</span>
                <span class="col-md col-sm-6 table-head">E-mail</span>
                <span class="col-md col-sm-6 table-head">Zájezdy</span>
              </span>
            </li>
            <div v-if="address.baseAddress.Fetching" class="d-flex justify-content-center">
              <div class="spinner-border myspinner" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <li v-else v-for="item in this.filteredData" :key="item.id" class="nav-item">
              <router-link class="nav-link form-row" :to="`address/${item.id}`">
                <span
                  :style="`--background:Tomato;`"
                  :data-letters="`${item.name.charAt(0).toUpperCase()}`"
                  class="col-md col-sm-6 my-text-bold"
                >
                  {{ item.name }}
                </span>
                <span class="col-md col-sm-6 my-text-bold">
                  {{ item.phone }}
                </span>
                <span class="col-md col-sm-6 my-text-bold">
                  {{ item.email }}
                </span>
                <span class="col-md col-sm-6 my-text-bold">
                  <span class="persons">
                    <img src="../assets/img/ico-star.svg" alt="" />
                    <img src="../assets/img/ico-star.svg" alt="" />
                  </span>
                  {{ item.num_if_terms }}
                </span>
              </router-link>
            </li>
          </ul>
        </div>
        <p class="text-center d-sm-none mt-4">
          <a href="">
            <img src="../assets/img/ico-logout.svg" alt="Odhlásit se" class="mr-2" />
            Odhlásit se
          </a>
        </p>
      </div>
    </main>
  </div>
</template>

<script>
/* eslint-disable */
import Navbar from '@/components/Navbar.vue'
import '../assets/js/jquery.min.js'
import '../assets/js/bootstrap.bundle.min.js'
import { mapState, mapActions, mapGetters } from 'vuex'
import axios from 'axios'
export default {
  data() {
    return {
      searchQuery: '',
      filteredData: [],
    }
  },
  components: {
    Navbar,
  },
  methods: {
    getRandomColor() {
      const colors = [
        'Tomato',
        'Orange',
        'DodgerBlue',
        'MediumSeaGreen',
        'Gray',
        'SlateBlue',
        'Violet',
        'LightGray',
      ]
      return colors[Math.floor(Math.random() * colors.length)]
    },
    ...mapActions('address', ['get_Base_All_Address', 'get_All_Address']),
    searchData() {
      if (this.searchQuery.length > 0) {
        this.filteredData = this.address.baseAddress.data.filter((item) => {
          return (
            item.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            item.email.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            item.phone.toLowerCase().includes(this.searchQuery.toLowerCase())
          )
        })
      } else {
        console.log(this.address)
        //this.filteredData = this.address.addresss.data;
        this.filteredData = this.address.baseAddress.data
      }
      //  console.log(this.filteredData)
    },
  },
  created() {
    document.title = 'Adresář'
    this.get_Base_All_Address()
  },
  computed: {
    ...mapState({ address: 'address' }),
    filldata() {
      console.log(this.address.baseAddress.data)
      return this.address.baseAddress.data
    },
  },
  watch: {
    filldata() {
      this.filteredData = this.address.baseAddress.data
    },
  },
  mounted() {},
}
</script>

<style lang="css" scoped>
</style>
