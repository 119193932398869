import { userService } from '../services'
import router from '../router'
// const user = JSON.parse(localStorage.getItem('user'))
const state = {
  user: {
    data: [],
  },
  Fetching: null,
  Fetched: null,
}

const actions = {
  clearmsgAccount({ commit }) {
    commit('clearmsg')
  },
  login({ dispatch, commit }, { email, password }) {
    commit('loginRequest')

    userService.login(email, password).then(
      user => {
        const loggedIn = JSON.parse(localStorage.getItem('user'))
        commit('loginSuccess', loggedIn)
        dispatch('alert/success', 'Login successful', { root: true })
        router.push('/')
      },
      error => {
        commit('loginFailure', error)
        dispatch('alert/error', error, { root: true })
      }
    )
  },
  logout({ commit }) {
    userService.logout()
    commit('logout')
    router.push('/login')
  },
}

const mutations = {
  clearmsg(state) {
    state.Fetched = null
    state.Fetching = null
  },
  loginRequest(state) {
    state.Fetched = false
    state.Fetching = true
  },
  loginSuccess(state, user) {
    state.user = user
    state.Fetched = true
    state.Fetching = false
  },
  loginFailure(state) {
    state.user = null
    state.Fetched = true
    state.Fetching = false
  },
  logout(state) {
    state.user = null
  },
}

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
}
