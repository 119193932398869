<template>
  <div class="home">
    <Navbar />
    <main>
      <div class="topbar has-image">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-2 col-md-6">
              <p class="title">Nejbližší termíny</p>
            </div>
            <div class="col-lg-2 col-md-6 order-lg-last d-none d-md-block text-right">
              <a class="carousel-control-prev mr-1" href="#carouselTopbar" role="button" data-slide="prev">
                <span style="visibility: hidden" class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#carouselTopbar" role="button" data-slide="next">
                <span style="visibility: hidden" class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
            <div class="col-lg-8 mt-3 mt-lg-0">
              <div id="carouselTopbar" class="carousel slide" data-interval="false" data-ride="carousel">
                <div v-if="destination.destinations.Fetched" class="carousel-inner">
                  <div v-if="recentData.slice(0, 3).length != 0" class="carousel-item active">
                    <div class="row">
                      <div v-for="(desti1, index) in recentData.slice(0, 3)" :key="`${index}-desti1`" class="col-sm">
                        <router-link class="carousel-link" :to="`/term/${desti1.id}`">
                          <p class="carousel-tip">
                            <flag class="flag" :iso="`${desti1.iso}`" />
                            <a class>
                              <span class="carousel-name">
                                {{ desti1.name }}
                              </span>
                            </a>
                            <br />
                            <span class="font-weight-light grey-font">
                              {{ changeDateFormatTo(desti1.startDate) }} -
                              {{ changeDateFormatTo(desti1.endDate) }}
                            </span>
                          </p>
                        </router-link>
                      </div>
                    </div>
                  </div>

                  <div v-if="recentData.slice(3, 6).length != 0" class="carousel-item">
                    <div class="row">
                      <div v-for="(desti2, index) in recentData.slice(3, 6)" :key="`${index}-desti2`" class="col-sm">
                        <router-link class="carousel-link" :to="`/term/${desti2.id}`">
                          <p class="carousel-tip">
                            <flag class="flag" :iso="`${desti2.iso}`" />
                            <a class>
                              <span class="carousel-name">
                                {{ desti2.name }}
                              </span>
                            </a>
                            <br />
                            <span class="font-weight-light grey-font">
                              {{ changeDateFormatTo(desti2.startDate) }} -
                              {{ changeDateFormatTo(desti2.endDate) }}
                            </span>
                          </p>
                        </router-link>
                      </div>
                    </div>
                  </div>

                  <div v-if="recentData.slice(6, 9).length != 0" class="carousel-item">
                    <div class="row">
                      <div v-for="(desti3, index) in recentData.slice(6, 9)" :key="`${index}-desti3`" class="col-sm">
                        <router-link class="carousel-link" :to="`/term/${desti3.id}`">
                          <p class="carousel-tip">
                            <flag class="flag" :iso="`${desti3.iso}`" />
                            <a class>
                              <span class="carousel-name">
                                {{ desti3.name }}
                              </span>
                            </a>
                            <br />
                            <span class="font-weight-light grey-font">
                              {{ changeDateFormatTo(desti3.startDate) }} -
                              {{ changeDateFormatTo(desti3.endDate) }}
                            </span>
                          </p>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row mb-2">
          <div class="col-md-8 col-7">
            <h2 class="mb-2" style="font-size: 32px">Noví účastníci</h2>
          </div>
        </div>
        <div class="card">
          <ul class="nav pseudotable flex-column">
            <li v-if="!this.participant.uncompleted.Fetching && this.participant.uncompleted.data.length == 0">
              Žádný nový účastník
            </li>
            <li v-else class="nav-item pseudoheader">
              <span class="form-row">
                <span class="col-md col-sm-6 table-head">Jméno</span>
                <span class="col-md col-sm-6 table-head">Destinace</span>
                <span class="col-md col-sm-6 table-head">Termín</span>
                <span class="col-md col-sm-6 table-head">Datum přihlášení</span>
              </span>
            </li>
            <div v-if="this.participant.uncompleted.Fetching" class="d-flex justify-content-center">
              <div class="spinner-border myspinner" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <li v-else v-for="item in this.uncompletedParticipants" :key="item.id" class="nav-item">
              <router-link class="nav-link form-row lh-07" :to="`participant/${item.id}`">
                <span
                  :style="`--background:Tomato;`"
                  :data-letters="`${item.name.charAt(0).toUpperCase()}`"
                  class="col-md col-sm-6 my-text-bold align-content-center"
                >
                  {{ item.name }}
                </span>
                <div class="d-none d-md-flex align-items-center">
                  <flag class="flag flag-lg rounded-circle w27p" :iso="`${item.destination_iso}`" />
                </div>
                <div class="col-md col-sm-6 my-2 my-md-0 my-text-bold d-flex align-items-center">
                  <flag
                    class="d-inline-block d-md-none flag flag-lg rounded-circle w27p"
                    :iso="`${item.destination_iso}`"
                  />
                  {{ item.destination }}
                </div>
                <span class="col-md col-sm-6 my-text-bold d-flex align-items-center">
                  {{ item.term }}
                </span>
                <span class="col-md col-sm-6 my-text-bold d-flex align-items-center">
                  {{ item.created_at }}
                </span>
              </router-link>
            </li>
          </ul>
          <div
            class="link text-center pb-2"
            v-if="
              !this.participant.uncompleted.Fetching &&
              this.participant.uncompleted.data.length > this.uncompletedParticipantsMinShow
            "
          >
            <div class="d-inline pointer" @click.prevent="toggleShowUncompleteParticipants()">
              <span class="my-text-red hov-underline">
                Zobrazit
                {{ !this.showAllUncompletedParticipants ? `více` : 'méně' }}
              </span>
              <span v-if="!this.showAllUncompletedParticipants" class="text-muted">
                ({{ this.participant.uncompleted.data.length - this.uncompletedParticipantsMinShow }})
                <img src="../assets/img/custom-select.svg" height="6px" />
              </span>
            </div>
          </div>
        </div>
        <div class="row align-items-center mb-2 mt-4">
          <div class="col-md-8 col-7">
            <h2 class="mb-2" style="font-size: 32px">Destinace</h2>
          </div>
          <div class="col-md-4 col-5 text-right">
            <button
              class="btn btn-outline-primary btn-round d-sm-none mr-2 mb-2"
              type="button"
              data-toggle="collapse"
              data-target="#collapseSearch"
              aria-expanded="false"
              aria-controls="collapseSearch"
            >
              <span class="sr-only">Vyhledat</span>
              <img src="../assets/img/ico-search-red.svg" alt="Přidat" class="mx-auto ml-sm-2" />
            </button>
            <router-link
              to="/destination/add"
              style
              class="btn btn-primary d-none d-sm-inline-block mb-2 add-destination button-style-round"
            >
              <span class>Přidat destinaci</span>
              <img style="margin: 0px" src="../assets/img/ico-add.svg" alt="Přidat destinaci" class="ml-sm-2" />
            </router-link>
            <router-link
              to="/destination/add"
              id="addbutton"
              class="btn btn-primary btn-round d-sm-none mb-2 add-destination"
            >
              <span class="sr-only">Přidat destinaci</span>
              <img style="margin: 0px" src="../assets/img/ico-add.svg" alt="Přidat destinaci" />
            </router-link>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-9">
            <form class="form-row">
              <div class="col-lg-2 col-sm-3">
                <div class="form-group mb-2 mb-md-3">
                  <select @change="getfilteredData($event)" class="custom-select custom-select-sm" id="filterStatus">
                    <option value="status" selected>Status</option>
                    <option value="active">Aktivní</option>
                    <option value="inactive">Neaktivní</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-4 col-sm">
                <div class="form-group mb-2 mb-md-3">
                  <select
                    @change="getfilteredCountryData($event)"
                    class="custom-select custom-select-sm"
                    id="filterCountry"
                  >
                    <option value="country" selected>Země</option>
                    <option v-for="country in countriesData" :key="country.id" :value="`${country.name}`">
                      {{ country.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-4 col-sm">
                <form @submit.prevent="searchData()">
                  <div class="collapse d-sm-block" id="collapseSearch">
                    <div class="input-group mb-2 mb-md-3">
                      <input
                        @input="searchData()"
                        v-model="searchQuery"
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Hledat v seznamu"
                        id="filterSearch"
                        aria-describedby="filterSearchButton"
                        aria-label="Hledat v seznamu"
                      />
                      <div style="margin-left: 2px" class="input-group-append">
                        <button
                          @click.prevent="searchData()"
                          class="btn btn-search btn-sm"
                          type="button"
                          id="filterSearchButton"
                        >
                          Vyhledat
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </form>
          </div>
          <div class="col-lg-4 col-md-3 d-none d-sm-block text-right">
            <p class="small">
              <img src="../assets/img/ico-items.svg" alt="Celkem" class="mr-2" />
              <span class="destinac my-text-bold">{{ this.filteredData.length }}&nbsp;destinací</span>
            </p>
          </div>
        </div>
        <div v-if="destination.destinations.Fetching" class="d-flex justify-content-center">
          <div class="spinner-border myspinner" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <ul v-else class="form-row offer">
          <li v-for="item in this.filteredData" :key="item.id" class="col-lg-3 col-sm-4">
            <router-link v-if="item.status === 'active'" :to="`/destination/${item.id}`" class="picker">
              <flag class="flag flag-lg" :iso="`${item.iso}`" />

              <span class="my-text-bold">{{ item.name }}</span>
              <br />

              <span class>
                <span v-if="item.no_of_participants <= 10">
                  <img src="../assets/img/ico-person.svg" alt />
                </span>
                <span v-if="item.no_of_participants > 10">
                  <img src="../assets/img/ico-person.svg" alt />
                  <img src="../assets/img/ico-person.svg" alt />
                </span>
                <span v-if="item.no_of_participants > 21">
                  <img src="../assets/img/ico-person.svg" alt />
                  <img src="../assets/img/ico-person.svg" alt />
                  <img src="../assets/img/ico-person.svg" alt />
                </span>
                &nbsp;
                <span class="small my-text-bold">
                  {{ item.no_of_participants }}
                </span>
              </span>
            </router-link>
            <router-link style="text-decoration: none" v-else :to="`/destination/${item.id}`">
              <span class="picker">
                <flag class="flag flag-lg" :iso="`${item.iso}`" />
                <span class="my-text-bold">{{ item.name }}</span>
                <br />
                <span class>
                  <span v-if="item.no_of_participants <= 10">
                    <img src="../assets/img/ico-person.svg" alt />
                  </span>
                  <span v-if="item.no_of_participants > 10">
                    <img src="../assets/img/ico-person.svg" alt />
                    <img src="../assets/img/ico-person.svg" alt />
                  </span>
                  <span v-if="item.no_of_participants > 21">
                    <img src="../assets/img/ico-person.svg" alt />
                    <img src="../assets/img/ico-person.svg" alt />
                    <img src="../assets/img/ico-person.svg" alt />
                  </span>
                  &nbsp;
                  <span class="small my-text-bold">
                    {{ item.no_of_participants }}
                  </span>
                </span>
              </span>
            </router-link>
          </li>
        </ul>
        <!-- <button class="btn btn-success" @click="webhookTest()">WHtest</button> -->
        <p class="text-center d-sm-none mt-4">
          <a>
            <img src="../assets/img/ico-logout.svg" alt="Odhlásit se" class="mr-2" />
            Odhlásit se
          </a>
        </p>
      </div>
    </main>
  </div>
</template>
<script>
// @ is an alias to /src
/* eslint-disable */
import Navbar from '@/components/Navbar.vue'
import '../assets/js/jquery.min.js'
import '../assets/js/bootstrap.bundle.min.js'
import { mapState, mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import { config } from '@/services/config.js'
export default {
  data() {
    return {
      name: 'home',
      searchQuery: '',
      //  recentData:[],
      filter1: '1',
      filteredData: [],
      countriesData: [],
      showAllUncompletedParticipants: false,
      participantBgColor: [],
      uncompletedParticipantsMinShow: 4,
    }
  },
  components: {
    Navbar,
  },
  methods: {
    getRandomColor() {
      const colors = [
        'Tomato',
        'Orange',
        'DodgerBlue',
        'MediumSeaGreen',
        'Gray',
        'SlateBlue',
        'Violet',
        'LightGray',
      ]
      return colors[Math.floor(Math.random() * colors.length)]
    },
    getParticipantBGColor(participant) {
      if (!this.participantBgColor[participant.id]) {
        this.participantBgColor[participant.id] = this.getRandomColor()
      }
      return this.participantBgColor[participant.id]
    },
    /* async webhookTest() {
      console.log("Jedu..");
      axios
        .post(`${config.getUrl}/invoice/webhook`, {
          invoice_id: "15191546",
          status: "open"
        })
        .then(function(response) {
          console.log(response);
        })
        .catch(function(error) {
          console.log(error);
        });
    }, */
    changeDateFormatTo(date) {
      const [dd, mm, yy] = date.split(/-/g)
      return `${yy}. ${mm}. ${dd}`
    },
    ...mapActions('destination', [
      'create',
      'get_All_Destination',
      'getById',
      '_delete',
      'update',
    ]),
    ...mapActions('term', ['getUpcoming']),
    ...mapActions('participant', ['getUncomplete']),
    getfilteredData(event) {
      //  console.log(event.target.value);
      if (event.target.value === 'status') {
        this.filteredData = this.destination.destinations.data
      } else {
        this.filteredData = this.destination.destinations.data.filter((obj) => {
          return obj.status === event.target.value
        })
      }
      //  console.log(this.filteredData)a
    },
    getfilteredCountryData(event) {
      if (event.target.value === 'country') {
        this.filteredData = this.destination.destinations.data
      } else {
        this.filteredData = this.destination.destinations.data.filter((obj) => {
          return obj.country === event.target.value
        })
      }
      //console.log(this.filteredData)
    },
    async listCountries() {
      let countryres = []
      this.destination.destinations.data.forEach((destination) =>
        countryres.push({
          id: destination.id,
          name: destination.country,
        })
      )
      countryres = countryres.filter(
        (v, i, a) => a.findIndex((t) => t.name === v.name) === i
      )
      //console.log(countryres)
      //const res = await axios.get('https://restcountries.eu/rest/v2/all')
      this.countriesData = countryres
      //    console.log(this.countriesData)
    },
    searchData() {
      //  console.log('search')
      if (this.searchQuery.length > 0) {
        this.filteredData = this.destination.destinations.data.filter(
          (item) => {
            return (
              item.name
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase()) ||
              item.country
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase())
            )
          }
        )
      } else {
        this.filteredData = this.destination.destinations.data
      }
      //  console.log(this.filteredData)
    },
    toggleShowUncompleteParticipants() {
      this.showAllUncompletedParticipants = !this.showAllUncompletedParticipants
    },
  },

  created() {
    this.listCountries()
    this.get_All_Destination()
    this.getUpcoming()
    this.getUncomplete()
    document.title = 'Seznam destinací'

    //  this.filteredData = this.destination.destinations.data
    //    this.filldata(this.destination.destinations.data)
  },
  mounted() {
    const loggedIn = localStorage.getItem('user')
    //  console.log(loggedIn, '#$')
    // if (!loggedIn) {
    //  this.$router.push('/login')
    // }
    //    this.recentData = this.destination.destinations.data.filter(obj => {
    //    return obj.status !== 'inactive'
    //    })
    //  console.log(this.recentData)
  },
  watch: {
    filldata() {
      this.filteredData = this.destination.destinations.data
    },
  },
  computed: {
    ...mapState({ destination: 'destination' }),
    ...mapState({ term: 'term' }),
    ...mapState({ participant: 'participant' }),
    recentData() {
      const arr = this.term.upcoming.data
      //console.log(arr)
      return arr
    },
    filldata() {
      return this.destination.destinations.data
    },
    uncompletedParticipants() {
      if (!this.showAllUncompletedParticipants) {
        return this.participant.uncompleted.data.slice(
          0,
          this.uncompletedParticipantsMinShow
        )
      }
      return this.participant.uncompleted.data
    },
  },
}
</script>
<style media="screen">
.add-destination {
  text-decoration: none;
}
.grey-font {
  color: #9ca4ab;
}
.carousel-link {
  text-decoration: none !important;
}
.carousel-name {
  color: black !important;
}
.destinac {
  font-size: 14px;
  color: #26353c;
}
/* .my-text-red {
  color: #da4c43;
  font-weight: 600;
}
.my-text-red:hover {
  color: #ae2b22;
} */
.pointer {
  cursor: pointer;
}
.hov-underline:hover {
  text-decoration: underline;
}
.w27p {
  width: 27px !important;
}
.lh-07 {
  line-height: 0.7em !important;
}
</style>
