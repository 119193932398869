<template lang="html">
  <div class="">
    <Navbar />
    <main>
      <Alert :success="address.success.length != 0" :fail="address.failed.length != 0" />
      <div class="topbar has-image">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-6">
              <div class="d-flex align-items-md-center">
                <div class="my-2 mr-4 mr-md-5">
                  <button @click="$router.go(-1)" class="btn bordered-custom-round btn-round" type="button">
                    <span class="sr-only">Zpět</span>
                    <img src="../assets/img/ico-back.svg" alt="Zpět" class="mx-auto" />
                  </button>
                </div>
                <div>
                  <h1 v-if="address.current.Fetched == true" class="mb-1">{{ address.current.data[0].name }}</h1>
                  <div class="d-md-none mt-3">
                    <div
                      v-if="address.Deleting"
                      style=""
                      class="spinner-border spinner-border-sm myspinner mr-1 mt-3"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <button @click.prevent="$_deletefun" class="btn bordered-custom-round btn-round mr-2" type="button">
                      <span class="sr-only">Delete</span>
                      <img width="20px" src="../assets/img/delete.svg" alt="Delete" class="mx-auto" />
                    </button>
                    <router-link to="/email/contact">
                      <button class="btn bordered-custom-round btn-round mr-2" type="button">
                        <span class="sr-only">Zpráva</span>
                        <img src="../assets/img/ico-message.svg" alt="Zpráva" class="mx-auto" />
                      </button>
                    </router-link>
                    <router-link :to="`/address/update/${this.$router.currentRoute.params.id}`">
                      <button type="button" class="btn btn-primary btn-round">
                        <span class="sr-only">Editovat kontakt</span>
                        <img src="../assets/img/ico-edit-white.svg" alt="Editovat kontakt" />
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 d-none d-md-block text-right">
              <div
                v-if="address.Deleting"
                style=""
                class="spinner-border spinner-border-sm myspinner mr-1 mt-3"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <button @click.prevent="$_deletefun" class="btn bordered-custom-round btn-round mr-2" type="button">
                <span class="sr-only">Delete</span>
                <img width="20px" src="../assets/img/delete.svg" alt="Delete" class="mx-auto" />
              </button>
              <router-link to="/email/contact">
                <button class="btn bordered-custom-round btn-round mr-2" type="button">
                  <span class="sr-only">Zpráva</span>
                  <img src="../assets/img/ico-message.svg" alt="Zpráva" class="mx-auto" />
                </button>
              </router-link>
              <router-link :to="`/address/update/${this.$router.currentRoute.params.id}`">
                <button style="" type="button" class="button-style-round btn btn-primary">
                  <span style="padding: 12px">Editovat kontakt</span>
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div v-if="address.current.Fetching" class="d-flex justify-content-center">
          <div class="spinner-border myspinner" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div v-else v-for="item in address.current.data" :key="item.id" class="form-row">
          <div class="col-lg-3 col-sm">
            <div class="card">
              <div class="card-body">
                <h2 class="card-title">
                  <img src="../assets/img/ico-basic-info.svg" alt="Základní údaje" />
                  Základní údaje
                </h2>
                <table class="table table-striped table-sm m-0">
                  <tbody>
                    <tr>
                      <td class="my-text-bold">{{ item.name }}</td>
                    </tr>
                    <tr>
                      <td class="my-text-bold">
                        <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                      </td>
                    </tr>
                    <tr>
                      <td class="my-text-bold">
                        <a :href="`tel:${item.phone}`">{{ item.phone }}</a>
                      </td>
                    </tr>
                    <tr>
                      <td class="my-text-bold">{{ changeDateFormatTo(item.dob) }}</td>
                    </tr>
                    <tr>
                      <td class="my-text-bold" title="číslo občanského průkazu">
                        <a>{{ item.id_card_number }}</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm">
            <div class="card">
              <div class="card-body">
                <h2 class="card-title">
                  <img src="../assets/img/ico-address.svg" alt="Základní údaje" />
                  Adresa
                </h2>
                <table class="table table-striped table-sm m-0">
                  <tbody>
                    <tr>
                      <td class="my-text-bold">{{ item.street }}</td>
                    </tr>
                    <tr>
                      <td class="my-text-bold">{{ item.city }}</td>
                    </tr>
                    <tr>
                      <td class="my-text-bold">{{ item.zip }}</td>
                    </tr>
                    <tr>
                      <td>
                        <flag class="flag flag-lg align-middle" :iso="`${item.iso}`" />
                        <span class="my-text-bold align-middle">{{ item.country_trnaslated }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="my-text-bold">
                        <flag class="flag flag-lg align-middle" :iso="`${item.nationality_iso}`" />
                        <span class="align-middle">{{ item.nationality_trnaslated }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card">
              <ul class="nav pseudotable flex-column">
                <li class="nav-item pseudoheader">
                  <span class="form-row">
                    <span class="col-sm my-text-bold">
                      Destinace
                      <!--a class="btn btn-link btn-sort" href="#" role="button">Seřadit</a-->
                    </span>
                    <span class="col-sm my-text-bold">
                      Termín
                      <!--a class="btn btn-link btn-sort" href="#" role="button">Seřadit</a-->
                    </span>
                  </span>
                </li>
                <li v-if="address.current.data[0].terms.length == 0"><span>Žádný nenalezen</span></li>
                <li v-else v-for="(d, index) in address.current.data[0].terms" :key="index" class="nav-item">
                  <router-link
                    tabindex="-1"
                    aria-disabled="true"
                    class="nav-link form-row"
                    :to="`/participant/${d.participant_id}`"
                  >
                    <span class="col-sm">
                      <flag class="flag flag-lg" :iso="`${d.iso}`" :alt="`${d.destination_name}`" />
                      <span class="my-text-bold">{{ d.destination_name }}</span>
                    </span>
                    <span class="col-sm my-text-bold">
                      {{ changeDateFormatTo(d.startDate) }} - {{ changeDateFormatTo(d.endDate) }}
                      <img
                        v-if="d.storno"
                        width="20px"
                        src="../assets/img/cancel.svg"
                        alt="storno"
                        class="ml-2"
                        title="Stornováno"
                      />
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p class="text-center d-sm-none mt-4">
          <a href="">
            <img src="../assets/img/ico-logout.svg" alt="Odhlásit se" class="mr-2" />
            Odhlásit se
          </a>
        </p>
      </div>
    </main>
  </div>
</template>

<script>
/* eslint-disable */
import Navbar from '@/components/Navbar.vue'
import Alert from '@/components/Alert.vue'
import '../assets/js/jquery.min.js'
import '../assets/js/bootstrap.bundle.min.js'
import { mapState, mapActions, mapGetters } from 'vuex'
import axios from 'axios'
export default {
  components: {
    Navbar,
    Alert,
  },
  methods: {
    ...mapActions('address', [
      'get_All_Address',
      'getById',
      '_delete',
      'clearmsg',
    ]),
    $_deletefun() {
      const contactID = this.$router.currentRoute.params.id
      this._delete(contactID)
    },
    changeDateFormatTo(date) {
      const [dd, mm, yy] = date.split(/-/g)
      return `${yy}. ${mm}. ${dd}`
    },
  },
  created() {
    document.title = this.getTitle
    const participantID = this.$router.currentRoute.params.id
    this.getById(participantID)
    this.clearmsg()
  },
  computed: {
    ...mapState({
      address: 'address',
    }),
    getTitle() {
      if (typeof this.address.current.data[0] != 'undefined')
        return this.address.current.data[0].name
    },
  },
  watch: {
    getTitle() {
      if (typeof this.address.current.data[0] != 'undefined')
        document.title = this.address.current.data[0].name
    },
  },
  mounted() {},
}
</script>

<style lang="css" scoped>
.bordered-custom-round {
  background-color: white;
  color: black;
  border: 2px solid #da4c43;
  border-radius: 20px !important;
  font-weight: bold;
}
</style>
