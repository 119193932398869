<template lang="html">
  <div class="">
    <Navbar />
    <main>
      <Alert :success="destination.success.length !== 0" :fail="destination.failed.length !== 0" />
      <div class="topbar has-image">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-md-2 col-sm-1 col-2">
              <div class="d-flex align-items-md-center">
                <div class="my-2 mr-4 mr-md-5">
                  <button class="btn bordered-custom-round btn-round" type="button" @click="$router.go(-1)">
                    <span class="sr-only">Zpět</span>
                    <img src="../assets/img/ico-back.svg" alt="Zpět" class="mx-auto" />
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-8 col-sm-10 col-10 text-md-center">
              <h1 class="mb-1">Upravit destinaci</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="card">
          <div v-if="destination.temp.Fetching" class="d-flex justify-content-center">
            <div class="spinner-border myspinner" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <ValidationObserver v-else ref="observer" v-slot="{ invalid }" tag="form">
            <form class="card-body">
              <h2 class="card-title">1. Základní informace</h2>
              <fieldset>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="addDestinationName">Název destinace:</label>
                      <ValidationProvider v-slot="{ errors }" name="name" rules="required">
                        <input
                          id="addDestinationName"
                          v-model="destination.temp.data[0].name"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-md-4 form-group">
                    <label for="addDestinationCountry">Země:</label>
                    <ValidationProvider v-slot="{ errors }" name="country" rules="required">
                      <select
                        id="addDestinationCountry"
                        v-model="destination.temp.data[0].country"
                        class="custom-select custom-select-sm"
                      >
                        <option disabled value="country" selected>Země</option>
                        <option v-for="country in closeCountriesData" :key="country.id" :value="`${country.name}`">
                          {{ country.human_name }}
                        </option>
                      </select>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4">
                    <label for="addDestinationMail">E-mail:</label>
                    <ValidationProvider v-slot="{ errors }" name="mail" rules="required">
                      <select
                        id="addDestinationMail"
                        v-model="destination.temp.data[0].email"
                        class="custom-select custom-select-sm"
                      >
                        <option disabled value="mail" selected>E-mail</option>
                        <option v-for="mail in mailsData" :key="mail" :value="`${mail}`">
                          {{ mail }}
                        </option>
                      </select>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4">
                    <p class="mb-1"><strong>Stav:</strong></p>
                    <div class="row custom-radio-select mx-2 mx-md-0">
                      <div class="col-6">
                        <div
                          class="custom-control card custom-input text-center w-auto"
                          :class="{
                            selected: destination.temp.data[0].status === 'active',
                          }"
                        >
                          <input
                            id="radio1"
                            v-model="destination.temp.data[0].status"
                            type="radio"
                            class="radio_item"
                            value="active"
                            name="radio_group_1"
                          />
                          <label class="label_item" for="radio1">
                            <img class="img-tick" width="14px" style="margin: 3px" src="../assets/img/ico-check.svg" />
                            <span style="text-decoration: underline; font-weight: 600" class="text-primary">
                              aktivní
                            </span>
                          </label>
                        </div>
                      </div>
                      <div class="col-6">
                        <div
                          style=""
                          class="card custom-input text-center ml-1 w-auto"
                          :class="{
                            selected: destination.temp.data[0].status === 'inactive',
                          }"
                        >
                          <input
                            id="radio2"
                            v-model="destination.temp.data[0].status"
                            type="radio"
                            class="radio_item"
                            value="inactive"
                            name="radio_group_1"
                          />
                          <label class="label_item" for="radio2">
                            <img class="img-tick" width="14px" style="margin: 3px" src="../assets/img/ico-check.svg" />
                            <span style="text-decoration: underline; font-weight: 600" class="text-primary">
                              neaktivní
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <hr class="my-4" />
              <h2 class="card-title">2. Termíny</h2>
              <fieldset>
                <div v-for="(tdata, index) in getPublicTerms()" :key="index" class="row">
                  <div class="col-md-1 d-flex align-items-center justify-content-center">
                    <span
                      v-if="tdata.custom"
                      :style="`--background:SlateBlue;`"
                      :data-letters="`i`"
                      class="col-md col-sm-6 my-text-bold"
                    ></span>
                  </div>
                  <div class="col-md-2 form-group">
                    <label for="addDestinationCode">Kód:</label>
                    <input
                      id="addDestinationCode"
                      v-model="tdata.code"
                      type="text"
                      class="form-control form-control-sm"
                      required
                    />
                  </div>
                  <div class="col-md-2 form-group">
                    <label for="addDestinationDateFrom">Datum od:</label>
                    <ValidationProvider v-slot="{ errors }" name="startDate" rules="required">
                      <date-pick
                        v-model="tdata.startDate"
                        :input-attributes="{ readonly: true }"
                        class=""
                        :display-format="'DD. MM. YYYY'"
                      ></date-pick>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-2 form-group">
                    <label for="addDestinationDateTo">Datum do:</label>
                    <ValidationProvider v-slot="{ errors }" name="endDate" rules="required">
                      <date-pick
                        v-model="tdata.endDate"
                        :input-attributes="{ readonly: true }"
                        class=""
                        :display-format="'DD. MM. YYYY'"
                      ></date-pick>

                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-2 form-group">
                    <label>Stav:</label>
                    <select :id="'term-open-' + index" v-model="tdata.status" class="custom-select custom-select-sm">
                      <option v-for="status in statusOptions" :key="status.id" :value="`${status.val}`">
                        {{ status.name }}
                      </option>
                    </select>
                    <!-- <div class="custom-control card custom-input text-center w-auto selected" :class="{'d-none': tdata.open !== 1}">
                    <input :id="'term-open-'+index" v-model="tdata.open" type="radio" class="radio_item" v-bind:value="0">
                    <label :for="'term-open-'+index" class="label_item">
                      <img class="img-tick" width="14px" style="margin:3px;" src="../assets/img/ico-check.svg">
                      <span style="text-decoration:underline;font-weight:600;" class="text-primary">Volný</span>
                    </label>
                  </div>
                  <div class="card custom-input text-center w-auto selected" :class="{'d-none': tdata.open !== 0 }">
                    <input :id="'term-close-'+index" v-model="tdata.open" type="radio" class="radio_item" v-bind:value="1">
                    <label :for="'term-close-'+index" class="label_item">
                      <img class="img-tick" width="14px" style="margin:3px;" src="../assets/img/ico-check.svg">
                      <span style="text-decoration:underline;font-weight:600;" class="text-primary">Obsazený</span>
                    </label>
                  </div> -->
                  </div>
                  <!-- <div class="col-md-1">
                  <br />
                  <img style="margin-top:9px" @click.prevent="deleteTerm(index)" class="delete-button" width="30px" src="../assets/img/cancel.svg" alt="delete">
                </div> -->
                </div>
              </fieldset>

              <fieldset class="fieldset-custom">
                <div v-for="(term, index) in terminputs" :key="index">
                  <div class="row">
                    <div class="col-md-1">
                      <span
                        v-if="term.custom"
                        :style="`--background:SlateBlue;`"
                        :data-letters="`i`"
                        class="col-md col-sm-6 my-text-bold"
                      ></span>
                    </div>
                    <div class="col-md-2 form-group">
                      <label for="">Kód:</label>
                      <input v-model="term.code" type="text" class="form-control form-control-sm" required />
                    </div>
                    <div class="col-md-2 form-group">
                      <label for="">Datum od:</label>
                      <date-pick
                        v-model="term.startDate"
                        :input-attributes="{ readonly: true }"
                        class=""
                        :display-format="'DD. MM. YYYY'"
                      ></date-pick>
                    </div>
                    <div class="col-md-2 form-group">
                      <label for="">Datum do:</label>
                      <date-pick
                        v-model="term.endDate"
                        :input-attributes="{ readonly: true }"
                        class=""
                        :display-format="'DD. MM. YYYY'"
                      ></date-pick>
                    </div>

                    <div class="col-md-2 form-group">
                      <!-- <label>Stav:</label>
                  <div class="custom-control card custom-input text-center w-auto selected" :class="{'d-none': term.open !== 1}">
                    <input :id="'new_term-open-'+index" v-model="term.open" type="radio" class="radio_item" v-bind:value="0">
                    <label :for="'new_term-open-'+index" class="label_item">
                      <img class="img-tick" width="14px" style="margin:3px;" src="../assets/img/ico-check.svg">
                      <span style="text-decoration:underline;font-weight:600;" class="text-primary">Volný</span>
                    </label>
                  </div>
                  <div class="card custom-input text-center w-auto selected" :class="{'d-none': term.open !== 0 }">
                    <input :id="'new_term-close-'+index" v-model="term.open" type="radio" class="radio_item" v-bind:value="1">
                    <label :for="'new_term-close-'+index" class="label_item">
                      <img class="img-tick" width="14px" style="margin:3px;" src="../assets/img/ico-check.svg">
                      <span style="text-decoration:underline;font-weight:600;" class="text-primary">Obsazený</span>
                    </label> -->
                      <label>Stav:</label>
                      <select
                        :id="'new_term-open-' + index"
                        v-model="term.status"
                        class="custom-select custom-select-sm"
                      >
                        <option v-for="status in statusOptions" :key="status.id" :value="`${status.val}`">
                          {{ status.name }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-1">
                      <br />
                      <img
                        class="delete-button"
                        width="30px"
                        src="../assets/img/cancel.svg"
                        alt="delete"
                        @click.prevent="deleteRowTerm(index)"
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
              <br />
              <a class="my-3 ml-3 ml-sm-4" @click.prevent="addRowTerm">
                <span style="font-weight: 600; font-size: 16px" class="text-primary">přidat další termín</span>
                &nbsp;&nbsp;
                <img src="../assets/img/ico-new.svg" alt="přidat další Termín" />
              </a>
              <hr class="my-4" />
              <h2 class="card-title">3. Balíčky</h2>
              <fieldset>
                <div v-for="(pdata, index) in getPublicPackages()" :key="index" class="row">
                  <div class="col-md-4 form-group">
                    <label for="addDestinationPackageName">Název:</label>
                    <ValidationProvider v-slot="{ errors }" name="packageName" rules="required">
                      <input
                        id="addDestinationPackageName"
                        v-model="pdata.name"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 form-group">
                    <label for="addDestinationPackagePrice">Cena za 1 termín:</label>
                    <ValidationProvider v-slot="{ errors }" name="packageValue" rules="required">
                      <input
                        id="addDestinationPackagePrice"
                        v-model="pdata.price"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-1">
                    <img
                      style="margin-top: 18px"
                      class="delete-button"
                      width="30px"
                      src="../assets/img/cancel.svg"
                      alt="delete"
                      @click.prevent="deletePackages(index)"
                    />
                  </div>
                </div>
              </fieldset>

              <fieldset class="fieldset-custom">
                <div v-for="(input, index) in inputs" :key="index">
                  <div class="row">
                    <div class="col-md-4 form-group">
                      <input v-model="input.name" type="text" class="form-control form-control-sm" />
                    </div>
                    <div class="col-md-4 form-group">
                      <input v-model="input.value" type="text" class="form-control form-control-sm" />
                    </div>

                    <img
                      class="delete-button"
                      width="30px"
                      src="../assets/img/cancel.svg"
                      alt="delete"
                      @click.prevent="deleteRow(index)"
                    />
                  </div>
                </div>
              </fieldset>
              <br />
              <a class="my-3 ml-3 ml-sm-4" @click.prevent="addRow">
                <span style="font-weight: 600; font-size: 16px" class="text-primary">přidat další balíček</span>
                &nbsp;&nbsp;
                <img src="../assets/img/ico-new.svg" alt="přidat další balíček" />
              </a>
              <hr class="my-4" />
              <div class="row">
                <div class="col-md-3">
                  <button
                    style=""
                    type="button"
                    class="button-style-round btn btn-primary mt-4 ml-3 ml-sm-4 mb-3"
                    @click.prevent="$_emitdata()"
                  >
                    <span class="">Uložit destinaci</span>
                  </button>
                </div>
                <div class="col">
                  <div
                    v-if="destination.Fetching"
                    style="margin-top: 26px"
                    class="spinner-border myspinner"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <p class="text-center d-sm-none mt-4">
          <a href="">
            <img src="../assets/img/ico-logout.svg" alt="Odhlásit se" class="mr-2" />
            Odhlásit se
          </a>
        </p>
      </div>
    </main>
  </div>
</template>

<script>
/* eslint-disable */
import Navbar from '@/components/Navbar.vue'
import Alert from '@/components/Alert.vue'
import '../assets/js/jquery.min.js'
import '../assets/js/bootstrap.bundle.min.js'
import { config } from '@/services/config.js'
import { mapState, mapActions, mapGetters } from 'vuex'
import axios from 'axios'
export default {
  components: {
    Navbar,
    Alert,
  },

  data() {
    return {
      startDate: '',
      endDate: '',
      code: '',
      open: '',
      inputs: [],
      terminputs: [],
      countriesData: [],
      closeCountriesData: [],
      mailsData: [],
      packageName: '',
      packageValue: '',
      statusOptions: [
        { val: 1, name: 'Dostupný' },
        { val: 2, name: 'Obsazený' },
        { val: 5, name: 'Zrušený' },
        { val: 3, name: 'Skrytý' },
        { val: 4, name: 'Smazaný' },
      ],
    }
  },

  computed: {
    ...mapState({ destination: 'destination' }),
    getTitle() {
      if (typeof this.destination.temp.data[0] != 'undefined')
        return this.destination.temp.data[0].name
    },
  },

  created() {
    document.title = this.getTitle
    const id = this.$router.currentRoute.params.id
    this.getDestinationById(id)
    this.clearmsg()
    this.listCountries()
    this.listDestinationMails()
  },

  watch: {
    getTitle() {
      if (typeof this.destination.temp.data[0] != 'undefined')
        document.title = this.destination.temp.data[0].name
    },
  },

  methods: {
    getAuthToken() {
      const userData = window.localStorage.getItem('user')
      const user = userData ? JSON.parse(userData) : null
      if (user) {
        return `Bearer ${user.data.api_token}`
      }
    },

    getBasicHeaders() {
      return {
        headers: {
          Accept: 'application/json',
          Authorization: this.getAuthToken(),
        },
      }
    },

    getPublicTerms() {
      return this.destination.temp.data[0].terms.filter(
        (item) => item.custom != 1
      )
    },
    getPublicPackages() {
      return this.destination.temp.data[0].packages.filter(
        (item) => item.custom != 1
      )
    },
    ...mapActions('destination', [
      'update',
      'get_All_Destination',
      'getDestinationById',
      '_delete',
      'update',
      'clearmsg',
    ]),
    async listCountries() {
      const res = await axios.get(
        `${config.getUrl}/countries/all`,
        this.getBasicHeaders()
      )
      this.countriesData = res.data
      this.closeCountriesData = this.countriesData
        .filter((item) => item.close == 1)
        .sort((a, b) => a.slug > b.slug)
    },
    async listDestinationMails() {
      const res = await axios.get(
        `${config.getUrl}/destination/mail/list`,
        this.getBasicHeaders()
      )
      this.mailsData = res.data
    },
    changeDateFormatTo(date) {
      const [dd, mm, yy] = date.split(/-/g)
      return `${yy}. ${mm}. ${dd}`
    },
    deleteTerm(index) {
      //console.log(index)
      this.destination.temp.data[0].terms.splice(index, 1)
    },
    deletePackages(index) {
      this.destination.temp.data[0].packages.splice(index, 1)
    },
    async $_emitdata() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        console.log('Error')
      } else {
        this.destination.temp.data[0].packages.push(...this.inputs)
        this.destination.temp.data[0].terms.push(...this.terminputs)

        this.inputs = []
        this.terminputs = []
        //  console.log(this.destination.temp.data)

        this.update(this.destination.temp.data)

        window.scrollTo(0, 0)
      }
    },
    addRow() {
      this.inputs.push({
        name: '',
        value: '',
      })
    },
    addRowTerm() {
      this.terminputs.push({
        startDate: '',
        endDate: '',
        open: 1,
      })
    },
    deleteRowTerm(index) {
      this.terminputs.splice(index, 1)
    },
    deleteRow(index) {
      this.inputs.splice(index, 1)
    },
  },

  mounted() {},
}
</script>

<style lang="css" scoped>
.custom-input {
  height: calc(1.5em + 0.5rem + 4px);
  width: 48%;
  background: transparent;
}
.img-tick {
  display: none;
}
.radio_item {
  display: none !important;
}
.selected {
  background: white;
}
.radio_item:checked + label > img {
  display: inline;
}

.radio_item:checked + label {
  opacity: 1;
}

.radio_item:checked + label > span {
  color: #000 !important;
  text-decoration: none !important;
}

.delete-button {
  margin-left: 12px;
  margin-bottom: 12px;
}

.add-button {
  margin-left: 12px;

  width: 90px;
  height: 35px;
}

label {
  cursor: pointer;
}
.error {
  color: red !important;
}
.bordered-custom-round {
  background-color: white;
  color: black;
  border: 2px solid #da4c43;
  border-radius: 20px !important;
  font-weight: bold;
}
/* .topbar {
} */
.custom-alert {
  padding: 9px;
}
.custom-alert-button {
  margin: -5px;
}
.fieldset-custom {
  padding-top: 0 !important;
}
@media (max-width: 767px) {
  .custom-radio-select {
    margin-left: 15px;
  }
}
</style>
