/* eslint-disable*/
/*! jQuery v3.3.1 | (c) JS Foundation and other contributors | jquery.org/license */ !(function (e, t) {
  'use strict'
  typeof module === 'object' && typeof module.exports === 'object' ? module.exports = e.document ? t(e, !0) : function (e) {
    if (!e.document) throw new Error('jQuery requires a window with a document')
    return t(e)
  } : t(e)
}(typeof window !== 'undefined' ? window : this, function (e, t) {
  'use strict'
  var n = []
  var r = e.document
  var i = Object.getPrototypeOf
  var o = n.slice
  var a = n.concat
  var s = n.push
  var u = n.indexOf
  var l = {}
  var c = l.toString
  var f = l.hasOwnProperty
  var p = f.toString
  var d = p.call(Object)
  var h = {}
  var g = function e (t) {
    return typeof t === 'function' && typeof t.nodeType !== 'number'
  }
  var y = function e (t) {
    return t != null && t === t.window
  }
  var v = {
    type: !0,
    src: !0,
    noModule: !0
  }

  function m (e, t, n) {
    var i
    var o = (t = t || r).createElement('script')
    if (o.text = e, n) { for (i in v) n[i] && (o[i] = n[i]) }
    t.head.appendChild(o).parentNode.removeChild(o)
  }

  function x (e) {
    return e == null ? e + '' : typeof e === 'object' || typeof e === 'function' ? l[c.call(e)] || 'object' : typeof e
  }
  var b = '3.3.1'
  var w = function (e, t) {
    return new w.fn.init(e, t)
  }
  var T = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g
  w.fn = w.prototype = {
    jquery: '3.3.1',
    constructor: w,
    length: 0,
    toArray: function () {
      return o.call(this)
    },
    get: function (e) {
      return e == null ? o.call(this) : e < 0 ? this[e + this.length] : this[e]
    },
    pushStack: function (e) {
      var t = w.merge(this.constructor(), e)
      return t.prevObject = this, t
    },
    each: function (e) {
      return w.each(this, e)
    },
    map: function (e) {
      return this.pushStack(w.map(this, function (t, n) {
        return e.call(t, n, t)
      }))
    },
    slice: function () {
      return this.pushStack(o.apply(this, arguments))
    },
    first: function () {
      return this.eq(0)
    },
    last: function () {
      return this.eq(-1)
    },
    eq: function (e) {
      var t = this.length
      var n = +e + (e < 0 ? t : 0)
      return this.pushStack(n >= 0 && n < t ? [this[n]] : [])
    },
    end: function () {
      return this.prevObject || this.constructor()
    },
    push: s,
    sort: n.sort,
    splice: n.splice
  }, w.extend = w.fn.extend = function () {
    var e
    var t
    var n
    var r
    var i
    var o
    var a = arguments[0] || {}
    var s = 1
    var u = arguments.length
    var l = !1
    for (typeof a === 'boolean' && (l = a, a = arguments[s] || {}, s++), typeof a === 'object' || g(a) || (a = {}), s === u && (a = this, s--); s < u; s++) {
      if ((e = arguments[s]) != null) { for (t in e) n = a[t], a !== (r = e[t]) && (l && r && (w.isPlainObject(r) || (i = Array.isArray(r))) ? (i ? (i = !1, o = n && Array.isArray(n) ? n : []) : o = n && w.isPlainObject(n) ? n : {}, a[t] = w.extend(l, o, r)) : void 0 !== r && (a[t] = r)) }
    }
    return a
  }, w.extend({
    expando: 'jQuery' + ('3.3.1' + Math.random()).replace(/\D/g, ''),
    isReady: !0,
    error: function (e) {
      throw new Error(e)
    },
    noop: function () {},
    isPlainObject: function (e) {
      var t, n
      return !(!e || c.call(e) !== '[object Object]') && (!(t = i(e)) || typeof (n = f.call(t, 'constructor') && t.constructor) === 'function' && p.call(n) === d)
    },
    isEmptyObject: function (e) {
      var t
      for (t in e) return !1
      return !0
    },
    globalEval: function (e) {
      m(e)
    },
    each: function (e, t) {
      var n
      var r = 0
      if (C(e)) {
        for (n = e.length; r < n; r++) { if (!1 === t.call(e[r], r, e[r])) break }
      } else {
        for (r in e) { if (!1 === t.call(e[r], r, e[r])) break }
      }
      return e
    },
    trim: function (e) {
      return e == null ? '' : (e + '').replace(T, '')
    },
    makeArray: function (e, t) {
      var n = t || []
      return e != null && (C(Object(e)) ? w.merge(n, typeof e === 'string' ? [e] : e) : s.call(n, e)), n
    },
    inArray: function (e, t, n) {
      return t == null ? -1 : u.call(t, e, n)
    },
    merge: function (e, t) {
      for (var n = +t.length, r = 0, i = e.length; r < n; r++) e[i++] = t[r]
      return e.length = i, e
    },
    grep: function (e, t, n) {
      for (var r, i = [], o = 0, a = e.length, s = !n; o < a; o++)(r = !t(e[o], o)) !== s && i.push(e[o])
      return i
    },
    map: function (e, t, n) {
      var r
      var i
      var o = 0
      var s = []
      if (C(e)) { for (r = e.length; o < r; o++)(i = t(e[o], o, n)) != null && s.push(i) } else { for (o in e)(i = t(e[o], o, n)) != null && s.push(i) }
      return a.apply([], s)
    },
    guid: 1,
    support: h
  }), typeof Symbol === 'function' && (w.fn[Symbol.iterator] = n[Symbol.iterator]), w.each('Boolean Number String Function Array Date RegExp Object Error Symbol'.split(' '), function (e, t) {
    l['[object ' + t + ']'] = t.toLowerCase()
  })

  function C (e) {
    var t = !!e && 'length' in e && e.length
    var n = x(e)
    return !g(e) && !y(e) && (n === 'array' || t === 0 || typeof t === 'number' && t > 0 && t - 1 in e)
  }
  var E = (function (e) {
    var t
    var n
    var r
    var i
    var o
    var a
    var s
    var u
    var l
    var c
    var f
    var p
    var d
    var h
    var g
    var y
    var v
    var m
    var x
    var b = 'sizzle' + 1 * new Date()
    var w = e.document
    var T = 0
    var C = 0
    var E = ae()
    var k = ae()
    var S = ae()
    var D = function (e, t) {
      return e === t && (f = !0), 0
    }
    var N = {}.hasOwnProperty
    var A = []
    var j = A.pop
    var q = A.push
    var L = A.push
    var H = A.slice
    var O = function (e, t) {
      for (var n = 0, r = e.length; n < r; n++) { if (e[n] === t) return n }
      return -1
    }
    var P = 'checked|selected|async|autofocus|autoplay|controls|defer|disabled|hidden|ismap|loop|multiple|open|readonly|required|scoped'
    var M = '[\\x20\\t\\r\\n\\f]'
    var R = '(?:\\\\.|[\\w-]|[^\0-\\xa0])+'
    var I = '\\[' + M + '*(' + R + ')(?:' + M + '*([*^$|!~]?=)' + M + "*(?:'((?:\\\\.|[^\\\\'])*)'|\"((?:\\\\.|[^\\\\\"])*)\"|(" + R + '))|)' + M + '*\\]'
    var W = ':(' + R + ")(?:\\((('((?:\\\\.|[^\\\\'])*)'|\"((?:\\\\.|[^\\\\\"])*)\")|((?:\\\\.|[^\\\\()[\\]]|" + I + ')*)|.*)\\)|)'
    var $ = new RegExp(M + '+', 'g')
    var B = new RegExp('^' + M + '+|((?:^|[^\\\\])(?:\\\\.)*)' + M + '+$', 'g')
    var F = new RegExp('^' + M + '*,' + M + '*')
    var _ = new RegExp('^' + M + '*([>+~]|' + M + ')' + M + '*')
    var z = new RegExp('=' + M + "*([^\\]'\"]*?)" + M + '*\\]', 'g')
    var X = new RegExp(W)
    var U = new RegExp('^' + R + '$')
    var V = {
      ID: new RegExp('^#(' + R + ')'),
      CLASS: new RegExp('^\\.(' + R + ')'),
      TAG: new RegExp('^(' + R + '|[*])'),
      ATTR: new RegExp('^' + I),
      PSEUDO: new RegExp('^' + W),
      CHILD: new RegExp('^:(only|first|last|nth|nth-last)-(child|of-type)(?:\\(' + M + '*(even|odd|(([+-]|)(\\d*)n|)' + M + '*(?:([+-]|)' + M + '*(\\d+)|))' + M + '*\\)|)', 'i'),
      bool: new RegExp('^(?:' + P + ')$', 'i'),
      needsContext: new RegExp('^' + M + '*[>+~]|:(even|odd|eq|gt|lt|nth|first|last)(?:\\(' + M + '*((?:-\\d)?\\d*)' + M + '*\\)|)(?=[^-]|$)', 'i')
    }
    var G = /^(?:input|select|textarea|button)$/i
    var Y = /^h\d$/i
    var Q = /^[^{]+\{\s*\[native \w/
    var J = /^(?:#([\w-]+)|(\w+)|\.([\w-]+))$/
    var K = /[+~]/
    var Z = new RegExp('\\\\([\\da-f]{1,6}' + M + '?|(' + M + ')|.)', 'ig')
    var ee = function (e, t, n) {
      var r = '0x' + t - 65536
      return r !== r || n ? t : r < 0 ? String.fromCharCode(r + 65536) : String.fromCharCode(r >> 10 | 55296, 1023 & r | 56320)
    }
    var te = /([\0-\x1f\x7f]|^-?\d)|^-$|[^\0-\x1f\x7f-\uFFFF\w-]/g
    var ne = function (e, t) {
      return t ? e === '\0' ? '\ufffd' : e.slice(0, -1) + '\\' + e.charCodeAt(e.length - 1).toString(16) + ' ' : '\\' + e
    }
    var re = function () {
      p()
    }
    var ie = me(function (e) {
      return !0 === e.disabled && ('form' in e || 'label' in e)
    }, {
      dir: 'parentNode',
      next: 'legend'
    })
    try {
      L.apply(A = H.call(w.childNodes), w.childNodes), A[w.childNodes.length].nodeType
    } catch (e) {
      L = {
        apply: A.length ? function (e, t) {
          q.apply(e, H.call(t))
        } : function (e, t) {
          var n = e.length
          var r = 0
          while (e[n++] = t[r++]);
          e.length = n - 1
        }
      }
    }

    function oe (e, t, r, i) {
      var o
      var s
      var l
      var c
      var f
      var h
      var v
      var m = t && t.ownerDocument
      var T = t ? t.nodeType : 9
      if (r = r || [], typeof e !== 'string' || !e || T !== 1 && T !== 9 && T !== 11) return r
      if (!i && ((t ? t.ownerDocument || t : w) !== d && p(t), t = t || d, g)) {
        if (T !== 11 && (f = J.exec(e))) {
          if (o = f[1]) {
            if (T === 9) {
              if (!(l = t.getElementById(o))) return r
              if (l.id === o) return r.push(l), r
            } else if (m && (l = m.getElementById(o)) && x(t, l) && l.id === o) return r.push(l), r
          } else {
            if (f[2]) return L.apply(r, t.getElementsByTagName(e)), r
            if ((o = f[3]) && n.getElementsByClassName && t.getElementsByClassName) return L.apply(r, t.getElementsByClassName(o)), r
          }
        } if (n.qsa && !S[e + ' '] && (!y || !y.test(e))) {
          if (T !== 1) m = t, v = e
          else if (t.nodeName.toLowerCase() !== 'object') {
            (c = t.getAttribute('id')) ? c = c.replace(te, ne) : t.setAttribute('id', c = b), s = (h = a(e)).length
            while (s--) h[s] = '#' + c + ' ' + ve(h[s])
            v = h.join(','), m = K.test(e) && ge(t.parentNode) || t
          }
          if (v) {
            try {
              return L.apply(r, m.querySelectorAll(v)), r
            } catch (e) {} finally {
              c === b && t.removeAttribute('id')
            }
          }
        }
      }
      return u(e.replace(B, '$1'), t, r, i)
    }

    function ae () {
      var e = []

      function t (n, i) {
        return e.push(n + ' ') > r.cacheLength && delete t[e.shift()], t[n + ' '] = i
      }
      return t
    }

    function se (e) {
      return e[b] = !0, e
    }

    function ue (e) {
      var t = d.createElement('fieldset')
      try {
        return !!e(t)
      } catch (e) {
        return !1
      } finally {
        t.parentNode && t.parentNode.removeChild(t), t = null
      }
    }

    function le (e, t) {
      var n = e.split('|')
      var i = n.length
      while (i--) r.attrHandle[n[i]] = t
    }

    function ce (e, t) {
      var n = t && e
      var r = n && e.nodeType === 1 && t.nodeType === 1 && e.sourceIndex - t.sourceIndex
      if (r) return r
      if (n) {
        while (n = n.nextSibling) { if (n === t) return -1 }
      }
      return e ? 1 : -1
    }

    function fe (e) {
      return function (t) {
        return t.nodeName.toLowerCase() === 'input' && t.type === e
      }
    }

    function pe (e) {
      return function (t) {
        var n = t.nodeName.toLowerCase()
        return (n === 'input' || n === 'button') && t.type === e
      }
    }

    function de (e) {
      return function (t) {
        return 'form' in t ? t.parentNode && !1 === t.disabled ? 'label' in t ? 'label' in t.parentNode ? t.parentNode.disabled === e : t.disabled === e : t.isDisabled === e || t.isDisabled !== !e && ie(t) === e : t.disabled === e : 'label' in t && t.disabled === e
      }
    }

    function he (e) {
      return se(function (t) {
        return t = +t, se(function (n, r) {
          var i
          var o = e([], n.length, t)
          var a = o.length
          while (a--) n[i = o[a]] && (n[i] = !(r[i] = n[i]))
        })
      })
    }

    function ge (e) {
      return e && typeof e.getElementsByTagName !== 'undefined' && e
    }
    n = oe.support = {}, o = oe.isXML = function (e) {
      var t = e && (e.ownerDocument || e).documentElement
      return !!t && t.nodeName !== 'HTML'
    }, p = oe.setDocument = function (e) {
      var t
      var i
      var a = e ? e.ownerDocument || e : w
      return a !== d && a.nodeType === 9 && a.documentElement ? (d = a, h = d.documentElement, g = !o(d), w !== d && (i = d.defaultView) && i.top !== i && (i.addEventListener ? i.addEventListener('unload', re, !1) : i.attachEvent && i.attachEvent('onunload', re)), n.attributes = ue(function (e) {
        return e.className = 'i', !e.getAttribute('className')
      }), n.getElementsByTagName = ue(function (e) {
        return e.appendChild(d.createComment('')), !e.getElementsByTagName('*').length
      }), n.getElementsByClassName = Q.test(d.getElementsByClassName), n.getById = ue(function (e) {
        return h.appendChild(e).id = b, !d.getElementsByName || !d.getElementsByName(b).length
      }), n.getById ? (r.filter.ID = function (e) {
        var t = e.replace(Z, ee)
        return function (e) {
          return e.getAttribute('id') === t
        }
      }, r.find.ID = function (e, t) {
        if (typeof t.getElementById !== 'undefined' && g) {
          var n = t.getElementById(e)
          return n ? [n] : []
        }
      }) : (r.filter.ID = function (e) {
        var t = e.replace(Z, ee)
        return function (e) {
          var n = typeof e.getAttributeNode !== 'undefined' && e.getAttributeNode('id')
          return n && n.value === t
        }
      }, r.find.ID = function (e, t) {
        if (typeof t.getElementById !== 'undefined' && g) {
          var n
          var r
          var i
          var o = t.getElementById(e)
          if (o) {
            if ((n = o.getAttributeNode('id')) && n.value === e) return [o]
            i = t.getElementsByName(e), r = 0
            while (o = i[r++]) { if ((n = o.getAttributeNode('id')) && n.value === e) return [o] }
          }
          return []
        }
      }), r.find.TAG = n.getElementsByTagName ? function (e, t) {
        return typeof t.getElementsByTagName !== 'undefined' ? t.getElementsByTagName(e) : n.qsa ? t.querySelectorAll(e) : void 0
      } : function (e, t) {
        var n
        var r = []
        var i = 0
        var o = t.getElementsByTagName(e)
        if (e === '*') {
          while (n = o[i++]) n.nodeType === 1 && r.push(n)
          return r
        }
        return o
      }, r.find.CLASS = n.getElementsByClassName && function (e, t) {
        if (typeof t.getElementsByClassName !== 'undefined' && g) return t.getElementsByClassName(e)
      }, v = [], y = [], (n.qsa = Q.test(d.querySelectorAll)) && (ue(function (e) {
        h.appendChild(e).innerHTML = "<a id='" + b + "'></a><select id='" + b + "-\r\\' msallowcapture=''><option selected=''></option></select>", e.querySelectorAll("[msallowcapture^='']").length && y.push('[*^$]=' + M + "*(?:''|\"\")"), e.querySelectorAll('[selected]').length || y.push('\\[' + M + '*(?:value|' + P + ')'), e.querySelectorAll('[id~=' + b + '-]').length || y.push('~='), e.querySelectorAll(':checked').length || y.push(':checked'), e.querySelectorAll('a#' + b + '+*').length || y.push('.#.+[+~]')
      }), ue(function (e) {
        e.innerHTML = "<a href='' disabled='disabled'></a><select disabled='disabled'><option/></select>"
        var t = d.createElement('input')
        t.setAttribute('type', 'hidden'), e.appendChild(t).setAttribute('name', 'D'), e.querySelectorAll('[name=d]').length && y.push('name' + M + '*[*^$|!~]?='), e.querySelectorAll(':enabled').length !== 2 && y.push(':enabled', ':disabled'), h.appendChild(e).disabled = !0, e.querySelectorAll(':disabled').length !== 2 && y.push(':enabled', ':disabled'), e.querySelectorAll('*,:x'), y.push(',.*:')
      })), (n.matchesSelector = Q.test(m = h.matches || h.webkitMatchesSelector || h.mozMatchesSelector || h.oMatchesSelector || h.msMatchesSelector)) && ue(function (e) {
        n.disconnectedMatch = m.call(e, '*'), m.call(e, "[s!='']:x"), v.push('!=', W)
      }), y = y.length && new RegExp(y.join('|')), v = v.length && new RegExp(v.join('|')), t = Q.test(h.compareDocumentPosition), x = t || Q.test(h.contains) ? function (e, t) {
        var n = e.nodeType === 9 ? e.documentElement : e
        var r = t && t.parentNode
        return e === r || !(!r || r.nodeType !== 1 || !(n.contains ? n.contains(r) : e.compareDocumentPosition && 16 & e.compareDocumentPosition(r)))
      } : function (e, t) {
        if (t) {
          while (t = t.parentNode) { if (t === e) return !0 }
        }
        return !1
      }, D = t ? function (e, t) {
        if (e === t) return f = !0, 0
        var r = !e.compareDocumentPosition - !t.compareDocumentPosition
        return r || (1 & (r = (e.ownerDocument || e) === (t.ownerDocument || t) ? e.compareDocumentPosition(t) : 1) || !n.sortDetached && t.compareDocumentPosition(e) === r ? e === d || e.ownerDocument === w && x(w, e) ? -1 : t === d || t.ownerDocument === w && x(w, t) ? 1 : c ? O(c, e) - O(c, t) : 0 : 4 & r ? -1 : 1)
      } : function (e, t) {
        if (e === t) return f = !0, 0
        var n
        var r = 0
        var i = e.parentNode
        var o = t.parentNode
        var a = [e]
        var s = [t]
        if (!i || !o) return e === d ? -1 : t === d ? 1 : i ? -1 : o ? 1 : c ? O(c, e) - O(c, t) : 0
        if (i === o) return ce(e, t)
        n = e
        while (n = n.parentNode) a.unshift(n)
        n = t
        while (n = n.parentNode) s.unshift(n)
        while (a[r] === s[r]) r++
        return r ? ce(a[r], s[r]) : a[r] === w ? -1 : s[r] === w ? 1 : 0
      }, d) : d
    }, oe.matches = function (e, t) {
      return oe(e, null, null, t)
    }, oe.matchesSelector = function (e, t) {
      if ((e.ownerDocument || e) !== d && p(e), t = t.replace(z, "='$1']"), n.matchesSelector && g && !S[t + ' '] && (!v || !v.test(t)) && (!y || !y.test(t))) {
        try {
          var r = m.call(e, t)
          if (r || n.disconnectedMatch || e.document && e.document.nodeType !== 11) return r
        } catch (e) {}
      }
      return oe(t, d, null, [e]).length > 0
    }, oe.contains = function (e, t) {
      return (e.ownerDocument || e) !== d && p(e), x(e, t)
    }, oe.attr = function (e, t) {
      (e.ownerDocument || e) !== d && p(e)
      var i = r.attrHandle[t.toLowerCase()]
      var o = i && N.call(r.attrHandle, t.toLowerCase()) ? i(e, t, !g) : void 0
      return void 0 !== o ? o : n.attributes || !g ? e.getAttribute(t) : (o = e.getAttributeNode(t)) && o.specified ? o.value : null
    }, oe.escape = function (e) {
      return (e + '').replace(te, ne)
    }, oe.error = function (e) {
      throw new Error('Syntax error, unrecognized expression: ' + e)
    }, oe.uniqueSort = function (e) {
      var t
      var r = []
      var i = 0
      var o = 0
      if (f = !n.detectDuplicates, c = !n.sortStable && e.slice(0), e.sort(D), f) {
        while (t = e[o++]) t === e[o] && (i = r.push(o))
        while (i--) e.splice(r[i], 1)
      }
      return c = null, e
    }, i = oe.getText = function (e) {
      var t
      var n = ''
      var r = 0
      var o = e.nodeType
      if (o) {
        if (o === 1 || o === 9 || o === 11) {
          if (typeof e.textContent === 'string') return e.textContent
          for (e = e.firstChild; e; e = e.nextSibling) n += i(e)
        } else if (o === 3 || o === 4) return e.nodeValue
      } else { while (t = e[r++]) n += i(t) }
      return n
    }, (r = oe.selectors = {
      cacheLength: 50,
      createPseudo: se,
      match: V,
      attrHandle: {},
      find: {},
      relative: {
        '>': {
          dir: 'parentNode',
          first: !0
        },
        ' ': {
          dir: 'parentNode'
        },
        '+': {
          dir: 'previousSibling',
          first: !0
        },
        '~': {
          dir: 'previousSibling'
        }
      },
      preFilter: {
        ATTR: function (e) {
          return e[1] = e[1].replace(Z, ee), e[3] = (e[3] || e[4] || e[5] || '').replace(Z, ee), e[2] === '~=' && (e[3] = ' ' + e[3] + ' '), e.slice(0, 4)
        },
        CHILD: function (e) {
          return e[1] = e[1].toLowerCase(), e[1].slice(0, 3) === 'nth' ? (e[3] || oe.error(e[0]), e[4] = +(e[4] ? e[5] + (e[6] || 1) : 2 * (e[3] === 'even' || e[3] === 'odd')), e[5] = +(e[7] + e[8] || e[3] === 'odd')) : e[3] && oe.error(e[0]), e
        },
        PSEUDO: function (e) {
          var t
          var n = !e[6] && e[2]
          return V.CHILD.test(e[0]) ? null : (e[3] ? e[2] = e[4] || e[5] || '' : n && X.test(n) && (t = a(n, !0)) && (t = n.indexOf(')', n.length - t) - n.length) && (e[0] = e[0].slice(0, t), e[2] = n.slice(0, t)), e.slice(0, 3))
        }
      },
      filter: {
        TAG: function (e) {
          var t = e.replace(Z, ee).toLowerCase()
          return e === '*' ? function () {
            return !0
          } : function (e) {
            return e.nodeName && e.nodeName.toLowerCase() === t
          }
        },
        CLASS: function (e) {
          var t = E[e + ' ']
          return t || (t = new RegExp('(^|' + M + ')' + e + '(' + M + '|$)')) && E(e, function (e) {
            return t.test(typeof e.className === 'string' && e.className || typeof e.getAttribute !== 'undefined' && e.getAttribute('class') || '')
          })
        },
        ATTR: function (e, t, n) {
          return function (r) {
            var i = oe.attr(r, e)
            return i == null ? t === '!=' : !t || (i += '', t === '=' ? i === n : t === '!=' ? i !== n : t === '^=' ? n && i.indexOf(n) === 0 : t === '*=' ? n && i.indexOf(n) > -1 : t === '$=' ? n && i.slice(-n.length) === n : t === '~=' ? (' ' + i.replace($, ' ') + ' ').indexOf(n) > -1 : t === '|=' && (i === n || i.slice(0, n.length + 1) === n + '-'))
          }
        },
        CHILD: function (e, t, n, r, i) {
          var o = e.slice(0, 3) !== 'nth'
          var a = e.slice(-4) !== 'last'
          var s = t === 'of-type'
          return r === 1 && i === 0 ? function (e) {
            return !!e.parentNode
          } : function (t, n, u) {
            var l
            var c
            var f
            var p
            var d
            var h
            var g = o !== a ? 'nextSibling' : 'previousSibling'
            var y = t.parentNode
            var v = s && t.nodeName.toLowerCase()
            var m = !u && !s
            var x = !1
            if (y) {
              if (o) {
                while (g) {
                  p = t
                  while (p = p[g]) { if (s ? p.nodeName.toLowerCase() === v : p.nodeType === 1) return !1 }
                  h = g = e === 'only' && !h && 'nextSibling'
                }
                return !0
              }
              if (h = [a ? y.firstChild : y.lastChild], a && m) {
                x = (d = (l = (c = (f = (p = y)[b] || (p[b] = {}))[p.uniqueID] || (f[p.uniqueID] = {}))[e] || [])[0] === T && l[1]) && l[2], p = d && y.childNodes[d]
                while (p = ++d && p && p[g] || (x = d = 0) || h.pop()) {
                  if (p.nodeType === 1 && ++x && p === t) {
                    c[e] = [T, d, x]
                    break
                  }
                }
              } else if (m && (x = d = (l = (c = (f = (p = t)[b] || (p[b] = {}))[p.uniqueID] || (f[p.uniqueID] = {}))[e] || [])[0] === T && l[1]), !1 === x) {
                while (p = ++d && p && p[g] || (x = d = 0) || h.pop()) { if ((s ? p.nodeName.toLowerCase() === v : p.nodeType === 1) && ++x && (m && ((c = (f = p[b] || (p[b] = {}))[p.uniqueID] || (f[p.uniqueID] = {}))[e] = [T, x]), p === t)) break }
              }
              return (x -= i) === r || x % r == 0 && x / r >= 0
            }
          }
        },
        PSEUDO: function (e, t) {
          var n
          var i = r.pseudos[e] || r.setFilters[e.toLowerCase()] || oe.error('unsupported pseudo: ' + e)
          return i[b] ? i(t) : i.length > 1 ? (n = [e, e, '', t], r.setFilters.hasOwnProperty(e.toLowerCase()) ? se(function (e, n) {
            var r
            var o = i(e, t)
            var a = o.length
            while (a--) e[r = O(e, o[a])] = !(n[r] = o[a])
          }) : function (e) {
            return i(e, 0, n)
          }) : i
        }
      },
      pseudos: {
        not: se(function (e) {
          var t = []
          var n = []
          var r = s(e.replace(B, '$1'))
          return r[b] ? se(function (e, t, n, i) {
            var o
            var a = r(e, null, i, [])
            var s = e.length
            while (s--)(o = a[s]) && (e[s] = !(t[s] = o))
          }) : function (e, i, o) {
            return t[0] = e, r(t, null, o, n), t[0] = null, !n.pop()
          }
        }),
        has: se(function (e) {
          return function (t) {
            return oe(e, t).length > 0
          }
        }),
        contains: se(function (e) {
          return e = e.replace(Z, ee),
          function (t) {
            return (t.textContent || t.innerText || i(t)).indexOf(e) > -1
          }
        }),
        lang: se(function (e) {
          return U.test(e || '') || oe.error('unsupported lang: ' + e), e = e.replace(Z, ee).toLowerCase(),
          function (t) {
            var n
            do {
              if (n = g ? t.lang : t.getAttribute('xml:lang') || t.getAttribute('lang')) return (n = n.toLowerCase()) === e || n.indexOf(e + '-') === 0
            } while ((t = t.parentNode) && t.nodeType === 1)
            return !1
          }
        }),
        target: function (t) {
          var n = e.location && e.location.hash
          return n && n.slice(1) === t.id
        },
        root: function (e) {
          return e === h
        },
        focus: function (e) {
          return e === d.activeElement && (!d.hasFocus || d.hasFocus()) && !!(e.type || e.href || ~e.tabIndex)
        },
        enabled: de(!1),
        disabled: de(!0),
        checked: function (e) {
          var t = e.nodeName.toLowerCase()
          return t === 'input' && !!e.checked || t === 'option' && !!e.selected
        },
        selected: function (e) {
          return e.parentNode && e.parentNode.selectedIndex, !0 === e.selected
        },
        empty: function (e) {
          for (e = e.firstChild; e; e = e.nextSibling) { if (e.nodeType < 6) return !1 }
          return !0
        },
        parent: function (e) {
          return !r.pseudos.empty(e)
        },
        header: function (e) {
          return Y.test(e.nodeName)
        },
        input: function (e) {
          return G.test(e.nodeName)
        },
        button: function (e) {
          var t = e.nodeName.toLowerCase()
          return t === 'input' && e.type === 'button' || t === 'button'
        },
        text: function (e) {
          var t
          return e.nodeName.toLowerCase() === 'input' && e.type === 'text' && ((t = e.getAttribute('type')) == null || t.toLowerCase() === 'text')
        },
        first: he(function () {
          return [0]
        }),
        last: he(function (e, t) {
          return [t - 1]
        }),
        eq: he(function (e, t, n) {
          return [n < 0 ? n + t : n]
        }),
        even: he(function (e, t) {
          for (var n = 0; n < t; n += 2) e.push(n)
          return e
        }),
        odd: he(function (e, t) {
          for (var n = 1; n < t; n += 2) e.push(n)
          return e
        }),
        lt: he(function (e, t, n) {
          for (var r = n < 0 ? n + t : n; --r >= 0;) e.push(r)
          return e
        }),
        gt: he(function (e, t, n) {
          for (var r = n < 0 ? n + t : n; ++r < t;) e.push(r)
          return e
        })
      }
    }).pseudos.nth = r.pseudos.eq
    for (t in {
      radio: !0,
      checkbox: !0,
      file: !0,
      password: !0,
      image: !0
    }) r.pseudos[t] = fe(t)
    for (t in {
      submit: !0,
      reset: !0
    }) r.pseudos[t] = pe(t)

    function ye () {}
    ye.prototype = r.filters = r.pseudos, r.setFilters = new ye(), a = oe.tokenize = function (e, t) {
      var n
      var i
      var o
      var a
      var s
      var u
      var l
      var c = k[e + ' ']
      if (c) return t ? 0 : c.slice(0)
      s = e, u = [], l = r.preFilter
      while (s) {
        n && !(i = F.exec(s)) || (i && (s = s.slice(i[0].length) || s), u.push(o = [])), n = !1, (i = _.exec(s)) && (n = i.shift(), o.push({
          value: n,
          type: i[0].replace(B, ' ')
        }), s = s.slice(n.length))
        for (a in r.filter) {
          !(i = V[a].exec(s)) || l[a] && !(i = l[a](i)) || (n = i.shift(), o.push({
            value: n,
            type: a,
            matches: i
          }), s = s.slice(n.length))
        }
        if (!n) break
      }
      return t ? s.length : s ? oe.error(e) : k(e, u).slice(0)
    }

    function ve (e) {
      for (var t = 0, n = e.length, r = ''; t < n; t++) r += e[t].value
      return r
    }

    function me (e, t, n) {
      var r = t.dir
      var i = t.next
      var o = i || r
      var a = n && o === 'parentNode'
      var s = C++
      return t.first ? function (t, n, i) {
        while (t = t[r]) { if (t.nodeType === 1 || a) return e(t, n, i) }
        return !1
      } : function (t, n, u) {
        var l
        var c
        var f
        var p = [T, s]
        if (u) {
          while (t = t[r]) { if ((t.nodeType === 1 || a) && e(t, n, u)) return !0 }
        } else {
          while (t = t[r]) {
            if (t.nodeType === 1 || a) {
              if (f = t[b] || (t[b] = {}), c = f[t.uniqueID] || (f[t.uniqueID] = {}), i && i === t.nodeName.toLowerCase()) t = t[r] || t
              else {
                if ((l = c[o]) && l[0] === T && l[1] === s) return p[2] = l[2]
                if (c[o] = p, p[2] = e(t, n, u)) return !0
              }
            }
          }
        } return !1
      }
    }

    function xe (e) {
      return e.length > 1 ? function (t, n, r) {
        var i = e.length
        while (i--) { if (!e[i](t, n, r)) return !1 }
        return !0
      } : e[0]
    }

    function be (e, t, n) {
      for (var r = 0, i = t.length; r < i; r++) oe(e, t[r], n)
      return n
    }

    function we (e, t, n, r, i) {
      for (var o, a = [], s = 0, u = e.length, l = t != null; s < u; s++)(o = e[s]) && (n && !n(o, r, i) || (a.push(o), l && t.push(s)))
      return a
    }

    function Te (e, t, n, r, i, o) {
      return r && !r[b] && (r = Te(r)), i && !i[b] && (i = Te(i, o)), se(function (o, a, s, u) {
        var l
        var c
        var f
        var p = []
        var d = []
        var h = a.length
        var g = o || be(t || '*', s.nodeType ? [s] : s, [])
        var y = !e || !o && t ? g : we(g, p, e, s, u)
        var v = n ? i || (o ? e : h || r) ? [] : a : y
        if (n && n(y, v, s, u), r) {
          l = we(v, d), r(l, [], s, u), c = l.length
          while (c--)(f = l[c]) && (v[d[c]] = !(y[d[c]] = f))
        }
        if (o) {
          if (i || e) {
            if (i) {
              l = [], c = v.length
              while (c--)(f = v[c]) && l.push(y[c] = f)
              i(null, v = [], l, u)
            }
            c = v.length
            while (c--)(f = v[c]) && (l = i ? O(o, f) : p[c]) > -1 && (o[l] = !(a[l] = f))
          }
        } else v = we(v === a ? v.splice(h, v.length) : v), i ? i(null, a, v, u) : L.apply(a, v)
      })
    }

    function Ce (e) {
      for (var t, n, i, o = e.length, a = r.relative[e[0].type], s = a || r.relative[' '], u = a ? 1 : 0, c = me(function (e) {
          return e === t
        }, s, !0), f = me(function (e) {
          return O(t, e) > -1
        }, s, !0), p = [function (e, n, r) {
          var i = !a && (r || n !== l) || ((t = n).nodeType ? c(e, n, r) : f(e, n, r))
          return t = null, i
        }]; u < o; u++) {
        if (n = r.relative[e[u].type]) p = [me(xe(p), n)]
        else {
          if ((n = r.filter[e[u].type].apply(null, e[u].matches))[b]) {
            for (i = ++u; i < o; i++) { if (r.relative[e[i].type]) break }
            return Te(u > 1 && xe(p), u > 1 && ve(e.slice(0, u - 1).concat({
              value: e[u - 2].type === ' ' ? '*' : ''
            })).replace(B, '$1'), n, u < i && Ce(e.slice(u, i)), i < o && Ce(e = e.slice(i)), i < o && ve(e))
          }
          p.push(n)
        }
      } return xe(p)
    }

    function Ee (e, t) {
      var n = t.length > 0
      var i = e.length > 0
      var o = function (o, a, s, u, c) {
        var f
        var h
        var y
        var v = 0
        var m = '0'
        var x = o && []
        var b = []
        var w = l
        var C = o || i && r.find.TAG('*', c)
        var E = T += w == null ? 1 : Math.random() || 0.1
        var k = C.length
        for (c && (l = a === d || a || c); m !== k && (f = C[m]) != null; m++) {
          if (i && f) {
            h = 0, a || f.ownerDocument === d || (p(f), s = !g)
            while (y = e[h++]) {
              if (y(f, a || d, s)) {
                u.push(f)
                break
              }
            } c && (T = E)
          }
          n && ((f = !y && f) && v--, o && x.push(f))
        }
        if (v += m, n && m !== v) {
          h = 0
          while (y = t[h++]) y(x, b, a, s)
          if (o) {
            if (v > 0) { while (m--) x[m] || b[m] || (b[m] = j.call(u)) }
            b = we(b)
          }
          L.apply(u, b), c && !o && b.length > 0 && v + t.length > 1 && oe.uniqueSort(u)
        }
        return c && (T = E, l = w), x
      }
      return n ? se(o) : o
    }
    return s = oe.compile = function (e, t) {
      var n
      var r = []
      var i = []
      var o = S[e + ' ']
      if (!o) {
        t || (t = a(e)), n = t.length
        while (n--)(o = Ce(t[n]))[b] ? r.push(o) : i.push(o);
        (o = S(e, Ee(i, r))).selector = e
      }
      return o
    }, u = oe.select = function (e, t, n, i) {
      var o
      var u
      var l
      var c
      var f
      var p = typeof e === 'function' && e
      var d = !i && a(e = p.selector || e)
      if (n = n || [], d.length === 1) {
        if ((u = d[0] = d[0].slice(0)).length > 2 && (l = u[0]).type === 'ID' && t.nodeType === 9 && g && r.relative[u[1].type]) {
          if (!(t = (r.find.ID(l.matches[0].replace(Z, ee), t) || [])[0])) return n
          p && (t = t.parentNode), e = e.slice(u.shift().value.length)
        }
        o = V.needsContext.test(e) ? 0 : u.length
        while (o--) {
          if (l = u[o], r.relative[c = l.type]) break
          if ((f = r.find[c]) && (i = f(l.matches[0].replace(Z, ee), K.test(u[0].type) && ge(t.parentNode) || t))) {
            if (u.splice(o, 1), !(e = i.length && ve(u))) return L.apply(n, i), n
            break
          }
        }
      }
      return (p || s(e, d))(i, t, !g, n, !t || K.test(e) && ge(t.parentNode) || t), n
    }, n.sortStable = b.split('').sort(D).join('') === b, n.detectDuplicates = !!f, p(), n.sortDetached = ue(function (e) {
      return 1 & e.compareDocumentPosition(d.createElement('fieldset'))
    }), ue(function (e) {
      return e.innerHTML = "<a href='#'></a>", e.firstChild.getAttribute('href') === '#'
    }) || le('type|href|height|width', function (e, t, n) {
      if (!n) return e.getAttribute(t, t.toLowerCase() === 'type' ? 1 : 2)
    }), n.attributes && ue(function (e) {
      return e.innerHTML = '<input/>', e.firstChild.setAttribute('value', ''), e.firstChild.getAttribute('value') === ''
    }) || le('value', function (e, t, n) {
      if (!n && e.nodeName.toLowerCase() === 'input') return e.defaultValue
    }), ue(function (e) {
      return e.getAttribute('disabled') == null
    }) || le(P, function (e, t, n) {
      var r
      if (!n) return !0 === e[t] ? t.toLowerCase() : (r = e.getAttributeNode(t)) && r.specified ? r.value : null
    }), oe
  }(e))
  w.find = E, w.expr = E.selectors, w.expr[':'] = w.expr.pseudos, w.uniqueSort = w.unique = E.uniqueSort, w.text = E.getText, w.isXMLDoc = E.isXML, w.contains = E.contains, w.escapeSelector = E.escape
  var k = function (e, t, n) {
    var r = []
    var i = void 0 !== n
    while ((e = e[t]) && e.nodeType !== 9) {
      if (e.nodeType === 1) {
        if (i && w(e).is(n)) break
        r.push(e)
      }
    } return r
  }
  var S = function (e, t) {
    for (var n = []; e; e = e.nextSibling) e.nodeType === 1 && e !== t && n.push(e)
    return n
  }
  var D = w.expr.match.needsContext

  function N (e, t) {
    return e.nodeName && e.nodeName.toLowerCase() === t.toLowerCase()
  }
  var A = /^<([a-z][^\/\0>:\x20\t\r\n\f]*)[\x20\t\r\n\f]*\/?>(?:<\/\1>|)$/i

  function j (e, t, n) {
    return g(t) ? w.grep(e, function (e, r) {
      return !!t.call(e, r, e) !== n
    }) : t.nodeType ? w.grep(e, function (e) {
      return e === t !== n
    }) : typeof t !== 'string' ? w.grep(e, function (e) {
      return u.call(t, e) > -1 !== n
    }) : w.filter(t, e, n)
  }
  w.filter = function (e, t, n) {
    var r = t[0]
    return n && (e = ':not(' + e + ')'), t.length === 1 && r.nodeType === 1 ? w.find.matchesSelector(r, e) ? [r] : [] : w.find.matches(e, w.grep(t, function (e) {
      return e.nodeType === 1
    }))
  }, w.fn.extend({
    find: function (e) {
      var t
      var n
      var r = this.length
      var i = this
      if (typeof e !== 'string') {
        return this.pushStack(w(e).filter(function () {
          for (t = 0; t < r; t++) { if (w.contains(i[t], this)) return !0 }
        }))
      }
      for (n = this.pushStack([]), t = 0; t < r; t++) w.find(e, i[t], n)
      return r > 1 ? w.uniqueSort(n) : n
    },
    filter: function (e) {
      return this.pushStack(j(this, e || [], !1))
    },
    not: function (e) {
      return this.pushStack(j(this, e || [], !0))
    },
    is: function (e) {
      return !!j(this, typeof e === 'string' && D.test(e) ? w(e) : e || [], !1).length
    }
  })
  var q
  var L = /^(?:\s*(<[\w\W]+>)[^>]*|#([\w-]+))$/;
  (w.fn.init = function (e, t, n) {
    var i, o
    if (!e) return this
    if (n = n || q, typeof e === 'string') {
      if (!(i = e[0] === '<' && e[e.length - 1] === '>' && e.length >= 3 ? [null, e, null] : L.exec(e)) || !i[1] && t) return !t || t.jquery ? (t || n).find(e) : this.constructor(t).find(e)
      if (i[1]) {
        if (t = t instanceof w ? t[0] : t, w.merge(this, w.parseHTML(i[1], t && t.nodeType ? t.ownerDocument || t : r, !0)), A.test(i[1]) && w.isPlainObject(t)) { for (i in t) g(this[i]) ? this[i](t[i]) : this.attr(i, t[i]) }
        return this
      }
      return (o = r.getElementById(i[2])) && (this[0] = o, this.length = 1), this
    }
    return e.nodeType ? (this[0] = e, this.length = 1, this) : g(e) ? void 0 !== n.ready ? n.ready(e) : e(w) : w.makeArray(e, this)
  }).prototype = w.fn, q = w(r)
  var H = /^(?:parents|prev(?:Until|All))/
  var O = {
    children: !0,
    contents: !0,
    next: !0,
    prev: !0
  }
  w.fn.extend({
    has: function (e) {
      var t = w(e, this)
      var n = t.length
      return this.filter(function () {
        for (var e = 0; e < n; e++) { if (w.contains(this, t[e])) return !0 }
      })
    },
    closest: function (e, t) {
      var n
      var r = 0
      var i = this.length
      var o = []
      var a = typeof e !== 'string' && w(e)
      if (!D.test(e)) {
        for (; r < i; r++) {
          for (n = this[r]; n && n !== t; n = n.parentNode) {
            if (n.nodeType < 11 && (a ? a.index(n) > -1 : n.nodeType === 1 && w.find.matchesSelector(n, e))) {
              o.push(n)
              break
            }
          }
        }
      } return this.pushStack(o.length > 1 ? w.uniqueSort(o) : o)
    },
    index: function (e) {
      return e ? typeof e === 'string' ? u.call(w(e), this[0]) : u.call(this, e.jquery ? e[0] : e) : this[0] && this[0].parentNode ? this.first().prevAll().length : -1
    },
    add: function (e, t) {
      return this.pushStack(w.uniqueSort(w.merge(this.get(), w(e, t))))
    },
    addBack: function (e) {
      return this.add(e == null ? this.prevObject : this.prevObject.filter(e))
    }
  })

  function P (e, t) {
    while ((e = e[t]) && e.nodeType !== 1);
    return e
  }
  w.each({
    parent: function (e) {
      var t = e.parentNode
      return t && t.nodeType !== 11 ? t : null
    },
    parents: function (e) {
      return k(e, 'parentNode')
    },
    parentsUntil: function (e, t, n) {
      return k(e, 'parentNode', n)
    },
    next: function (e) {
      return P(e, 'nextSibling')
    },
    prev: function (e) {
      return P(e, 'previousSibling')
    },
    nextAll: function (e) {
      return k(e, 'nextSibling')
    },
    prevAll: function (e) {
      return k(e, 'previousSibling')
    },
    nextUntil: function (e, t, n) {
      return k(e, 'nextSibling', n)
    },
    prevUntil: function (e, t, n) {
      return k(e, 'previousSibling', n)
    },
    siblings: function (e) {
      return S((e.parentNode || {}).firstChild, e)
    },
    children: function (e) {
      return S(e.firstChild)
    },
    contents: function (e) {
      return N(e, 'iframe') ? e.contentDocument : (N(e, 'template') && (e = e.content || e), w.merge([], e.childNodes))
    }
  }, function (e, t) {
    w.fn[e] = function (n, r) {
      var i = w.map(this, t, n)
      return e.slice(-5) !== 'Until' && (r = n), r && typeof r === 'string' && (i = w.filter(r, i)), this.length > 1 && (O[e] || w.uniqueSort(i), H.test(e) && i.reverse()), this.pushStack(i)
    }
  })
  var M = /[^\x20\t\r\n\f]+/g

  function R (e) {
    var t = {}
    return w.each(e.match(M) || [], function (e, n) {
      t[n] = !0
    }), t
  }
  w.Callbacks = function (e) {
    e = typeof e === 'string' ? R(e) : w.extend({}, e)
    var t
    var n
    var r
    var i
    var o = []
    var a = []
    var s = -1
    var u = function () {
      for (i = i || e.once, r = t = !0; a.length; s = -1) {
        n = a.shift()
        while (++s < o.length) !1 === o[s].apply(n[0], n[1]) && e.stopOnFalse && (s = o.length, n = !1)
      }
      e.memory || (n = !1), t = !1, i && (o = n ? [] : '')
    }
    var l = {
      add: function () {
        return o && (n && !t && (s = o.length - 1, a.push(n)), (function t (n) {
          w.each(n, function (n, r) {
            g(r) ? e.unique && l.has(r) || o.push(r) : r && r.length && x(r) !== 'string' && t(r)
          })
        }(arguments)), n && !t && u()), this
      },
      remove: function () {
        return w.each(arguments, function (e, t) {
          var n
          while ((n = w.inArray(t, o, n)) > -1) o.splice(n, 1), n <= s && s--
        }), this
      },
      has: function (e) {
        return e ? w.inArray(e, o) > -1 : o.length > 0
      },
      empty: function () {
        return o && (o = []), this
      },
      disable: function () {
        return i = a = [], o = n = '', this
      },
      disabled: function () {
        return !o
      },
      lock: function () {
        return i = a = [], n || t || (o = n = ''), this
      },
      locked: function () {
        return !!i
      },
      fireWith: function (e, n) {
        return i || (n = [e, (n = n || []).slice ? n.slice() : n], a.push(n), t || u()), this
      },
      fire: function () {
        return l.fireWith(this, arguments), this
      },
      fired: function () {
        return !!r
      }
    }
    return l
  }

  function I (e) {
    return e
  }

  function W (e) {
    throw e
  }

  function $ (e, t, n, r) {
    var i
    try {
      e && g(i = e.promise) ? i.call(e).done(t).fail(n) : e && g(i = e.then) ? i.call(e, t, n) : t.apply(void 0, [e].slice(r))
    } catch (e) {
      n.apply(void 0, [e])
    }
  }
  w.extend({
    Deferred: function (t) {
      var n = [
        ['notify', 'progress', w.Callbacks('memory'), w.Callbacks('memory'), 2],
        ['resolve', 'done', w.Callbacks('once memory'), w.Callbacks('once memory'), 0, 'resolved'],
        ['reject', 'fail', w.Callbacks('once memory'), w.Callbacks('once memory'), 1, 'rejected']
      ]
      var r = 'pending'
      var i = {
        state: function () {
          return r
        },
        always: function () {
          return o.done(arguments).fail(arguments), this
        },
        'catch': function (e) {
          return i.then(null, e)
        },
        pipe: function () {
          var e = arguments
          return w.Deferred(function (t) {
            w.each(n, function (n, r) {
              var i = g(e[r[4]]) && e[r[4]]
              o[r[1]](function () {
                var e = i && i.apply(this, arguments)
                e && g(e.promise) ? e.promise().progress(t.notify).done(t.resolve).fail(t.reject) : t[r[0] + 'With'](this, i ? [e] : arguments)
              })
            }), e = null
          }).promise()
        },
        then: function (t, r, i) {
          var o = 0

          function a (t, n, r, i) {
            return function () {
              var s = this
              var u = arguments
              var l = function () {
                var e, l
                if (!(t < o)) {
                  if ((e = r.apply(s, u)) === n.promise()) throw new TypeError('Thenable self-resolution')
                  l = e && (typeof e === 'object' || typeof e === 'function') && e.then, g(l) ? i ? l.call(e, a(o, n, I, i), a(o, n, W, i)) : (o++, l.call(e, a(o, n, I, i), a(o, n, W, i), a(o, n, I, n.notifyWith))) : (r !== I && (s = void 0, u = [e]), (i || n.resolveWith)(s, u))
                }
              }
              var c = i ? l : function () {
                try {
                  l()
                } catch (e) {
                  w.Deferred.exceptionHook && w.Deferred.exceptionHook(e, c.stackTrace), t + 1 >= o && (r !== W && (s = void 0, u = [e]), n.rejectWith(s, u))
                }
              }
              t ? c() : (w.Deferred.getStackHook && (c.stackTrace = w.Deferred.getStackHook()), e.setTimeout(c))
            }
          }
          return w.Deferred(function (e) {
            n[0][3].add(a(0, e, g(i) ? i : I, e.notifyWith)), n[1][3].add(a(0, e, g(t) ? t : I)), n[2][3].add(a(0, e, g(r) ? r : W))
          }).promise()
        },
        promise: function (e) {
          return e != null ? w.extend(e, i) : i
        }
      }
      var o = {}
      return w.each(n, function (e, t) {
        var a = t[2]
        var s = t[5]
        i[t[1]] = a.add, s && a.add(function () {
          r = s
        }, n[3 - e][2].disable, n[3 - e][3].disable, n[0][2].lock, n[0][3].lock), a.add(t[3].fire), o[t[0]] = function () {
          return o[t[0] + 'With'](this === o ? void 0 : this, arguments), this
        }, o[t[0] + 'With'] = a.fireWith
      }), i.promise(o), t && t.call(o, o), o
    },
    when: function (e) {
      var t = arguments.length
      var n = t
      var r = Array(n)
      var i = o.call(arguments)
      var a = w.Deferred()
      var s = function (e) {
        return function (n) {
          r[e] = this, i[e] = arguments.length > 1 ? o.call(arguments) : n, --t || a.resolveWith(r, i)
        }
      }
      if (t <= 1 && ($(e, a.done(s(n)).resolve, a.reject, !t), a.state() === 'pending' || g(i[n] && i[n].then))) return a.then()
      while (n--) $(i[n], s(n), a.reject)
      return a.promise()
    }
  })
  var B = /^(Eval|Internal|Range|Reference|Syntax|Type|URI)Error$/
  w.Deferred.exceptionHook = function (t, n) {
    e.console && e.console.warn && t && B.test(t.name) && e.console.warn('jQuery.Deferred exception: ' + t.message, t.stack, n)
  }, w.readyException = function (t) {
    e.setTimeout(function () {
      throw t
    })
  }
  var F = w.Deferred()
  w.fn.ready = function (e) {
    return F.then(e)['catch'](function (e) {
      w.readyException(e)
    }), this
  }, w.extend({
    isReady: !1,
    readyWait: 1,
    ready: function (e) {
      (!0 === e ? --w.readyWait : w.isReady) || (w.isReady = !0, !0 !== e && --w.readyWait > 0 || F.resolveWith(r, [w]))
    }
  }), w.ready.then = F.then

  function _ () {
    r.removeEventListener('DOMContentLoaded', _), e.removeEventListener('load', _), w.ready()
  }
  r.readyState === 'complete' || r.readyState !== 'loading' && !r.documentElement.doScroll ? e.setTimeout(w.ready) : (r.addEventListener('DOMContentLoaded', _), e.addEventListener('load', _))
  var z = function (e, t, n, r, i, o, a) {
    var s = 0
    var u = e.length
    var l = n == null
    if (x(n) === 'object') {
      i = !0
      for (s in n) z(e, t, s, n[s], !0, o, a)
    } else if (void 0 !== r && (i = !0, g(r) || (a = !0), l && (a ? (t.call(e, r), t = null) : (l = t, t = function (e, t, n) {
      return l.call(w(e), n)
    })), t)) { for (; s < u; s++) t(e[s], n, a ? r : r.call(e[s], s, t(e[s], n))) }
    return i ? e : l ? t.call(e) : u ? t(e[0], n) : o
  }
  var X = /^-ms-/
  var U = /-([a-z])/g

  function V (e, t) {
    return t.toUpperCase()
  }

  function G (e) {
    return e.replace(X, 'ms-').replace(U, V)
  }
  var Y = function (e) {
    return e.nodeType === 1 || e.nodeType === 9 || !+e.nodeType
  }

  function Q () {
    this.expando = w.expando + Q.uid++
  }
  Q.uid = 1, Q.prototype = {
    cache: function (e) {
      var t = e[this.expando]
      return t || (t = {}, Y(e) && (e.nodeType ? e[this.expando] = t : Object.defineProperty(e, this.expando, {
        value: t,
        configurable: !0
      }))), t
    },
    set: function (e, t, n) {
      var r
      var i = this.cache(e)
      if (typeof t === 'string') i[G(t)] = n
      else { for (r in t) i[G(r)] = t[r] }
      return i
    },
    get: function (e, t) {
      return void 0 === t ? this.cache(e) : e[this.expando] && e[this.expando][G(t)]
    },
    access: function (e, t, n) {
      return void 0 === t || t && typeof t === 'string' && void 0 === n ? this.get(e, t) : (this.set(e, t, n), void 0 !== n ? n : t)
    },
    remove: function (e, t) {
      var n
      var r = e[this.expando]
      if (void 0 !== r) {
        if (void 0 !== t) {
          n = (t = Array.isArray(t) ? t.map(G) : (t = G(t)) in r ? [t] : t.match(M) || []).length
          while (n--) delete r[t[n]]
        }(void 0 === t || w.isEmptyObject(r)) && (e.nodeType ? e[this.expando] = void 0 : delete e[this.expando])
      }
    },
    hasData: function (e) {
      var t = e[this.expando]
      return void 0 !== t && !w.isEmptyObject(t)
    }
  }
  var J = new Q()
  var K = new Q()
  var Z = /^(?:\{[\w\W]*\}|\[[\w\W]*\])$/
  var ee = /[A-Z]/g

  function te (e) {
    return e === 'true' || e !== 'false' && (e === 'null' ? null : e === +e + '' ? +e : Z.test(e) ? JSON.parse(e) : e)
  }

  function ne (e, t, n) {
    var r
    if (void 0 === n && e.nodeType === 1) {
      if (r = 'data-' + t.replace(ee, '-$&').toLowerCase(), typeof (n = e.getAttribute(r)) === 'string') {
        try {
          n = te(n)
        } catch (e) {}
        K.set(e, t, n)
      } else n = void 0
    }
    return n
  }
  w.extend({
    hasData: function (e) {
      return K.hasData(e) || J.hasData(e)
    },
    data: function (e, t, n) {
      return K.access(e, t, n)
    },
    removeData: function (e, t) {
      K.remove(e, t)
    },
    _data: function (e, t, n) {
      return J.access(e, t, n)
    },
    _removeData: function (e, t) {
      J.remove(e, t)
    }
  }), w.fn.extend({
    data: function (e, t) {
      var n
      var r
      var i
      var o = this[0]
      var a = o && o.attributes
      if (void 0 === e) {
        if (this.length && (i = K.get(o), o.nodeType === 1 && !J.get(o, 'hasDataAttrs'))) {
          n = a.length
          while (n--) a[n] && (r = a[n].name).indexOf('data-') === 0 && (r = G(r.slice(5)), ne(o, r, i[r]))
          J.set(o, 'hasDataAttrs', !0)
        }
        return i
      }
      return typeof e === 'object' ? this.each(function () {
        K.set(this, e)
      }) : z(this, function (t) {
        var n
        if (o && void 0 === t) {
          if (void 0 !== (n = K.get(o, e))) return n
          if (void 0 !== (n = ne(o, e))) return n
        } else {
          this.each(function () {
            K.set(this, e, t)
          })
        }
      }, null, t, arguments.length > 1, null, !0)
    },
    removeData: function (e) {
      return this.each(function () {
        K.remove(this, e)
      })
    }
  }), w.extend({
    queue: function (e, t, n) {
      var r
      if (e) return t = (t || 'fx') + 'queue', r = J.get(e, t), n && (!r || Array.isArray(n) ? r = J.access(e, t, w.makeArray(n)) : r.push(n)), r || []
    },
    dequeue: function (e, t) {
      t = t || 'fx'
      var n = w.queue(e, t)
      var r = n.length
      var i = n.shift()
      var o = w._queueHooks(e, t)
      var a = function () {
        w.dequeue(e, t)
      }
      i === 'inprogress' && (i = n.shift(), r--), i && (t === 'fx' && n.unshift('inprogress'), delete o.stop, i.call(e, a, o)), !r && o && o.empty.fire()
    },
    _queueHooks: function (e, t) {
      var n = t + 'queueHooks'
      return J.get(e, n) || J.access(e, n, {
        empty: w.Callbacks('once memory').add(function () {
          J.remove(e, [t + 'queue', n])
        })
      })
    }
  }), w.fn.extend({
    queue: function (e, t) {
      var n = 2
      return typeof e !== 'string' && (t = e, e = 'fx', n--), arguments.length < n ? w.queue(this[0], e) : void 0 === t ? this : this.each(function () {
        var n = w.queue(this, e, t)
        w._queueHooks(this, e), e === 'fx' && n[0] !== 'inprogress' && w.dequeue(this, e)
      })
    },
    dequeue: function (e) {
      return this.each(function () {
        w.dequeue(this, e)
      })
    },
    clearQueue: function (e) {
      return this.queue(e || 'fx', [])
    },
    promise: function (e, t) {
      var n
      var r = 1
      var i = w.Deferred()
      var o = this
      var a = this.length
      var s = function () {
        --r || i.resolveWith(o, [o])
      }
      typeof e !== 'string' && (t = e, e = void 0), e = e || 'fx'
      while (a--)(n = J.get(o[a], e + 'queueHooks')) && n.empty && (r++, n.empty.add(s))
      return s(), i.promise(t)
    }
  })
  var re = /[+-]?(?:\d*\.|)\d+(?:[eE][+-]?\d+|)/.source
  var ie = new RegExp('^(?:([+-])=|)(' + re + ')([a-z%]*)$', 'i')
  var oe = ['Top', 'Right', 'Bottom', 'Left']
  var ae = function (e, t) {
    return (e = t || e).style.display === 'none' || e.style.display === '' && w.contains(e.ownerDocument, e) && w.css(e, 'display') === 'none'
  }
  var se = function (e, t, n, r) {
    var i
    var o
    var a = {}
    for (o in t) a[o] = e.style[o], e.style[o] = t[o]
    i = n.apply(e, r || [])
    for (o in t) e.style[o] = a[o]
    return i
  }

  function ue (e, t, n, r) {
    var i
    var o
    var a = 20
    var s = r ? function () {
      return r.cur()
    } : function () {
      return w.css(e, t, '')
    }
    var u = s()
    var l = n && n[3] || (w.cssNumber[t] ? '' : 'px')
    var c = (w.cssNumber[t] || l !== 'px' && +u) && ie.exec(w.css(e, t))
    if (c && c[3] !== l) {
      u /= 2, l = l || c[3], c = +u || 1
      while (a--) w.style(e, t, c + l), (1 - o) * (1 - (o = s() / u || 0.5)) <= 0 && (a = 0), c /= o
      c *= 2, w.style(e, t, c + l), n = n || []
    }
    return n && (c = +c || +u || 0, i = n[1] ? c + (n[1] + 1) * n[2] : +n[2], r && (r.unit = l, r.start = c, r.end = i)), i
  }
  var le = {}

  function ce (e) {
    var t
    var n = e.ownerDocument
    var r = e.nodeName
    var i = le[r]
    return i || (t = n.body.appendChild(n.createElement(r)), i = w.css(t, 'display'), t.parentNode.removeChild(t), i === 'none' && (i = 'block'), le[r] = i, i)
  }

  function fe (e, t) {
    for (var n, r, i = [], o = 0, a = e.length; o < a; o++)(r = e[o]).style && (n = r.style.display, t ? (n === 'none' && (i[o] = J.get(r, 'display') || null, i[o] || (r.style.display = '')), r.style.display === '' && ae(r) && (i[o] = ce(r))) : n !== 'none' && (i[o] = 'none', J.set(r, 'display', n)))
    for (o = 0; o < a; o++) i[o] != null && (e[o].style.display = i[o])
    return e
  }
  w.fn.extend({
    show: function () {
      return fe(this, !0)
    },
    hide: function () {
      return fe(this)
    },
    toggle: function (e) {
      return typeof e === 'boolean' ? e ? this.show() : this.hide() : this.each(function () {
        ae(this) ? w(this).show() : w(this).hide()
      })
    }
  })
  var pe = /^(?:checkbox|radio)$/i
  var de = /<([a-z][^\/\0>\x20\t\r\n\f]+)/i
  var he = /^$|^module$|\/(?:java|ecma)script/i
  var ge = {
    option: [1, "<select multiple='multiple'>", '</select>'],
    thead: [1, '<table>', '</table>'],
    col: [2, '<table><colgroup>', '</colgroup></table>'],
    tr: [2, '<table><tbody>', '</tbody></table>'],
    td: [3, '<table><tbody><tr>', '</tr></tbody></table>'],
    _default: [0, '', '']
  }
  ge.optgroup = ge.option, ge.tbody = ge.tfoot = ge.colgroup = ge.caption = ge.thead, ge.th = ge.td

  function ye (e, t) {
    var n
    return n = typeof e.getElementsByTagName !== 'undefined' ? e.getElementsByTagName(t || '*') : typeof e.querySelectorAll !== 'undefined' ? e.querySelectorAll(t || '*') : [], void 0 === t || t && N(e, t) ? w.merge([e], n) : n
  }

  function ve (e, t) {
    for (var n = 0, r = e.length; n < r; n++) J.set(e[n], 'globalEval', !t || J.get(t[n], 'globalEval'))
  }
  var me = /<|&#?\w+;/

  function xe (e, t, n, r, i) {
    for (var o, a, s, u, l, c, f = t.createDocumentFragment(), p = [], d = 0, h = e.length; d < h; d++) {
      if ((o = e[d]) || o === 0) {
        if (x(o) === 'object') w.merge(p, o.nodeType ? [o] : o)
        else if (me.test(o)) {
          a = a || f.appendChild(t.createElement('div')), s = (de.exec(o) || ['', ''])[1].toLowerCase(), u = ge[s] || ge._default, a.innerHTML = u[1] + w.htmlPrefilter(o) + u[2], c = u[0]
          while (c--) a = a.lastChild
          w.merge(p, a.childNodes), (a = f.firstChild).textContent = ''
        } else p.push(t.createTextNode(o))
      }
    }
    f.textContent = '', d = 0
    while (o = p[d++]) {
      if (r && w.inArray(o, r) > -1) i && i.push(o)
      else if (l = w.contains(o.ownerDocument, o), a = ye(f.appendChild(o), 'script'), l && ve(a), n) {
        c = 0
        while (o = a[c++]) he.test(o.type || '') && n.push(o)
      }
    }
    return f
  }!(function () {
    var e = r.createDocumentFragment().appendChild(r.createElement('div'))
    var t = r.createElement('input')
    t.setAttribute('type', 'radio'), t.setAttribute('checked', 'checked'), t.setAttribute('name', 't'), e.appendChild(t), h.checkClone = e.cloneNode(!0).cloneNode(!0).lastChild.checked, e.innerHTML = '<textarea>x</textarea>', h.noCloneChecked = !!e.cloneNode(!0).lastChild.defaultValue
  }())
  var be = r.documentElement
  var we = /^key/
  var Te = /^(?:mouse|pointer|contextmenu|drag|drop)|click/
  var Ce = /^([^.]*)(?:\.(.+)|)/

  function Ee () {
    return !0
  }

  function ke () {
    return !1
  }

  function Se () {
    try {
      return r.activeElement
    } catch (e) {}
  }

  function De (e, t, n, r, i, o) {
    var a, s
    if (typeof t === 'object') {
      typeof n !== 'string' && (r = r || n, n = void 0)
      for (s in t) De(e, s, n, r, t[s], o)
      return e
    }
    if (r == null && i == null ? (i = n, r = n = void 0) : i == null && (typeof n === 'string' ? (i = r, r = void 0) : (i = r, r = n, n = void 0)), !1 === i) i = ke
    else if (!i) return e
    return o === 1 && (a = i, (i = function (e) {
      return w().off(e), a.apply(this, arguments)
    }).guid = a.guid || (a.guid = w.guid++)), e.each(function () {
      w.event.add(this, t, i, r, n)
    })
  }
  w.event = {
    global: {},
    add: function (e, t, n, r, i) {
      var o
      var a
      var s
      var u
      var l
      var c
      var f
      var p
      var d
      var h
      var g
      var y = J.get(e)
      if (y) {
        n.handler && (n = (o = n).handler, i = o.selector), i && w.find.matchesSelector(be, i), n.guid || (n.guid = w.guid++), (u = y.events) || (u = y.events = {}), (a = y.handle) || (a = y.handle = function (t) {
          return typeof w !== 'undefined' && w.event.triggered !== t.type ? w.event.dispatch.apply(e, arguments) : void 0
        }), l = (t = (t || '').match(M) || ['']).length
        while (l--) {
          d = g = (s = Ce.exec(t[l]) || [])[1], h = (s[2] || '').split('.').sort(), d && (f = w.event.special[d] || {}, d = (i ? f.delegateType : f.bindType) || d, f = w.event.special[d] || {}, c = w.extend({
            type: d,
            origType: g,
            data: r,
            handler: n,
            guid: n.guid,
            selector: i,
            needsContext: i && w.expr.match.needsContext.test(i),
            namespace: h.join('.')
          }, o), (p = u[d]) || ((p = u[d] = []).delegateCount = 0, f.setup && !1 !== f.setup.call(e, r, h, a) || e.addEventListener && e.addEventListener(d, a)), f.add && (f.add.call(e, c), c.handler.guid || (c.handler.guid = n.guid)), i ? p.splice(p.delegateCount++, 0, c) : p.push(c), w.event.global[d] = !0)
        }
      }
    },
    remove: function (e, t, n, r, i) {
      var o
      var a
      var s
      var u
      var l
      var c
      var f
      var p
      var d
      var h
      var g
      var y = J.hasData(e) && J.get(e)
      if (y && (u = y.events)) {
        l = (t = (t || '').match(M) || ['']).length
        while (l--) {
          if (s = Ce.exec(t[l]) || [], d = g = s[1], h = (s[2] || '').split('.').sort(), d) {
            f = w.event.special[d] || {}, p = u[d = (r ? f.delegateType : f.bindType) || d] || [], s = s[2] && new RegExp('(^|\\.)' + h.join('\\.(?:.*\\.|)') + '(\\.|$)'), a = o = p.length
            while (o--) c = p[o], !i && g !== c.origType || n && n.guid !== c.guid || s && !s.test(c.namespace) || r && r !== c.selector && (r !== '**' || !c.selector) || (p.splice(o, 1), c.selector && p.delegateCount--, f.remove && f.remove.call(e, c))
            a && !p.length && (f.teardown && !1 !== f.teardown.call(e, h, y.handle) || w.removeEvent(e, d, y.handle), delete u[d])
          } else { for (d in u) w.event.remove(e, d + t[l], n, r, !0) }
        }
        w.isEmptyObject(u) && J.remove(e, 'handle events')
      }
    },
    dispatch: function (e) {
      var t = w.event.fix(e)
      var n
      var r
      var i
      var o
      var a
      var s
      var u = new Array(arguments.length)
      var l = (J.get(this, 'events') || {})[t.type] || []
      var c = w.event.special[t.type] || {}
      for (u[0] = t, n = 1; n < arguments.length; n++) u[n] = arguments[n]
      if (t.delegateTarget = this, !c.preDispatch || !1 !== c.preDispatch.call(this, t)) {
        s = w.event.handlers.call(this, t, l), n = 0
        while ((o = s[n++]) && !t.isPropagationStopped()) {
          t.currentTarget = o.elem, r = 0
          while ((a = o.handlers[r++]) && !t.isImmediatePropagationStopped()) t.rnamespace && !t.rnamespace.test(a.namespace) || (t.handleObj = a, t.data = a.data, void 0 !== (i = ((w.event.special[a.origType] || {}).handle || a.handler).apply(o.elem, u)) && !1 === (t.result = i) && (t.preventDefault(), t.stopPropagation()))
        }
        return c.postDispatch && c.postDispatch.call(this, t), t.result
      }
    },
    handlers: function (e, t) {
      var n
      var r
      var i
      var o
      var a
      var s = []
      var u = t.delegateCount
      var l = e.target
      if (u && l.nodeType && !(e.type === 'click' && e.button >= 1)) {
        for (; l !== this; l = l.parentNode || this) {
          if (l.nodeType === 1 && (e.type !== 'click' || !0 !== l.disabled)) {
            for (o = [], a = {}, n = 0; n < u; n++) void 0 === a[i = (r = t[n]).selector + ' '] && (a[i] = r.needsContext ? w(i, this).index(l) > -1 : w.find(i, this, null, [l]).length), a[i] && o.push(r)
            o.length && s.push({
              elem: l,
              handlers: o
            })
          }
        }
      } return l = this, u < t.length && s.push({
        elem: l,
        handlers: t.slice(u)
      }), s
    },
    addProp: function (e, t) {
      Object.defineProperty(w.Event.prototype, e, {
        enumerable: !0,
        configurable: !0,
        get: g(t) ? function () {
          if (this.originalEvent) return t(this.originalEvent)
        } : function () {
          if (this.originalEvent) return this.originalEvent[e]
        },
        set: function (t) {
          Object.defineProperty(this, e, {
            enumerable: !0,
            configurable: !0,
            writable: !0,
            value: t
          })
        }
      })
    },
    fix: function (e) {
      return e[w.expando] ? e : new w.Event(e)
    },
    special: {
      load: {
        noBubble: !0
      },
      focus: {
        trigger: function () {
          if (this !== Se() && this.focus) return this.focus(), !1
        },
        delegateType: 'focusin'
      },
      blur: {
        trigger: function () {
          if (this === Se() && this.blur) return this.blur(), !1
        },
        delegateType: 'focusout'
      },
      click: {
        trigger: function () {
          if (this.type === 'checkbox' && this.click && N(this, 'input')) return this.click(), !1
        },
        _default: function (e) {
          return N(e.target, 'a')
        }
      },
      beforeunload: {
        postDispatch: function (e) {
          void 0 !== e.result && e.originalEvent && (e.originalEvent.returnValue = e.result)
        }
      }
    }
  }, w.removeEvent = function (e, t, n) {
    e.removeEventListener && e.removeEventListener(t, n)
  }, w.Event = function (e, t) {
    if (!(this instanceof w.Event)) return new w.Event(e, t)
    e && e.type ? (this.originalEvent = e, this.type = e.type, this.isDefaultPrevented = e.defaultPrevented || void 0 === e.defaultPrevented && !1 === e.returnValue ? Ee : ke, this.target = e.target && e.target.nodeType === 3 ? e.target.parentNode : e.target, this.currentTarget = e.currentTarget, this.relatedTarget = e.relatedTarget) : this.type = e, t && w.extend(this, t), this.timeStamp = e && e.timeStamp || Date.now(), this[w.expando] = !0
  }, w.Event.prototype = {
    constructor: w.Event,
    isDefaultPrevented: ke,
    isPropagationStopped: ke,
    isImmediatePropagationStopped: ke,
    isSimulated: !1,
    preventDefault: function () {
      var e = this.originalEvent
      this.isDefaultPrevented = Ee, e && !this.isSimulated && e.preventDefault()
    },
    stopPropagation: function () {
      var e = this.originalEvent
      this.isPropagationStopped = Ee, e && !this.isSimulated && e.stopPropagation()
    },
    stopImmediatePropagation: function () {
      var e = this.originalEvent
      this.isImmediatePropagationStopped = Ee, e && !this.isSimulated && e.stopImmediatePropagation(), this.stopPropagation()
    }
  }, w.each({
    altKey: !0,
    bubbles: !0,
    cancelable: !0,
    changedTouches: !0,
    ctrlKey: !0,
    detail: !0,
    eventPhase: !0,
    metaKey: !0,
    pageX: !0,
    pageY: !0,
    shiftKey: !0,
    view: !0,
    'char': !0,
    charCode: !0,
    key: !0,
    keyCode: !0,
    button: !0,
    buttons: !0,
    clientX: !0,
    clientY: !0,
    offsetX: !0,
    offsetY: !0,
    pointerId: !0,
    pointerType: !0,
    screenX: !0,
    screenY: !0,
    targetTouches: !0,
    toElement: !0,
    touches: !0,
    which: function (e) {
      var t = e.button
      return e.which == null && we.test(e.type) ? e.charCode != null ? e.charCode : e.keyCode : !e.which && void 0 !== t && Te.test(e.type) ? 1 & t ? 1 : 2 & t ? 3 : 4 & t ? 2 : 0 : e.which
    }
  }, w.event.addProp), w.each({
    mouseenter: 'mouseover',
    mouseleave: 'mouseout',
    pointerenter: 'pointerover',
    pointerleave: 'pointerout'
  }, function (e, t) {
    w.event.special[e] = {
      delegateType: t,
      bindType: t,
      handle: function (e) {
        var n
        var r = this
        var i = e.relatedTarget
        var o = e.handleObj
        return i && (i === r || w.contains(r, i)) || (e.type = o.origType, n = o.handler.apply(this, arguments), e.type = t), n
      }
    }
  }), w.fn.extend({
    on: function (e, t, n, r) {
      return De(this, e, t, n, r)
    },
    one: function (e, t, n, r) {
      return De(this, e, t, n, r, 1)
    },
    off: function (e, t, n) {
      var r, i
      if (e && e.preventDefault && e.handleObj) return r = e.handleObj, w(e.delegateTarget).off(r.namespace ? r.origType + '.' + r.namespace : r.origType, r.selector, r.handler), this
      if (typeof e === 'object') {
        for (i in e) this.off(i, t, e[i])
        return this
      }
      return !1 !== t && typeof t !== 'function' || (n = t, t = void 0), !1 === n && (n = ke), this.each(function () {
        w.event.remove(this, e, n, t)
      })
    }
  })
  var Ne = /<(?!area|br|col|embed|hr|img|input|link|meta|param)(([a-z][^\/\0>\x20\t\r\n\f]*)[^>]*)\/>/gi
  var Ae = /<script|<style|<link/i
  var je = /checked\s*(?:[^=]|=\s*.checked.)/i
  var qe = /^\s*<!(?:\[CDATA\[|--)|(?:\]\]|--)>\s*$/g

  function Le (e, t) {
    return N(e, 'table') && N(t.nodeType !== 11 ? t : t.firstChild, 'tr') ? w(e).children('tbody')[0] || e : e
  }

  function He (e) {
    return e.type = (e.getAttribute('type') !== null) + '/' + e.type, e
  }

  function Oe (e) {
    return (e.type || '').slice(0, 5) === 'true/' ? e.type = e.type.slice(5) : e.removeAttribute('type'), e
  }

  function Pe (e, t) {
    var n, r, i, o, a, s, u, l
    if (t.nodeType === 1) {
      if (J.hasData(e) && (o = J.access(e), a = J.set(t, o), l = o.events)) {
        delete a.handle, a.events = {}
        for (i in l) { for (n = 0, r = l[i].length; n < r; n++) w.event.add(t, i, l[i][n]) }
      }
      K.hasData(e) && (s = K.access(e), u = w.extend({}, s), K.set(t, u))
    }
  }

  function Me (e, t) {
    var n = t.nodeName.toLowerCase()
    n === 'input' && pe.test(e.type) ? t.checked = e.checked : n !== 'input' && n !== 'textarea' || (t.defaultValue = e.defaultValue)
  }

  function Re (e, t, n, r) {
    t = a.apply([], t)
    var i
    var o
    var s
    var u
    var l
    var c
    var f = 0
    var p = e.length
    var d = p - 1
    var y = t[0]
    var v = g(y)
    if (v || p > 1 && typeof y === 'string' && !h.checkClone && je.test(y)) {
      return e.each(function (i) {
        var o = e.eq(i)
        v && (t[0] = y.call(this, i, o.html())), Re(o, t, n, r)
      })
    }
    if (p && (i = xe(t, e[0].ownerDocument, !1, e, r), o = i.firstChild, i.childNodes.length === 1 && (i = o), o || r)) {
      for (u = (s = w.map(ye(i, 'script'), He)).length; f < p; f++) l = i, f !== d && (l = w.clone(l, !0, !0), u && w.merge(s, ye(l, 'script'))), n.call(e[f], l, f)
      if (u) { for (c = s[s.length - 1].ownerDocument, w.map(s, Oe), f = 0; f < u; f++) l = s[f], he.test(l.type || '') && !J.access(l, 'globalEval') && w.contains(c, l) && (l.src && (l.type || '').toLowerCase() !== 'module' ? w._evalUrl && w._evalUrl(l.src) : m(l.textContent.replace(qe, ''), c, l)) }
    }
    return e
  }

  function Ie (e, t, n) {
    for (var r, i = t ? w.filter(t, e) : e, o = 0;
      (r = i[o]) != null; o++) n || r.nodeType !== 1 || w.cleanData(ye(r)), r.parentNode && (n && w.contains(r.ownerDocument, r) && ve(ye(r, 'script')), r.parentNode.removeChild(r))
    return e
  }
  w.extend({
    htmlPrefilter: function (e) {
      return e.replace(Ne, '<$1></$2>')
    },
    clone: function (e, t, n) {
      var r
      var i
      var o
      var a
      var s = e.cloneNode(!0)
      var u = w.contains(e.ownerDocument, e)
      if (!(h.noCloneChecked || e.nodeType !== 1 && e.nodeType !== 11 || w.isXMLDoc(e))) { for (a = ye(s), r = 0, i = (o = ye(e)).length; r < i; r++) Me(o[r], a[r]) }
      if (t) {
        if (n) { for (o = o || ye(e), a = a || ye(s), r = 0, i = o.length; r < i; r++) Pe(o[r], a[r]) } else Pe(e, s)
      }
      return (a = ye(s, 'script')).length > 0 && ve(a, !u && ye(e, 'script')), s
    },
    cleanData: function (e) {
      for (var t, n, r, i = w.event.special, o = 0; void 0 !== (n = e[o]); o++) {
        if (Y(n)) {
          if (t = n[J.expando]) {
            if (t.events) { for (r in t.events) i[r] ? w.event.remove(n, r) : w.removeEvent(n, r, t.handle) }
            n[J.expando] = void 0
          }
          n[K.expando] && (n[K.expando] = void 0)
        }
      }
    }
  }), w.fn.extend({
    detach: function (e) {
      return Ie(this, e, !0)
    },
    remove: function (e) {
      return Ie(this, e)
    },
    text: function (e) {
      return z(this, function (e) {
        return void 0 === e ? w.text(this) : this.empty().each(function () {
          this.nodeType !== 1 && this.nodeType !== 11 && this.nodeType !== 9 || (this.textContent = e)
        })
      }, null, e, arguments.length)
    },
    append: function () {
      return Re(this, arguments, function (e) {
        this.nodeType !== 1 && this.nodeType !== 11 && this.nodeType !== 9 || Le(this, e).appendChild(e)
      })
    },
    prepend: function () {
      return Re(this, arguments, function (e) {
        if (this.nodeType === 1 || this.nodeType === 11 || this.nodeType === 9) {
          var t = Le(this, e)
          t.insertBefore(e, t.firstChild)
        }
      })
    },
    before: function () {
      return Re(this, arguments, function (e) {
        this.parentNode && this.parentNode.insertBefore(e, this)
      })
    },
    after: function () {
      return Re(this, arguments, function (e) {
        this.parentNode && this.parentNode.insertBefore(e, this.nextSibling)
      })
    },
    empty: function () {
      for (var e, t = 0;
        (e = this[t]) != null; t++) e.nodeType === 1 && (w.cleanData(ye(e, !1)), e.textContent = '')
      return this
    },
    clone: function (e, t) {
      return e = e != null && e, t = t == null ? e : t, this.map(function () {
        return w.clone(this, e, t)
      })
    },
    html: function (e) {
      return z(this, function (e) {
        var t = this[0] || {}
        var n = 0
        var r = this.length
        if (void 0 === e && t.nodeType === 1) return t.innerHTML
        if (typeof e === 'string' && !Ae.test(e) && !ge[(de.exec(e) || ['', ''])[1].toLowerCase()]) {
          e = w.htmlPrefilter(e)
          try {
            for (; n < r; n++)(t = this[n] || {}).nodeType === 1 && (w.cleanData(ye(t, !1)), t.innerHTML = e)
            t = 0
          } catch (e) {}
        }
        t && this.empty().append(e)
      }, null, e, arguments.length)
    },
    replaceWith: function () {
      var e = []
      return Re(this, arguments, function (t) {
        var n = this.parentNode
        w.inArray(this, e) < 0 && (w.cleanData(ye(this)), n && n.replaceChild(t, this))
      }, e)
    }
  }), w.each({
    appendTo: 'append',
    prependTo: 'prepend',
    insertBefore: 'before',
    insertAfter: 'after',
    replaceAll: 'replaceWith'
  }, function (e, t) {
    w.fn[e] = function (e) {
      for (var n, r = [], i = w(e), o = i.length - 1, a = 0; a <= o; a++) n = a === o ? this : this.clone(!0), w(i[a])[t](n), s.apply(r, n.get())
      return this.pushStack(r)
    }
  })
  var We = new RegExp('^(' + re + ')(?!px)[a-z%]+$', 'i')
  var $e = function (t) {
    var n = t.ownerDocument.defaultView
    return n && n.opener || (n = e), n.getComputedStyle(t)
  }
  var Be = new RegExp(oe.join('|'), 'i')
  !(function () {
    function t () {
      if (c) {
        l.style.cssText = 'position:absolute;left:-11111px;width:60px;margin-top:1px;padding:0;border:0', c.style.cssText = 'position:relative;display:block;box-sizing:border-box;overflow:scroll;margin:auto;border:1px;padding:1px;width:60%;top:1%', be.appendChild(l).appendChild(c)
        var t = e.getComputedStyle(c)
        i = t.top !== '1%', u = n(t.marginLeft) === 12, c.style.right = '60%', s = n(t.right) === 36, o = n(t.width) === 36, c.style.position = 'absolute', a = c.offsetWidth === 36 || 'absolute', be.removeChild(l), c = null
      }
    }

    function n (e) {
      return Math.round(parseFloat(e))
    }
    var i
    var o
    var a
    var s
    var u
    var l = r.createElement('div')
    var c = r.createElement('div')
    c.style && (c.style.backgroundClip = 'content-box', c.cloneNode(!0).style.backgroundClip = '', h.clearCloneStyle = c.style.backgroundClip === 'content-box', w.extend(h, {
      boxSizingReliable: function () {
        return t(), o
      },
      pixelBoxStyles: function () {
        return t(), s
      },
      pixelPosition: function () {
        return t(), i
      },
      reliableMarginLeft: function () {
        return t(), u
      },
      scrollboxSize: function () {
        return t(), a
      }
    }))
  }())

  function Fe (e, t, n) {
    var r
    var i
    var o
    var a
    var s = e.style
    return (n = n || $e(e)) && ((a = n.getPropertyValue(t) || n[t]) !== '' || w.contains(e.ownerDocument, e) || (a = w.style(e, t)), !h.pixelBoxStyles() && We.test(a) && Be.test(t) && (r = s.width, i = s.minWidth, o = s.maxWidth, s.minWidth = s.maxWidth = s.width = a, a = n.width, s.width = r, s.minWidth = i, s.maxWidth = o)), void 0 !== a ? a + '' : a
  }

  function _e (e, t) {
    return {
      get: function () {
        if (!e()) return (this.get = t).apply(this, arguments)
        delete this.get
      }
    }
  }
  var ze = /^(none|table(?!-c[ea]).+)/
  var Xe = /^--/
  var Ue = {
    position: 'absolute',
    visibility: 'hidden',
    display: 'block'
  }
  var Ve = {
    letterSpacing: '0',
    fontWeight: '400'
  }
  var Ge = ['Webkit', 'Moz', 'ms']
  var Ye = r.createElement('div').style

  function Qe (e) {
    if (e in Ye) return e
    var t = e[0].toUpperCase() + e.slice(1)
    var n = Ge.length
    while (n--) { if ((e = Ge[n] + t) in Ye) return e }
  }

  function Je (e) {
    var t = w.cssProps[e]
    return t || (t = w.cssProps[e] = Qe(e) || e), t
  }

  function Ke (e, t, n) {
    var r = ie.exec(t)
    return r ? Math.max(0, r[2] - (n || 0)) + (r[3] || 'px') : t
  }

  function Ze (e, t, n, r, i, o) {
    var a = t === 'width' ? 1 : 0
    var s = 0
    var u = 0
    if (n === (r ? 'border' : 'content')) return 0
    for (; a < 4; a += 2) n === 'margin' && (u += w.css(e, n + oe[a], !0, i)), r ? (n === 'content' && (u -= w.css(e, 'padding' + oe[a], !0, i)), n !== 'margin' && (u -= w.css(e, 'border' + oe[a] + 'Width', !0, i))) : (u += w.css(e, 'padding' + oe[a], !0, i), n !== 'padding' ? u += w.css(e, 'border' + oe[a] + 'Width', !0, i) : s += w.css(e, 'border' + oe[a] + 'Width', !0, i))
    return !r && o >= 0 && (u += Math.max(0, Math.ceil(e['offset' + t[0].toUpperCase() + t.slice(1)] - o - u - s - 0.5))), u
  }

  function et (e, t, n) {
    var r = $e(e)
    var i = Fe(e, t, r)
    var o = w.css(e, 'boxSizing', !1, r) === 'border-box'
    var a = o
    if (We.test(i)) {
      if (!n) return i
      i = 'auto'
    }
    return a = a && (h.boxSizingReliable() || i === e.style[t]), (i === 'auto' || !parseFloat(i) && w.css(e, 'display', !1, r) === 'inline') && (i = e['offset' + t[0].toUpperCase() + t.slice(1)], a = !0), (i = parseFloat(i) || 0) + Ze(e, t, n || (o ? 'border' : 'content'), a, r, i) + 'px'
  }
  w.extend({
    cssHooks: {
      opacity: {
        get: function (e, t) {
          if (t) {
            var n = Fe(e, 'opacity')
            return n === '' ? '1' : n
          }
        }
      }
    },
    cssNumber: {
      animationIterationCount: !0,
      columnCount: !0,
      fillOpacity: !0,
      flexGrow: !0,
      flexShrink: !0,
      fontWeight: !0,
      lineHeight: !0,
      opacity: !0,
      order: !0,
      orphans: !0,
      widows: !0,
      zIndex: !0,
      zoom: !0
    },
    cssProps: {},
    style: function (e, t, n, r) {
      if (e && e.nodeType !== 3 && e.nodeType !== 8 && e.style) {
        var i
        var o
        var a
        var s = G(t)
        var u = Xe.test(t)
        var l = e.style
        if (u || (t = Je(s)), a = w.cssHooks[t] || w.cssHooks[s], void 0 === n) return a && 'get' in a && void 0 !== (i = a.get(e, !1, r)) ? i : l[t];
        (o = typeof n) == 'string' && (i = ie.exec(n)) && i[1] && (n = ue(e, t, i), o = 'number'), n != null && n === n && (o === 'number' && (n += i && i[3] || (w.cssNumber[s] ? '' : 'px')), h.clearCloneStyle || n !== '' || t.indexOf('background') !== 0 || (l[t] = 'inherit'), a && 'set' in a && void 0 === (n = a.set(e, n, r)) || (u ? l.setProperty(t, n) : l[t] = n))
      }
    },
    css: function (e, t, n, r) {
      var i
      var o
      var a
      var s = G(t)
      return Xe.test(t) || (t = Je(s)), (a = w.cssHooks[t] || w.cssHooks[s]) && 'get' in a && (i = a.get(e, !0, n)), void 0 === i && (i = Fe(e, t, r)), i === 'normal' && t in Ve && (i = Ve[t]), n === '' || n ? (o = parseFloat(i), !0 === n || isFinite(o) ? o || 0 : i) : i
    }
  }), w.each(['height', 'width'], function (e, t) {
    w.cssHooks[t] = {
      get: function (e, n, r) {
        if (n) {
          return !ze.test(w.css(e, 'display')) || e.getClientRects().length && e.getBoundingClientRect().width ? et(e, t, r) : se(e, Ue, function () {
            return et(e, t, r)
          })
        }
      },
      set: function (e, n, r) {
        var i
        var o = $e(e)
        var a = w.css(e, 'boxSizing', !1, o) === 'border-box'
        var s = r && Ze(e, t, r, a, o)
        return a && h.scrollboxSize() === o.position && (s -= Math.ceil(e['offset' + t[0].toUpperCase() + t.slice(1)] - parseFloat(o[t]) - Ze(e, t, 'border', !1, o) - 0.5)), s && (i = ie.exec(n)) && (i[3] || 'px') !== 'px' && (e.style[t] = n, n = w.css(e, t)), Ke(e, n, s)
      }
    }
  }), w.cssHooks.marginLeft = _e(h.reliableMarginLeft, function (e, t) {
    if (t) {
      return (parseFloat(Fe(e, 'marginLeft')) || e.getBoundingClientRect().left - se(e, {
        marginLeft: 0
      }, function () {
        return e.getBoundingClientRect().left
      })) + 'px'
    }
  }), w.each({
    margin: '',
    padding: '',
    border: 'Width'
  }, function (e, t) {
    w.cssHooks[e + t] = {
      expand: function (n) {
        for (var r = 0, i = {}, o = typeof n === 'string' ? n.split(' ') : [n]; r < 4; r++) i[e + oe[r] + t] = o[r] || o[r - 2] || o[0]
        return i
      }
    }, e !== 'margin' && (w.cssHooks[e + t].set = Ke)
  }), w.fn.extend({
    css: function (e, t) {
      return z(this, function (e, t, n) {
        var r
        var i
        var o = {}
        var a = 0
        if (Array.isArray(t)) {
          for (r = $e(e), i = t.length; a < i; a++) o[t[a]] = w.css(e, t[a], !1, r)
          return o
        }
        return void 0 !== n ? w.style(e, t, n) : w.css(e, t)
      }, e, t, arguments.length > 1)
    }
  })

  function tt (e, t, n, r, i) {
    return new tt.prototype.init(e, t, n, r, i)
  }
  w.Tween = tt, tt.prototype = {
    constructor: tt,
    init: function (e, t, n, r, i, o) {
      this.elem = e, this.prop = n, this.easing = i || w.easing._default, this.options = t, this.start = this.now = this.cur(), this.end = r, this.unit = o || (w.cssNumber[n] ? '' : 'px')
    },
    cur: function () {
      var e = tt.propHooks[this.prop]
      return e && e.get ? e.get(this) : tt.propHooks._default.get(this)
    },
    run: function (e) {
      var t
      var n = tt.propHooks[this.prop]
      return this.options.duration ? this.pos = t = w.easing[this.easing](e, this.options.duration * e, 0, 1, this.options.duration) : this.pos = t = e, this.now = (this.end - this.start) * t + this.start, this.options.step && this.options.step.call(this.elem, this.now, this), n && n.set ? n.set(this) : tt.propHooks._default.set(this), this
    }
  }, tt.prototype.init.prototype = tt.prototype, tt.propHooks = {
    _default: {
      get: function (e) {
        var t
        return e.elem.nodeType !== 1 || e.elem[e.prop] != null && e.elem.style[e.prop] == null ? e.elem[e.prop] : (t = w.css(e.elem, e.prop, '')) && t !== 'auto' ? t : 0
      },
      set: function (e) {
        w.fx.step[e.prop] ? w.fx.step[e.prop](e) : e.elem.nodeType !== 1 || e.elem.style[w.cssProps[e.prop]] == null && !w.cssHooks[e.prop] ? e.elem[e.prop] = e.now : w.style(e.elem, e.prop, e.now + e.unit)
      }
    }
  }, tt.propHooks.scrollTop = tt.propHooks.scrollLeft = {
    set: function (e) {
      e.elem.nodeType && e.elem.parentNode && (e.elem[e.prop] = e.now)
    }
  }, w.easing = {
    linear: function (e) {
      return e
    },
    swing: function (e) {
      return 0.5 - Math.cos(e * Math.PI) / 2
    },
    _default: 'swing'
  }, w.fx = tt.prototype.init, w.fx.step = {}
  var nt
  var rt
  var it = /^(?:toggle|show|hide)$/
  var ot = /queueHooks$/

  function at () {
    rt && (!1 === r.hidden && e.requestAnimationFrame ? e.requestAnimationFrame(at) : e.setTimeout(at, w.fx.interval), w.fx.tick())
  }

  function st () {
    return e.setTimeout(function () {
      nt = void 0
    }), nt = Date.now()
  }

  function ut (e, t) {
    var n
    var r = 0
    var i = {
      height: e
    }
    for (t = t ? 1 : 0; r < 4; r += 2 - t) i['margin' + (n = oe[r])] = i['padding' + n] = e
    return t && (i.opacity = i.width = e), i
  }

  function lt (e, t, n) {
    for (var r, i = (pt.tweeners[t] || []).concat(pt.tweeners['*']), o = 0, a = i.length; o < a; o++) { if (r = i[o].call(n, t, e)) return r }
  }

  function ct (e, t, n) {
    var r
    var i
    var o
    var a
    var s
    var u
    var l
    var c
    var f = 'width' in t || 'height' in t
    var p = this
    var d = {}
    var h = e.style
    var g = e.nodeType && ae(e)
    var y = J.get(e, 'fxshow')
    n.queue || ((a = w._queueHooks(e, 'fx')).unqueued == null && (a.unqueued = 0, s = a.empty.fire, a.empty.fire = function () {
      a.unqueued || s()
    }), a.unqueued++, p.always(function () {
      p.always(function () {
        a.unqueued--, w.queue(e, 'fx').length || a.empty.fire()
      })
    }))
    for (r in t) {
      if (i = t[r], it.test(i)) {
        if (delete t[r], o = o || i === 'toggle', i === (g ? 'hide' : 'show')) {
          if (i !== 'show' || !y || void 0 === y[r]) continue
          g = !0
        }
        d[r] = y && y[r] || w.style(e, r)
      }
    } if ((u = !w.isEmptyObject(t)) || !w.isEmptyObject(d)) {
      f && e.nodeType === 1 && (n.overflow = [h.overflow, h.overflowX, h.overflowY], (l = y && y.display) == null && (l = J.get(e, 'display')), (c = w.css(e, 'display')) === 'none' && (l ? c = l : (fe([e], !0), l = e.style.display || l, c = w.css(e, 'display'), fe([e]))), (c === 'inline' || c === 'inline-block' && l != null) && w.css(e, 'float') === 'none' && (u || (p.done(function () {
        h.display = l
      }), l == null && (c = h.display, l = c === 'none' ? '' : c)), h.display = 'inline-block')), n.overflow && (h.overflow = 'hidden', p.always(function () {
        h.overflow = n.overflow[0], h.overflowX = n.overflow[1], h.overflowY = n.overflow[2]
      })), u = !1
      for (r in d) {
        u || (y ? 'hidden' in y && (g = y.hidden) : y = J.access(e, 'fxshow', {
          display: l
        }), o && (y.hidden = !g), g && fe([e], !0), p.done(function () {
          g || fe([e]), J.remove(e, 'fxshow')
          for (r in d) w.style(e, r, d[r])
        })), u = lt(g ? y[r] : 0, r, p), r in y || (y[r] = u.start, g && (u.end = u.start, u.start = 0))
      }
    }
  }

  function ft (e, t) {
    var n, r, i, o, a
    for (n in e) {
      if (r = G(n), i = t[r], o = e[n], Array.isArray(o) && (i = o[1], o = e[n] = o[0]), n !== r && (e[r] = o, delete e[n]), (a = w.cssHooks[r]) && 'expand' in a) {
        o = a.expand(o), delete e[r]
        for (n in o) n in e || (e[n] = o[n], t[n] = i)
      } else t[r] = i
    }
  }

  function pt (e, t, n) {
    var r
    var i
    var o = 0
    var a = pt.prefilters.length
    var s = w.Deferred().always(function () {
      delete u.elem
    })
    var u = function () {
      if (i) return !1
      for (var t = nt || st(), n = Math.max(0, l.startTime + l.duration - t), r = 1 - (n / l.duration || 0), o = 0, a = l.tweens.length; o < a; o++) l.tweens[o].run(r)
      return s.notifyWith(e, [l, r, n]), r < 1 && a ? n : (a || s.notifyWith(e, [l, 1, 0]), s.resolveWith(e, [l]), !1)
    }
    var l = s.promise({
      elem: e,
      props: w.extend({}, t),
      opts: w.extend(!0, {
        specialEasing: {},
        easing: w.easing._default
      }, n),
      originalProperties: t,
      originalOptions: n,
      startTime: nt || st(),
      duration: n.duration,
      tweens: [],
      createTween: function (t, n) {
        var r = w.Tween(e, l.opts, t, n, l.opts.specialEasing[t] || l.opts.easing)
        return l.tweens.push(r), r
      },
      stop: function (t) {
        var n = 0
        var r = t ? l.tweens.length : 0
        if (i) return this
        for (i = !0; n < r; n++) l.tweens[n].run(1)
        return t ? (s.notifyWith(e, [l, 1, 0]), s.resolveWith(e, [l, t])) : s.rejectWith(e, [l, t]), this
      }
    })
    var c = l.props
    for (ft(c, l.opts.specialEasing); o < a; o++) { if (r = pt.prefilters[o].call(l, e, c, l.opts)) return g(r.stop) && (w._queueHooks(l.elem, l.opts.queue).stop = r.stop.bind(r)), r }
    return w.map(c, lt, l), g(l.opts.start) && l.opts.start.call(e, l), l.progress(l.opts.progress).done(l.opts.done, l.opts.complete).fail(l.opts.fail).always(l.opts.always), w.fx.timer(w.extend(u, {
      elem: e,
      anim: l,
      queue: l.opts.queue
    })), l
  }
  w.Animation = w.extend(pt, {
    tweeners: {
      '*': [function (e, t) {
        var n = this.createTween(e, t)
        return ue(n.elem, e, ie.exec(t), n), n
      }]
    },
    tweener: function (e, t) {
      g(e) ? (t = e, e = ['*']) : e = e.match(M)
      for (var n, r = 0, i = e.length; r < i; r++) n = e[r], pt.tweeners[n] = pt.tweeners[n] || [], pt.tweeners[n].unshift(t)
    },
    prefilters: [ct],
    prefilter: function (e, t) {
      t ? pt.prefilters.unshift(e) : pt.prefilters.push(e)
    }
  }), w.speed = function (e, t, n) {
    var r = e && typeof e === 'object' ? w.extend({}, e) : {
      complete: n || !n && t || g(e) && e,
      duration: e,
      easing: n && t || t && !g(t) && t
    }
    return w.fx.off ? r.duration = 0 : typeof r.duration !== 'number' && (r.duration in w.fx.speeds ? r.duration = w.fx.speeds[r.duration] : r.duration = w.fx.speeds._default), r.queue != null && !0 !== r.queue || (r.queue = 'fx'), r.old = r.complete, r.complete = function () {
      g(r.old) && r.old.call(this), r.queue && w.dequeue(this, r.queue)
    }, r
  }, w.fn.extend({
    fadeTo: function (e, t, n, r) {
      return this.filter(ae).css('opacity', 0).show().end().animate({
        opacity: t
      }, e, n, r)
    },
    animate: function (e, t, n, r) {
      var i = w.isEmptyObject(e)
      var o = w.speed(t, n, r)
      var a = function () {
        var t = pt(this, w.extend({}, e), o);
        (i || J.get(this, 'finish')) && t.stop(!0)
      }
      return a.finish = a, i || !1 === o.queue ? this.each(a) : this.queue(o.queue, a)
    },
    stop: function (e, t, n) {
      var r = function (e) {
        var t = e.stop
        delete e.stop, t(n)
      }
      return typeof e !== 'string' && (n = t, t = e, e = void 0), t && !1 !== e && this.queue(e || 'fx', []), this.each(function () {
        var t = !0
        var i = e != null && e + 'queueHooks'
        var o = w.timers
        var a = J.get(this)
        if (i) a[i] && a[i].stop && r(a[i])
        else { for (i in a) a[i] && a[i].stop && ot.test(i) && r(a[i]) }
        for (i = o.length; i--;) o[i].elem !== this || e != null && o[i].queue !== e || (o[i].anim.stop(n), t = !1, o.splice(i, 1))
        !t && n || w.dequeue(this, e)
      })
    },
    finish: function (e) {
      return !1 !== e && (e = e || 'fx'), this.each(function () {
        var t
        var n = J.get(this)
        var r = n[e + 'queue']
        var i = n[e + 'queueHooks']
        var o = w.timers
        var a = r ? r.length : 0
        for (n.finish = !0, w.queue(this, e, []), i && i.stop && i.stop.call(this, !0), t = o.length; t--;) o[t].elem === this && o[t].queue === e && (o[t].anim.stop(!0), o.splice(t, 1))
        for (t = 0; t < a; t++) r[t] && r[t].finish && r[t].finish.call(this)
        delete n.finish
      })
    }
  }), w.each(['toggle', 'show', 'hide'], function (e, t) {
    var n = w.fn[t]
    w.fn[t] = function (e, r, i) {
      return e == null || typeof e === 'boolean' ? n.apply(this, arguments) : this.animate(ut(t, !0), e, r, i)
    }
  }), w.each({
    slideDown: ut('show'),
    slideUp: ut('hide'),
    slideToggle: ut('toggle'),
    fadeIn: {
      opacity: 'show'
    },
    fadeOut: {
      opacity: 'hide'
    },
    fadeToggle: {
      opacity: 'toggle'
    }
  }, function (e, t) {
    w.fn[e] = function (e, n, r) {
      return this.animate(t, e, n, r)
    }
  }), w.timers = [], w.fx.tick = function () {
    var e
    var t = 0
    var n = w.timers
    for (nt = Date.now(); t < n.length; t++)(e = n[t])() || n[t] !== e || n.splice(t--, 1)
    n.length || w.fx.stop(), nt = void 0
  }, w.fx.timer = function (e) {
    w.timers.push(e), w.fx.start()
  }, w.fx.interval = 13, w.fx.start = function () {
    rt || (rt = !0, at())
  }, w.fx.stop = function () {
    rt = null
  }, w.fx.speeds = {
    slow: 600,
    fast: 200,
    _default: 400
  }, w.fn.delay = function (t, n) {
    return t = w.fx ? w.fx.speeds[t] || t : t, n = n || 'fx', this.queue(n, function (n, r) {
      var i = e.setTimeout(n, t)
      r.stop = function () {
        e.clearTimeout(i)
      }
    })
  }, (function () {
    var e = r.createElement('input')
    var t = r.createElement('select').appendChild(r.createElement('option'))
    e.type = 'checkbox', h.checkOn = e.value !== '', h.optSelected = t.selected, (e = r.createElement('input')).value = 't', e.type = 'radio', h.radioValue = e.value === 't'
  }())
  var dt
  var ht = w.expr.attrHandle
  w.fn.extend({
    attr: function (e, t) {
      return z(this, w.attr, e, t, arguments.length > 1)
    },
    removeAttr: function (e) {
      return this.each(function () {
        w.removeAttr(this, e)
      })
    }
  }), w.extend({
    attr: function (e, t, n) {
      var r
      var i
      var o = e.nodeType
      if (o !== 3 && o !== 8 && o !== 2) return typeof e.getAttribute === 'undefined' ? w.prop(e, t, n) : (o === 1 && w.isXMLDoc(e) || (i = w.attrHooks[t.toLowerCase()] || (w.expr.match.bool.test(t) ? dt : void 0)), void 0 !== n ? n === null ? void w.removeAttr(e, t) : i && 'set' in i && void 0 !== (r = i.set(e, n, t)) ? r : (e.setAttribute(t, n + ''), n) : i && 'get' in i && (r = i.get(e, t)) !== null ? r : (r = w.find.attr(e, t)) == null ? void 0 : r)
    },
    attrHooks: {
      type: {
        set: function (e, t) {
          if (!h.radioValue && t === 'radio' && N(e, 'input')) {
            var n = e.value
            return e.setAttribute('type', t), n && (e.value = n), t
          }
        }
      }
    },
    removeAttr: function (e, t) {
      var n
      var r = 0
      var i = t && t.match(M)
      if (i && e.nodeType === 1) { while (n = i[r++]) e.removeAttribute(n) }
    }
  }), dt = {
    set: function (e, t, n) {
      return !1 === t ? w.removeAttr(e, n) : e.setAttribute(n, n), n
    }
  }, w.each(w.expr.match.bool.source.match(/\w+/g), function (e, t) {
    var n = ht[t] || w.find.attr
    ht[t] = function (e, t, r) {
      var i
      var o
      var a = t.toLowerCase()
      return r || (o = ht[a], ht[a] = i, i = n(e, t, r) != null ? a : null, ht[a] = o), i
    }
  })
  var gt = /^(?:input|select|textarea|button)$/i
  var yt = /^(?:a|area)$/i
  w.fn.extend({
    prop: function (e, t) {
      return z(this, w.prop, e, t, arguments.length > 1)
    },
    removeProp: function (e) {
      return this.each(function () {
        delete this[w.propFix[e] || e]
      })
    }
  }), w.extend({
    prop: function (e, t, n) {
      var r
      var i
      var o = e.nodeType
      if (o !== 3 && o !== 8 && o !== 2) return o === 1 && w.isXMLDoc(e) || (t = w.propFix[t] || t, i = w.propHooks[t]), void 0 !== n ? i && 'set' in i && void 0 !== (r = i.set(e, n, t)) ? r : e[t] = n : i && 'get' in i && (r = i.get(e, t)) !== null ? r : e[t]
    },
    propHooks: {
      tabIndex: {
        get: function (e) {
          var t = w.find.attr(e, 'tabindex')
          return t ? parseInt(t, 10) : gt.test(e.nodeName) || yt.test(e.nodeName) && e.href ? 0 : -1
        }
      }
    },
    propFix: {
      'for': 'htmlFor',
      'class': 'className'
    }
  }), h.optSelected || (w.propHooks.selected = {
    get: function (e) {
      var t = e.parentNode
      return t && t.parentNode && t.parentNode.selectedIndex, null
    },
    set: function (e) {
      var t = e.parentNode
      t && (t.selectedIndex, t.parentNode && t.parentNode.selectedIndex)
    }
  }), w.each(['tabIndex', 'readOnly', 'maxLength', 'cellSpacing', 'cellPadding', 'rowSpan', 'colSpan', 'useMap', 'frameBorder', 'contentEditable'], function () {
    w.propFix[this.toLowerCase()] = this
  })

  function vt (e) {
    return (e.match(M) || []).join(' ')
  }

  function mt (e) {
    return e.getAttribute && e.getAttribute('class') || ''
  }

  function xt (e) {
    return Array.isArray(e) ? e : typeof e === 'string' ? e.match(M) || [] : []
  }
  w.fn.extend({
    addClass: function (e) {
      var t
      var n
      var r
      var i
      var o
      var a
      var s
      var u = 0
      if (g(e)) {
        return this.each(function (t) {
          w(this).addClass(e.call(this, t, mt(this)))
        })
      }
      if ((t = xt(e)).length) {
        while (n = this[u++]) {
          if (i = mt(n), r = n.nodeType === 1 && ' ' + vt(i) + ' ') {
            a = 0
            while (o = t[a++]) r.indexOf(' ' + o + ' ') < 0 && (r += o + ' ')
            i !== (s = vt(r)) && n.setAttribute('class', s)
          }
        }
      } return this
    },
    removeClass: function (e) {
      var t
      var n
      var r
      var i
      var o
      var a
      var s
      var u = 0
      if (g(e)) {
        return this.each(function (t) {
          w(this).removeClass(e.call(this, t, mt(this)))
        })
      }
      if (!arguments.length) return this.attr('class', '')
      if ((t = xt(e)).length) {
        while (n = this[u++]) {
          if (i = mt(n), r = n.nodeType === 1 && ' ' + vt(i) + ' ') {
            a = 0
            while (o = t[a++]) { while (r.indexOf(' ' + o + ' ') > -1) r = r.replace(' ' + o + ' ', ' ') }
            i !== (s = vt(r)) && n.setAttribute('class', s)
          }
        }
      } return this
    },
    toggleClass: function (e, t) {
      var n = typeof e
      var r = n === 'string' || Array.isArray(e)
      return typeof t === 'boolean' && r ? t ? this.addClass(e) : this.removeClass(e) : g(e) ? this.each(function (n) {
        w(this).toggleClass(e.call(this, n, mt(this), t), t)
      }) : this.each(function () {
        var t, i, o, a
        if (r) {
          i = 0, o = w(this), a = xt(e)
          while (t = a[i++]) o.hasClass(t) ? o.removeClass(t) : o.addClass(t)
        } else void 0 !== e && n !== 'boolean' || ((t = mt(this)) && J.set(this, '__className__', t), this.setAttribute && this.setAttribute('class', t || !1 === e ? '' : J.get(this, '__className__') || ''))
      })
    },
    hasClass: function (e) {
      var t
      var n
      var r = 0
      t = ' ' + e + ' '
      while (n = this[r++]) { if (n.nodeType === 1 && (' ' + vt(mt(n)) + ' ').indexOf(t) > -1) return !0 }
      return !1
    }
  })
  var bt = /\r/g
  w.fn.extend({
    val: function (e) {
      var t
      var n
      var r
      var i = this[0]; {
        if (arguments.length) {
          return r = g(e), this.each(function (n) {
            var i
            this.nodeType === 1 && ((i = r ? e.call(this, n, w(this).val()) : e) == null ? i = '' : typeof i === 'number' ? i += '' : Array.isArray(i) && (i = w.map(i, function (e) {
              return e == null ? '' : e + ''
            })), (t = w.valHooks[this.type] || w.valHooks[this.nodeName.toLowerCase()]) && 'set' in t && void 0 !== t.set(this, i, 'value') || (this.value = i))
          })
        }
        if (i) return (t = w.valHooks[i.type] || w.valHooks[i.nodeName.toLowerCase()]) && 'get' in t && void 0 !== (n = t.get(i, 'value')) ? n : typeof (n = i.value) === 'string' ? n.replace(bt, '') : n == null ? '' : n
      }
    }
  }), w.extend({
    valHooks: {
      option: {
        get: function (e) {
          var t = w.find.attr(e, 'value')
          return t != null ? t : vt(w.text(e))
        }
      },
      select: {
        get: function (e) {
          var t
          var n
          var r
          var i = e.options
          var o = e.selectedIndex
          var a = e.type === 'select-one'
          var s = a ? null : []
          var u = a ? o + 1 : i.length
          for (r = o < 0 ? u : a ? o : 0; r < u; r++) {
            if (((n = i[r]).selected || r === o) && !n.disabled && (!n.parentNode.disabled || !N(n.parentNode, 'optgroup'))) {
              if (t = w(n).val(), a) return t
              s.push(t)
            }
          } return s
        },
        set: function (e, t) {
          var n
          var r
          var i = e.options
          var o = w.makeArray(t)
          var a = i.length
          while (a--)((r = i[a]).selected = w.inArray(w.valHooks.option.get(r), o) > -1) && (n = !0)
          return n || (e.selectedIndex = -1), o
        }
      }
    }
  }), w.each(['radio', 'checkbox'], function () {
    w.valHooks[this] = {
      set: function (e, t) {
        if (Array.isArray(t)) return e.checked = w.inArray(w(e).val(), t) > -1
      }
    }, h.checkOn || (w.valHooks[this].get = function (e) {
      return e.getAttribute('value') === null ? 'on' : e.value
    })
  }), h.focusin = 'onfocusin' in e
  var wt = /^(?:focusinfocus|focusoutblur)$/
  var Tt = function (e) {
    e.stopPropagation()
  }
  w.extend(w.event, {
    trigger: function (t, n, i, o) {
      var a
      var s
      var u
      var l
      var c
      var p
      var d
      var h
      var v = [i || r]
      var m = f.call(t, 'type') ? t.type : t
      var x = f.call(t, 'namespace') ? t.namespace.split('.') : []
      if (s = h = u = i = i || r, i.nodeType !== 3 && i.nodeType !== 8 && !wt.test(m + w.event.triggered) && (m.indexOf('.') > -1 && (m = (x = m.split('.')).shift(), x.sort()), c = m.indexOf(':') < 0 && 'on' + m, t = t[w.expando] ? t : new w.Event(m, typeof t === 'object' && t), t.isTrigger = o ? 2 : 3, t.namespace = x.join('.'), t.rnamespace = t.namespace ? new RegExp('(^|\\.)' + x.join('\\.(?:.*\\.|)') + '(\\.|$)') : null, t.result = void 0, t.target || (t.target = i), n = n == null ? [t] : w.makeArray(n, [t]), d = w.event.special[m] || {}, o || !d.trigger || !1 !== d.trigger.apply(i, n))) {
        if (!o && !d.noBubble && !y(i)) {
          for (l = d.delegateType || m, wt.test(l + m) || (s = s.parentNode); s; s = s.parentNode) v.push(s), u = s
          u === (i.ownerDocument || r) && v.push(u.defaultView || u.parentWindow || e)
        }
        a = 0
        while ((s = v[a++]) && !t.isPropagationStopped()) h = s, t.type = a > 1 ? l : d.bindType || m, (p = (J.get(s, 'events') || {})[t.type] && J.get(s, 'handle')) && p.apply(s, n), (p = c && s[c]) && p.apply && Y(s) && (t.result = p.apply(s, n), !1 === t.result && t.preventDefault())
        return t.type = m, o || t.isDefaultPrevented() || d._default && !1 !== d._default.apply(v.pop(), n) || !Y(i) || c && g(i[m]) && !y(i) && ((u = i[c]) && (i[c] = null), w.event.triggered = m, t.isPropagationStopped() && h.addEventListener(m, Tt), i[m](), t.isPropagationStopped() && h.removeEventListener(m, Tt), w.event.triggered = void 0, u && (i[c] = u)), t.result
      }
    },
    simulate: function (e, t, n) {
      var r = w.extend(new w.Event(), n, {
        type: e,
        isSimulated: !0
      })
      w.event.trigger(r, null, t)
    }
  }), w.fn.extend({
    trigger: function (e, t) {
      return this.each(function () {
        w.event.trigger(e, t, this)
      })
    },
    triggerHandler: function (e, t) {
      var n = this[0]
      if (n) return w.event.trigger(e, t, n, !0)
    }
  }), h.focusin || w.each({
    focus: 'focusin',
    blur: 'focusout'
  }, function (e, t) {
    var n = function (e) {
      w.event.simulate(t, e.target, w.event.fix(e))
    }
    w.event.special[t] = {
      setup: function () {
        var r = this.ownerDocument || this
        var i = J.access(r, t)
        i || r.addEventListener(e, n, !0), J.access(r, t, (i || 0) + 1)
      },
      teardown: function () {
        var r = this.ownerDocument || this
        var i = J.access(r, t) - 1
        i ? J.access(r, t, i) : (r.removeEventListener(e, n, !0), J.remove(r, t))
      }
    }
  })
  var Ct = e.location
  var Et = Date.now()
  var kt = /\?/
  w.parseXML = function (t) {
    var n
    if (!t || typeof t !== 'string') return null
    try {
      n = (new e.DOMParser()).parseFromString(t, 'text/xml')
    } catch (e) {
      n = void 0
    }
    return n && !n.getElementsByTagName('parsererror').length || w.error('Invalid XML: ' + t), n
  }
  var St = /\[\]$/
  var Dt = /\r?\n/g
  var Nt = /^(?:submit|button|image|reset|file)$/i
  var At = /^(?:input|select|textarea|keygen)/i

  function jt (e, t, n, r) {
    var i
    if (Array.isArray(t)) {
      w.each(t, function (t, i) {
        n || St.test(e) ? r(e, i) : jt(e + '[' + (typeof i === 'object' && i != null ? t : '') + ']', i, n, r)
      })
    } else if (n || x(t) !== 'object') r(e, t)
    else { for (i in t) jt(e + '[' + i + ']', t[i], n, r) }
  }
  w.param = function (e, t) {
    var n
    var r = []
    var i = function (e, t) {
      var n = g(t) ? t() : t
      r[r.length] = encodeURIComponent(e) + '=' + encodeURIComponent(n == null ? '' : n)
    }
    if (Array.isArray(e) || e.jquery && !w.isPlainObject(e)) {
      w.each(e, function () {
        i(this.name, this.value)
      })
    } else { for (n in e) jt(n, e[n], t, i) }
    return r.join('&')
  }, w.fn.extend({
    serialize: function () {
      return w.param(this.serializeArray())
    },
    serializeArray: function () {
      return this.map(function () {
        var e = w.prop(this, 'elements')
        return e ? w.makeArray(e) : this
      }).filter(function () {
        var e = this.type
        return this.name && !w(this).is(':disabled') && At.test(this.nodeName) && !Nt.test(e) && (this.checked || !pe.test(e))
      }).map(function (e, t) {
        var n = w(this).val()
        return n == null ? null : Array.isArray(n) ? w.map(n, function (e) {
          return {
            name: t.name,
            value: e.replace(Dt, '\r\n')
          }
        }) : {
          name: t.name,
          value: n.replace(Dt, '\r\n')
        }
      }).get()
    }
  })
  var qt = /%20/g
  var Lt = /#.*$/
  var Ht = /([?&])_=[^&]*/
  var Ot = /^(.*?):[ \t]*([^\r\n]*)$/gm
  var Pt = /^(?:about|app|app-storage|.+-extension|file|res|widget):$/
  var Mt = /^(?:GET|HEAD)$/
  var Rt = /^\/\//
  var It = {}
  var Wt = {}
  var $t = '*/'.concat('*')
  var Bt = r.createElement('a')
  Bt.href = Ct.href

  function Ft (e) {
    return function (t, n) {
      typeof t !== 'string' && (n = t, t = '*')
      var r
      var i = 0
      var o = t.toLowerCase().match(M) || []
      if (g(n)) { while (r = o[i++]) r[0] === '+' ? (r = r.slice(1) || '*', (e[r] = e[r] || []).unshift(n)) : (e[r] = e[r] || []).push(n) }
    }
  }

  function _t (e, t, n, r) {
    var i = {}
    var o = e === Wt

    function a (s) {
      var u
      return i[s] = !0, w.each(e[s] || [], function (e, s) {
        var l = s(t, n, r)
        return typeof l !== 'string' || o || i[l] ? o ? !(u = l) : void 0 : (t.dataTypes.unshift(l), a(l), !1)
      }), u
    }
    return a(t.dataTypes[0]) || !i['*'] && a('*')
  }

  function zt (e, t) {
    var n
    var r
    var i = w.ajaxSettings.flatOptions || {}
    for (n in t) void 0 !== t[n] && ((i[n] ? e : r || (r = {}))[n] = t[n])
    return r && w.extend(!0, e, r), e
  }

  function Xt (e, t, n) {
    var r
    var i
    var o
    var a
    var s = e.contents
    var u = e.dataTypes
    while (u[0] === '*') u.shift(), void 0 === r && (r = e.mimeType || t.getResponseHeader('Content-Type'))
    if (r) {
      for (i in s) {
        if (s[i] && s[i].test(r)) {
          u.unshift(i)
          break
        }
      }
    } if (u[0] in n) o = u[0]
    else {
      for (i in n) {
        if (!u[0] || e.converters[i + ' ' + u[0]]) {
          o = i
          break
        }
        a || (a = i)
      }
      o = o || a
    }
    if (o) return o !== u[0] && u.unshift(o), n[o]
  }

  function Ut (e, t, n, r) {
    var i
    var o
    var a
    var s
    var u
    var l = {}
    var c = e.dataTypes.slice()
    if (c[1]) { for (a in e.converters) l[a.toLowerCase()] = e.converters[a] }
    o = c.shift()
    while (o) {
      if (e.responseFields[o] && (n[e.responseFields[o]] = t), !u && r && e.dataFilter && (t = e.dataFilter(t, e.dataType)), u = o, o = c.shift()) {
        if (o === '*') o = u
        else if (u !== '*' && u !== o) {
          if (!(a = l[u + ' ' + o] || l['* ' + o])) {
            for (i in l) {
 if ((s = i.split(' '))[1] === o && (a = l[u + ' ' + s[0]] || l['* ' + s[0]])) {
              !0 === a ? a = l[i] : !0 !== l[i] && (o = s[0], c.unshift(s[1]))
              break
            }
}
          } if (!0 !== a) {
            if (a && e['throws']) t = a(t)
            else {
              try {
                t = a(t)
              } catch (e) {
                return {
                  state: 'parsererror',
                  error: a ? e : 'No conversion from ' + u + ' to ' + o
                }
              }
            }
          }
        }
      }
    }
    return {
      state: 'success',
      data: t
    }
  }
  w.extend({
    active: 0,
    lastModified: {},
    etag: {},
    ajaxSettings: {
      url: Ct.href,
      type: 'GET',
      isLocal: Pt.test(Ct.protocol),
      global: !0,
      processData: !0,
      async: !0,
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      accepts: {
        '*': $t,
        text: 'text/plain',
        html: 'text/html',
        xml: 'application/xml, text/xml',
        json: 'application/json, text/javascript'
      },
      contents: {
        xml: /\bxml\b/,
        html: /\bhtml/,
        json: /\bjson\b/
      },
      responseFields: {
        xml: 'responseXML',
        text: 'responseText',
        json: 'responseJSON'
      },
      converters: {
        '* text': String,
        'text html': !0,
        'text json': JSON.parse,
        'text xml': w.parseXML
      },
      flatOptions: {
        url: !0,
        context: !0
      }
    },
    ajaxSetup: function (e, t) {
      return t ? zt(zt(e, w.ajaxSettings), t) : zt(w.ajaxSettings, e)
    },
    ajaxPrefilter: Ft(It),
    ajaxTransport: Ft(Wt),
    ajax: function (t, n) {
      typeof t === 'object' && (n = t, t = void 0), n = n || {}
      var i
      var o
      var a
      var s
      var u
      var l
      var c
      var f
      var p
      var d
      var h = w.ajaxSetup({}, n)
      var g = h.context || h
      var y = h.context && (g.nodeType || g.jquery) ? w(g) : w.event
      var v = w.Deferred()
      var m = w.Callbacks('once memory')
      var x = h.statusCode || {}
      var b = {}
      var T = {}
      var C = 'canceled'
      var E = {
        readyState: 0,
        getResponseHeader: function (e) {
          var t
          if (c) {
            if (!s) {
              s = {}
              while (t = Ot.exec(a)) s[t[1].toLowerCase()] = t[2]
            }
            t = s[e.toLowerCase()]
          }
          return t == null ? null : t
        },
        getAllResponseHeaders: function () {
          return c ? a : null
        },
        setRequestHeader: function (e, t) {
          return c == null && (e = T[e.toLowerCase()] = T[e.toLowerCase()] || e, b[e] = t), this
        },
        overrideMimeType: function (e) {
          return c == null && (h.mimeType = e), this
        },
        statusCode: function (e) {
          var t
          if (e) {
            if (c) E.always(e[E.status])
            else { for (t in e) x[t] = [x[t], e[t]] }
          }
          return this
        },
        abort: function (e) {
          var t = e || C
          return i && i.abort(t), k(0, t), this
        }
      }
      if (v.promise(E), h.url = ((t || h.url || Ct.href) + '').replace(Rt, Ct.protocol + '//'), h.type = n.method || n.type || h.method || h.type, h.dataTypes = (h.dataType || '*').toLowerCase().match(M) || [''], h.crossDomain == null) {
        l = r.createElement('a')
        try {
          l.href = h.url, l.href = l.href, h.crossDomain = Bt.protocol + '//' + Bt.host != l.protocol + '//' + l.host
        } catch (e) {
          h.crossDomain = !0
        }
      }
      if (h.data && h.processData && typeof h.data !== 'string' && (h.data = w.param(h.data, h.traditional)), _t(It, h, n, E), c) return E;
      (f = w.event && h.global) && w.active++ == 0 && w.event.trigger('ajaxStart'), h.type = h.type.toUpperCase(), h.hasContent = !Mt.test(h.type), o = h.url.replace(Lt, ''), h.hasContent ? h.data && h.processData && (h.contentType || '').indexOf('application/x-www-form-urlencoded') === 0 && (h.data = h.data.replace(qt, '+')) : (d = h.url.slice(o.length), h.data && (h.processData || typeof h.data === 'string') && (o += (kt.test(o) ? '&' : '?') + h.data, delete h.data), !1 === h.cache && (o = o.replace(Ht, '$1'), d = (kt.test(o) ? '&' : '?') + '_=' + Et++ + d), h.url = o + d), h.ifModified && (w.lastModified[o] && E.setRequestHeader('If-Modified-Since', w.lastModified[o]), w.etag[o] && E.setRequestHeader('If-None-Match', w.etag[o])), (h.data && h.hasContent && !1 !== h.contentType || n.contentType) && E.setRequestHeader('Content-Type', h.contentType), E.setRequestHeader('Accept', h.dataTypes[0] && h.accepts[h.dataTypes[0]] ? h.accepts[h.dataTypes[0]] + (h.dataTypes[0] !== '*' ? ', ' + $t + '; q=0.01' : '') : h.accepts['*'])
      for (p in h.headers) E.setRequestHeader(p, h.headers[p])
      if (h.beforeSend && (!1 === h.beforeSend.call(g, E, h) || c)) return E.abort()
      if (C = 'abort', m.add(h.complete), E.done(h.success), E.fail(h.error), i = _t(Wt, h, n, E)) {
        if (E.readyState = 1, f && y.trigger('ajaxSend', [E, h]), c) return E
        h.async && h.timeout > 0 && (u = e.setTimeout(function () {
          E.abort('timeout')
        }, h.timeout))
        try {
          c = !1, i.send(b, k)
        } catch (e) {
          if (c) throw e
          k(-1, e)
        }
      } else k(-1, 'No Transport')

      function k (t, n, r, s) {
        var l
        var p
        var d
        var b
        var T
        var C = n
        c || (c = !0, u && e.clearTimeout(u), i = void 0, a = s || '', E.readyState = t > 0 ? 4 : 0, l = t >= 200 && t < 300 || t === 304, r && (b = Xt(h, E, r)), b = Ut(h, b, E, l), l ? (h.ifModified && ((T = E.getResponseHeader('Last-Modified')) && (w.lastModified[o] = T), (T = E.getResponseHeader('etag')) && (w.etag[o] = T)), t === 204 || h.type === 'HEAD' ? C = 'nocontent' : t === 304 ? C = 'notmodified' : (C = b.state, p = b.data, l = !(d = b.error))) : (d = C, !t && C || (C = 'error', t < 0 && (t = 0))), E.status = t, E.statusText = (n || C) + '', l ? v.resolveWith(g, [p, C, E]) : v.rejectWith(g, [E, C, d]), E.statusCode(x), x = void 0, f && y.trigger(l ? 'ajaxSuccess' : 'ajaxError', [E, h, l ? p : d]), m.fireWith(g, [E, C]), f && (y.trigger('ajaxComplete', [E, h]), --w.active || w.event.trigger('ajaxStop')))
      }
      return E
    },
    getJSON: function (e, t, n) {
      return w.get(e, t, n, 'json')
    },
    getScript: function (e, t) {
      return w.get(e, void 0, t, 'script')
    }
  }), w.each(['get', 'post'], function (e, t) {
    w[t] = function (e, n, r, i) {
      return g(n) && (i = i || r, r = n, n = void 0), w.ajax(w.extend({
        url: e,
        type: t,
        dataType: i,
        data: n,
        success: r
      }, w.isPlainObject(e) && e))
    }
  }), w._evalUrl = function (e) {
    return w.ajax({
      url: e,
      type: 'GET',
      dataType: 'script',
      cache: !0,
      async: !1,
      global: !1,
      'throws': !0
    })
  }, w.fn.extend({
    wrapAll: function (e) {
      var t
      return this[0] && (g(e) && (e = e.call(this[0])), t = w(e, this[0].ownerDocument).eq(0).clone(!0), this[0].parentNode && t.insertBefore(this[0]), t.map(function () {
        var e = this
        while (e.firstElementChild) e = e.firstElementChild
        return e
      }).append(this)), this
    },
    wrapInner: function (e) {
      return g(e) ? this.each(function (t) {
        w(this).wrapInner(e.call(this, t))
      }) : this.each(function () {
        var t = w(this)
        var n = t.contents()
        n.length ? n.wrapAll(e) : t.append(e)
      })
    },
    wrap: function (e) {
      var t = g(e)
      return this.each(function (n) {
        w(this).wrapAll(t ? e.call(this, n) : e)
      })
    },
    unwrap: function (e) {
      return this.parent(e).not('body').each(function () {
        w(this).replaceWith(this.childNodes)
      }), this
    }
  }), w.expr.pseudos.hidden = function (e) {
    return !w.expr.pseudos.visible(e)
  }, w.expr.pseudos.visible = function (e) {
    return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length)
  }, w.ajaxSettings.xhr = function () {
    try {
      return new e.XMLHttpRequest()
    } catch (e) {}
  }
  var Vt = {
    0: 200,
    1223: 204
  }
  var Gt = w.ajaxSettings.xhr()
  h.cors = !!Gt && 'withCredentials' in Gt, h.ajax = Gt = !!Gt, w.ajaxTransport(function (t) {
    var n, r
    if (h.cors || Gt && !t.crossDomain) {
      return {
        send: function (i, o) {
          var a
          var s = t.xhr()
          if (s.open(t.type, t.url, t.async, t.username, t.password), t.xhrFields) { for (a in t.xhrFields) s[a] = t.xhrFields[a] }
          t.mimeType && s.overrideMimeType && s.overrideMimeType(t.mimeType), t.crossDomain || i['X-Requested-With'] || (i['X-Requested-With'] = 'XMLHttpRequest')
          for (a in i) s.setRequestHeader(a, i[a])
          n = function (e) {
            return function () {
              n && (n = r = s.onload = s.onerror = s.onabort = s.ontimeout = s.onreadystatechange = null, e === 'abort' ? s.abort() : e === 'error' ? typeof s.status !== 'number' ? o(0, 'error') : o(s.status, s.statusText) : o(Vt[s.status] || s.status, s.statusText, (s.responseType || 'text') !== 'text' || typeof s.responseText !== 'string' ? {
                binary: s.response
              } : {
                text: s.responseText
              }, s.getAllResponseHeaders()))
            }
          }, s.onload = n(), r = s.onerror = s.ontimeout = n('error'), void 0 !== s.onabort ? s.onabort = r : s.onreadystatechange = function () {
            s.readyState === 4 && e.setTimeout(function () {
              n && r()
            })
          }, n = n('abort')
          try {
            s.send(t.hasContent && t.data || null)
          } catch (e) {
            if (n) throw e
          }
        },
        abort: function () {
          n && n()
        }
      }
    }
  }), w.ajaxPrefilter(function (e) {
    e.crossDomain && (e.contents.script = !1)
  }), w.ajaxSetup({
    accepts: {
      script: 'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript'
    },
    contents: {
      script: /\b(?:java|ecma)script\b/
    },
    converters: {
      'text script': function (e) {
        return w.globalEval(e), e
      }
    }
  }), w.ajaxPrefilter('script', function (e) {
    void 0 === e.cache && (e.cache = !1), e.crossDomain && (e.type = 'GET')
  }), w.ajaxTransport('script', function (e) {
    if (e.crossDomain) {
      var t, n
      return {
        send: function (i, o) {
          t = w('<script>').prop({
            charset: e.scriptCharset,
            src: e.url
          }).on('load error', n = function (e) {
            t.remove(), n = null, e && o(e.type === 'error' ? 404 : 200, e.type)
          }), r.head.appendChild(t[0])
        },
        abort: function () {
          n && n()
        }
      }
    }
  })
  var Yt = []
  var Qt = /(=)\?(?=&|$)|\?\?/
  w.ajaxSetup({
    jsonp: 'callback',
    jsonpCallback: function () {
      var e = Yt.pop() || w.expando + '_' + Et++
      return this[e] = !0, e
    }
  }), w.ajaxPrefilter('json jsonp', function (t, n, r) {
    var i
    var o
    var a
    var s = !1 !== t.jsonp && (Qt.test(t.url) ? 'url' : typeof t.data === 'string' && (t.contentType || '').indexOf('application/x-www-form-urlencoded') === 0 && Qt.test(t.data) && 'data')
    if (s || t.dataTypes[0] === 'jsonp') {
      return i = t.jsonpCallback = g(t.jsonpCallback) ? t.jsonpCallback() : t.jsonpCallback, s ? t[s] = t[s].replace(Qt, '$1' + i) : !1 !== t.jsonp && (t.url += (kt.test(t.url) ? '&' : '?') + t.jsonp + '=' + i), t.converters['script json'] = function () {
        return a || w.error(i + ' was not called'), a[0]
      }, t.dataTypes[0] = 'json', o = e[i], e[i] = function () {
        a = arguments
      }, r.always(function () {
        void 0 === o ? w(e).removeProp(i) : e[i] = o, t[i] && (t.jsonpCallback = n.jsonpCallback, Yt.push(i)), a && g(o) && o(a[0]), a = o = void 0
      }), 'script'
    }
  }), h.createHTMLDocument = (function () {
    var e = r.implementation.createHTMLDocument('').body
    return e.innerHTML = '<form></form><form></form>', e.childNodes.length === 2
  }()), w.parseHTML = function (e, t, n) {
    if (typeof e !== 'string') return []
    typeof t === 'boolean' && (n = t, t = !1)
    var i, o, a
    return t || (h.createHTMLDocument ? ((i = (t = r.implementation.createHTMLDocument('')).createElement('base')).href = r.location.href, t.head.appendChild(i)) : t = r), o = A.exec(e), a = !n && [], o ? [t.createElement(o[1])] : (o = xe([e], t, a), a && a.length && w(a).remove(), w.merge([], o.childNodes))
  }, w.fn.load = function (e, t, n) {
    var r
    var i
    var o
    var a = this
    var s = e.indexOf(' ')
    return s > -1 && (r = vt(e.slice(s)), e = e.slice(0, s)), g(t) ? (n = t, t = void 0) : t && typeof t === 'object' && (i = 'POST'), a.length > 0 && w.ajax({
      url: e,
      type: i || 'GET',
      dataType: 'html',
      data: t
    }).done(function (e) {
      o = arguments, a.html(r ? w('<div>').append(w.parseHTML(e)).find(r) : e)
    }).always(n && function (e, t) {
      a.each(function () {
        n.apply(this, o || [e.responseText, t, e])
      })
    }), this
  }, w.each(['ajaxStart', 'ajaxStop', 'ajaxComplete', 'ajaxError', 'ajaxSuccess', 'ajaxSend'], function (e, t) {
    w.fn[t] = function (e) {
      return this.on(t, e)
    }
  }), w.expr.pseudos.animated = function (e) {
    return w.grep(w.timers, function (t) {
      return e === t.elem
    }).length
  }, w.offset = {
    setOffset: function (e, t, n) {
      var r
      var i
      var o
      var a
      var s
      var u
      var l
      var c = w.css(e, 'position')
      var f = w(e)
      var p = {}
      c === 'static' && (e.style.position = 'relative'), s = f.offset(), o = w.css(e, 'top'), u = w.css(e, 'left'), (l = (c === 'absolute' || c === 'fixed') && (o + u).indexOf('auto') > -1) ? (a = (r = f.position()).top, i = r.left) : (a = parseFloat(o) || 0, i = parseFloat(u) || 0), g(t) && (t = t.call(e, n, w.extend({}, s))), t.top != null && (p.top = t.top - s.top + a), t.left != null && (p.left = t.left - s.left + i), 'using' in t ? t.using.call(e, p) : f.css(p)
    }
  }, w.fn.extend({
    offset: function (e) {
      if (arguments.length) {
        return void 0 === e ? this : this.each(function (t) {
          w.offset.setOffset(this, e, t)
        })
      }
      var t
      var n
      var r = this[0]
      if (r) {
        return r.getClientRects().length ? (t = r.getBoundingClientRect(), n = r.ownerDocument.defaultView, {
          top: t.top + n.pageYOffset,
          left: t.left + n.pageXOffset
        }) : {
          top: 0,
          left: 0
        }
      }
    },
    position: function () {
      if (this[0]) {
        var e
        var t
        var n
        var r = this[0]
        var i = {
          top: 0,
          left: 0
        }
        if (w.css(r, 'position') === 'fixed') t = r.getBoundingClientRect()
        else {
          t = this.offset(), n = r.ownerDocument, e = r.offsetParent || n.documentElement
          while (e && (e === n.body || e === n.documentElement) && w.css(e, 'position') === 'static') e = e.parentNode
          e && e !== r && e.nodeType === 1 && ((i = w(e).offset()).top += w.css(e, 'borderTopWidth', !0), i.left += w.css(e, 'borderLeftWidth', !0))
        }
        return {
          top: t.top - i.top - w.css(r, 'marginTop', !0),
          left: t.left - i.left - w.css(r, 'marginLeft', !0)
        }
      }
    },
    offsetParent: function () {
      return this.map(function () {
        var e = this.offsetParent
        while (e && w.css(e, 'position') === 'static') e = e.offsetParent
        return e || be
      })
    }
  }), w.each({
    scrollLeft: 'pageXOffset',
    scrollTop: 'pageYOffset'
  }, function (e, t) {
    var n = t === 'pageYOffset'
    w.fn[e] = function (r) {
      return z(this, function (e, r, i) {
        var o
        if (y(e) ? o = e : e.nodeType === 9 && (o = e.defaultView), void 0 === i) return o ? o[t] : e[r]
        o ? o.scrollTo(n ? o.pageXOffset : i, n ? i : o.pageYOffset) : e[r] = i
      }, e, r, arguments.length)
    }
  }), w.each(['top', 'left'], function (e, t) {
    w.cssHooks[t] = _e(h.pixelPosition, function (e, n) {
      if (n) return n = Fe(e, t), We.test(n) ? w(e).position()[t] + 'px' : n
    })
  }), w.each({
    Height: 'height',
    Width: 'width'
  }, function (e, t) {
    w.each({
      padding: 'inner' + e,
      content: t,
      '': 'outer' + e
    }, function (n, r) {
      w.fn[r] = function (i, o) {
        var a = arguments.length && (n || typeof i !== 'boolean')
        var s = n || (!0 === i || !0 === o ? 'margin' : 'border')
        return z(this, function (t, n, i) {
          var o
          return y(t) ? r.indexOf('outer') === 0 ? t['inner' + e] : t.document.documentElement['client' + e] : t.nodeType === 9 ? (o = t.documentElement, Math.max(t.body['scroll' + e], o['scroll' + e], t.body['offset' + e], o['offset' + e], o['client' + e])) : void 0 === i ? w.css(t, n, s) : w.style(t, n, i, s)
        }, t, a ? i : void 0, a)
      }
    })
  }), w.each('blur focus focusin focusout resize scroll click dblclick mousedown mouseup mousemove mouseover mouseout mouseenter mouseleave change select submit keydown keypress keyup contextmenu'.split(' '), function (e, t) {
    w.fn[t] = function (e, n) {
      return arguments.length > 0 ? this.on(t, null, e, n) : this.trigger(t)
    }
  }), w.fn.extend({
    hover: function (e, t) {
      return this.mouseenter(e).mouseleave(t || e)
    }
  }), w.fn.extend({
    bind: function (e, t, n) {
      return this.on(e, null, t, n)
    },
    unbind: function (e, t) {
      return this.off(e, null, t)
    },
    delegate: function (e, t, n, r) {
      return this.on(t, e, n, r)
    },
    undelegate: function (e, t, n) {
      return arguments.length === 1 ? this.off(e, '**') : this.off(t, e || '**', n)
    }
  }), w.proxy = function (e, t) {
    var n, r, i
    if (typeof t === 'string' && (n = e[t], t = e, e = n), g(e)) {
      return r = o.call(arguments, 2), i = function () {
        return e.apply(t || this, r.concat(o.call(arguments)))
      }, i.guid = e.guid = e.guid || w.guid++, i
    }
  }, w.holdReady = function (e) {
    e ? w.readyWait++ : w.ready(!0)
  }, w.isArray = Array.isArray, w.parseJSON = JSON.parse, w.nodeName = N, w.isFunction = g, w.isWindow = y, w.camelCase = G, w.type = x, w.now = Date.now, w.isNumeric = function (e) {
    var t = w.type(e)
    return (t === 'number' || t === 'string') && !isNaN(e - parseFloat(e))
  }, typeof define === 'function' && define.amd && define('jquery', [], function () {
    return w
  })
  var Jt = e.jQuery
  var Kt = e.$
  return w.noConflict = function (t) {
    return e.$ === w && (e.$ = Kt), t && e.jQuery === w && (e.jQuery = Jt), w
  }, t || (e.jQuery = e.$ = w), w
}))
