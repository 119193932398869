import { invoiceService } from '../services'

const state = {
  invoices: {
    data: [],
    Fetching: '',
    Fetched: 'false',
  },
  current: {
    data: [],
    Fetching: '',
    Fetched: 'false',
  },
  temp: {
    data: [],
    Fetching: 'true',
    Fetched: 'false',
  },
  msg: '',
  success: '',
  failed: '',
  Fetching: '',
  Fetched: '',
}

const actions = {
  clearmsg({ commit }) {
    commit('clearAllMsg')
  },
  get_All_Invoice({ commit }, id) {
    commit('getAllRequest')

    invoiceService.getAll(id).then(
      invoice => commit('getAllSuccess', invoice),
      error => commit('getAllFailure', error)
    )
  },
  getListById({ commit }, id) {
    commit('getInvoiceRequest')

    invoiceService.getListById(id).then(
      invoice => commit('getCurrentSuccess', invoice),
      error => commit('getCurrentFailure', error)
    )
  },

  getInvoiceById({ commit }, id) {
    commit('getInvoiceRequest')

    invoiceService.getInvoiceById(id).then(
      invoice => commit('getCurrentInvoiceSuccess', invoice),
      error => commit('getCurrentFailure', error)
    )
  },
  update({ commit }, invoice) {
    invoiceService.update(invoice).then(
      invoice =>
        invoiceService.getAll().then(
          invoice => commit('getAllSuccess', invoice),
          error => commit('getAllFailure', error)
        )
      // error => console.log(error.toString())
    )
  },
  createInvoice({ commit }, invoice) {
    commit('FetchRequest')
    const id = invoice.id
    invoiceService
      .create(invoice)
      .then(
        invoice =>
          invoiceService.getAll(id).then(
            newinv => commit('successMsg', newinv),

            error => commit('failedMsg', error)
          )
        //  error => console.log(error.toString())
      )
      .catch(error => commit('failedMsg', error))
  },
  _delete({ commit }, id) {
    //  commit('deleteRequest', id)

    invoiceService.delete(id).then(
      invoice => commit('deleteInvoiceSuccess', id)
      // error => console.log(error)
    )
  },
}
/* eslint-disable*/
const mutations = {
  clearAllMsg(state){
    state.success = '',
    state.failed = '',
    state.msg = '',
    state.Fetched = null,
    state.Fetching = null
  },
  successMsg(state,invoice){
    state.invoices =  { data: invoice },
    state.invoices.Fetched =  true,
    state.invoices.Fetching = false,
    state.success = "Request was successfull, Created successfully",
    state.Fetched = true,
    state.Fetching = false
  },
  failedMsg(state,error){
    state.failed = "Request was unsuccessfull, Failed",
    state.Fetched = true,
    state.Fetching = false
  },
  FetchRequest(state){
    state.Fetched = false,
    state.Fetching = true
  },
  getAllRequest (state) {
    state.invoices.Fetched =  false,
    state.invoices.Fetching =  true
  },
  getInvoiceRequest (state) {
    state.current.Fetched =  false,
    state.current.Fetching =  true
  },
  getAllSuccess (state, invoice) {
    state.invoices = { data: invoice },
    state.invoices.Fetched =  true,
    state.invoices.Fetching = false,
    state.Fetched = true,
    state.Fetching = false,
//    state.success = "Request was successfull, Created successfully",
    state.msg = "Request is successfull."
  },
  getCurrentSuccess (state, invoice) {
    state.current = { ...invoice },
    state.current.Fetched = true,
    state.current.Fetching = false
  },
  getCurrentInvoiceSuccess (state, invoice) {
    state.temp = { ...invoice },
    state.temp.Fetched = true,
    state.temp.Fetching = false
  },
  getCurrentFailure (state, error) {
    state.msg = { error }
  },
  getAllFailure (state, error) {
    state.msg = { error },
    state.failed = "Request was unsuccessfull, Failed"
  },
  deleteInvoiceSuccess (state, id) {
    // remove deleted user from state
    state.msg = "Invoice is Deleted.",
    state.invoices.data = state.invoices.data.filter(invoice => invoice._id !== id)
  },

}

export const invoice = {
  namespaced: true,
  state,
  actions,
  mutations
}
