<template lang="html">
  <div class="">
    <Navbar />
    <main>
      <Alert :success="participant.success.length != 0" :fail="participant.failed.length != 0" />
      <div class="topbar has-image">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-4 col-md-2 col-2">
              <div class="d-flex align-items-md-center">
                <div class="my-2 mr-4 mr-md-5">
                  <button @click="$router.go(-1)" class="btn bordered-custom-round btn-round" type="button">
                    <span class="sr-only">Zpět</span>
                    <img src="../assets/img/ico-back.svg" alt="Zpět" class="mx-auto" />
                  </button>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-10 text-lg-center">
              <h1 class="mb-1">Poslat email</h1>
              <div class="email-summary d-md-none">
                <flag
                  :squared="false"
                  class="flag float-left mt-2 mr-2 flag-lg"
                  :iso="`${destination.temp.data[0].iso}`"
                />
                {{ destination.temp.data[0].country }}
                <br />
                <span>{{ changeDateFormatTo(term.startDate) }} - {{ changeDateFormatTo(term.endDate) }}</span>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 d-none d-md-block">
              <div class="container">
                <div class="row">
                  <div class="col">
                    <flag
                      :squared="false"
                      class="flag float-left mt-2 mr-2 flag-lg"
                      :iso="`${destination.temp.data[0].iso}`"
                    />
                    <span class="">{{ destination.temp.data[0].name }}</span>
                    <br />
                    <span>{{ changeDateFormatTo(term.startDate) }} - {{ changeDateFormatTo(term.endDate) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="card">
          <form class="card-body">
            <fieldset class="pb-5">
              <div class="form-group mb-4">
                <label for="sendEmailTo">Komu:</label>
                <div class="chip-container">
                  <div class="chip" v-for="(chip, i) of chips" :key="chip.label">
                    {{ chip }}
                    <button @click="deleteChip(i)" type="button" class="close" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <input
                    class="chip-email"
                    v-model="currentInput"
                    @keypress.enter="saveChip"
                    @keydown.delete="backspaceDelete"
                  />
                </div>
              </div>
              <p class="mb-1"><strong>Vzor e-mailu:</strong></p>
              <ol class="nav form-row email-templates mb-3">
                <li
                  :key="temp.id"
                  v-for="(temp, index) in this.participant.template.data"
                  class="col-lg-2 col-sm-4 col-6 nav-item"
                >
                  <a
                    @click.prevent="selectTemplate(index + 1)"
                    v-bind:class="{ active: isActive == index + 1 }"
                    class="nav-link"
                    href="#"
                  >
                    {{ temp.subject }}
                  </a>
                </li>
                <li class="col-lg-2 col-sm-4 col-6 nav-item">
                  <a @click.prevent="resetTemplate()" :class="{ active: isActive == false }" class="nav-link" href="#">
                    žádná
                  </a>
                </li>
              </ol>
              <div class="form-group mb-4">
                <label for="sendEmailSubject">Předmět:</label>
                <input v-model="subject" type="text" class="form-control form-control-sm" id="sendEmailSubject" />
              </div>
              <div class="form-group mb-0">
                <label for="sendEmailMessage">Vaše zpráva:</label>
                <quill-editor
                  style="background: white"
                  v-model="content"
                  ref="myQuillEditor"
                  :options="editorOption"
                  @blur="onEditorBlur($event)"
                  @focus="onEditorFocus($event)"
                  @ready="onEditorReady($event)"
                ></quill-editor>
              </div>
            </fieldset>
            <div v-if="isActive" class="row">
              <div class="attachment" v-if="this.subject != 'Zrušení přihlášky'">
                <h4 class="mt-3">Přílohy</h4>
                <span v-if="participant.contract.data.length > 0" class="mt-2 ml-3">
                  {{ participant.contract.data[0].name }}
                </span>
                <span v-if="invoice.invoices.data.length > 0" class="mt-2 ml-3">invoice.pdf</span>
                <span class="mt-2 ml-3">Czech_Surf_pojistka.pdf</span>
                <span class="mt-2 ml-3">Pojištění_storna_2023.pdf</span>
                <span class="mt-2 ml-3">VOP_2023.pdf</span>
                <span class="mt-2 ml-3">Czech_Surf_GL.pdf</span>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-5">
                <button
                  style=""
                  @click.prevent="send_Email()"
                  type="button"
                  class="button-style-round btn btn-primary mt-4 ml-3 ml-sm-4 mb-3"
                >
                  Poslat email
                </button>
                <button type="button" class="btn bordered-custom-round btn-round mt-4 ml-3 ml-sm-4 mb-3">
                  <label for="file-upload" class="custom-file-upload">
                    <span class="sr-only">Příloha</span>
                    <img src="../assets/img/ico-attachment.svg" alt="Příloha" class="mx-auto" />
                  </label>
                  <input multiple id="file-upload" ref="files" @change="handleFilesUpload()" type="file" />
                </button>
              </div>
              <div style="margin: 12px" class="col-xs-3">
                <div
                  v-if="participant.email.Fetching"
                  style="margin-top: 26px"
                  class="spinner-border spinner-border-sm myspinner"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <p :key="index" v-for="(file, index) in files">
              {{ file.name }}
              <img
                @click.prevent="deleteFile(index)"
                class="delete-button"
                width="20px"
                src="../assets/img/cancel.svg"
                alt="delete"
              />
            </p>
          </form>
        </div>
        <p class="text-center d-sm-none mt-4">
          <a href="">
            <img src="../assets/img/ico-logout.svg" alt="Odhlásit se" class="mr-2" />
            Odhlásit se
          </a>
        </p>
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable */
import Navbar from '@/components/Navbar.vue'
import Alert from '@/components/Alert.vue'
import '../assets/js/jquery.min.js'
import '../assets/js/bootstrap.bundle.min.js'
import {mapState, mapActions, mapGetters} from 'vuex'
import axios from 'axios'

export default {
  components: {
    Navbar,
    Alert,
  },
  data() {
    return {
      files: [],
      result: [],
      subject: '',
      type: null,
      content: '',
      isActive: false,
      chips: [],
      currentInput: '',
      editorOption: {
        // some quill options
      },
      currentTemplate: {},
      participant_id: null,
    }
  },
  methods: {
    showAttachements() {
      return (
        this.currentTemplate.type &&
        this.currentTemplate.type != 'Zrušení přihlášky'
      )
    },
    ...mapActions('participant', ['sendEmail', 'clearmsgPar', 'getTemplates']),
    changeDateFormatTo(date) {
      const [dd, mm, yy] = date.split(/-/g)
      return `${yy}. ${mm}. ${dd}`
    },
    getSelectedData(index) {
      const len = this.participant.template.data.length
      const template = this.participant.template.data[(index - 1) % len]
      return template.subject
    },
    selectTemplate(index) {
      this.isActive = index

      const len = this.participant.template.data.length
      const template = this.participant.template.data[(index - 1) % len]
      this.content = template.content
      this.subject = template.subject
      this.type = template.subject
      this.currentTemplate = template
    },
    send_Email() {
      this.handleFile().then((data) => {
        const email = {
          content: this.content,
          emails: this.chips,
          files: this.result,
          subject: this.subject,
          participant_id: this.participant_id,
          type: this.type,
        }
        this.sendEmail(email)
      })
    },
    async handleFile() {
      this.result = []
      for (const file of this.files) {
        await this.toBase64(file).then((data) => this.result.push(data))
      }
      return this.result
    },
    handleFilesUpload() {
      let uploadedFiles = this.$refs.files.files
      for (var i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i])
      }
      //  console.log(this.result)
    },
    async toBase64(file) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader()
        fileReader.onload = (e) => resolve(fileReader.result)
        fileReader.readAsDataURL(file)
      })
      return result_base64
    },
    deleteFile(index) {
      this.files.splice(index, 1)
    },
    saveChip() {
      const {chips, currentInput, set} = this
      ;((set && chips.indexOf(currentInput) === -1) || !set) &&
      chips.push(currentInput)
      this.currentInput = ''
    },
    deleteChip(index) {
      this.chips.splice(index, 1)
    },
    backspaceDelete({which}) {
      which == 8 &&
      this.currentInput === '' &&
      this.chips.splice(this.chips.length - 1)
    },
    resetTemplate() {
      this.isActive = false

      this.content = ''
      this.subject = ''
      this.type = null
      this.currentTemplate = {}
    },
    onEditorBlur(quill) {
      //    console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      //    console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      //    console.log('editor ready!', quill)
    },
    onEditorChange({quill, html, text}) {
      //      console.log('editor change!', quill, html, text)
      this.content = html
    },
  },
  watch: {
    getTitle() {
      if (typeof this.destination.temp.data[0] != 'undefined')
        document.title = this.destination.temp.data[0].name
    },
  },
  computed: {
    ...mapState({destination: 'destination'}),
    //...mapState({ term: "term" }),
    ...mapState({invoice: 'invoice'}),
    ...mapState({participant: 'participant'}),
    getTitle() {
      if (typeof this.destination.temp.data[0] != 'undefined')
        return this.destination.temp.data[0].name
    },
    editor() {
      return this.$refs.myQuillEditor.quill
    },
    term() {
      return !this.participant ? null : this.participant.temp.data[0].terms[0]
    },
  },
  mounted() {
    this.participant_id = this.participant.emailList.data[0].id
    this.chips = this.participant.emailList.data.map((a) => a.email)
    console.log(['term:', this.term, this.participant])
  },
  created() {
    document.title = this.getTitle
    if (this.destination.temp.data.length == 0) {
      this.$router.push('/')
    }
    this.clearmsgPar()
    this.getTemplates()
  },
}
</script>

<style lang="css" scoped>
.bordered-custom {
  border: 2px solid #da4c43; /* Green */
  border-radius: 20px !important;
  font-weight: bold;
}

.active {
  background-color: #fdf4ed;
}

.chip-container {
  border: 1px solid #ccc;
  min-height: 34px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.chip {
  margin: 4px;
  background: #f5f7f8;
  padding: 0px 4px;
  border: 1px solid #ccc;
  border-radius: 3px;
  display: flex;
  align-items: center;
}

.close {
  cursor: pointer;
  opacity: 0.56;
  color: red;
  margin-left: 8px;
}

.chip-email {
  flex: 1 1 auto !important;
  border: none !important;
  outline: none !important;
  padding: 4px !important;
}

.bordered-custom-round {
  background-color: white;
  color: black;
  border: 2px solid #da4c43; /* Green */
  border-radius: 20px !important;
  font-weight: bold;
}

input[type='file'] {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.custom-alert {
  padding: 9px !important;
}

.custom-alert-button {
  margin: -5px !important;
}
</style>
