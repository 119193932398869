<template>
  <div class="container-fluid login-page">
    <div class="row h-100 login-page-div">
      <div
        class="col-lg-8 col-md-7 col-sm-6 bg-white d-flex align-items-start align-items-sm-center justify-content-center flex-column p-4 p-md-6"
      >
        <img src="../assets/img/barel-logo.svg" alt="barel" class="d-sm-none mb-3" />
        <img src="../assets/img/login-theme.png" alt="barel" class="login-image" />
      </div>
      <div class="col-lg-4 col-md-5 col-sm-6 order-sm-first h-100 py-5">
        <div class="row justify-content-center">
          <div class="col-xl-9 col-sm-11 px-4">
            <img src="../assets/img/barel-logo.svg" alt="barel" class="d-none d-sm-block mb-6" />
            <h1>Přihlášení</h1>
            <p class="mb-sm-4"><strong>Přihlašte se prosím ke svému účtu</strong></p>

            <form class="form-login">
              <div class="form-group">
                <label for="loginInputEmail1">Váš e-mail:</label>
                <input v-model="email" type="email" class="form-control" id="loginInputEmail1" />
              </div>
              <div class="form-group mb-4">
                <label for="loginInputPassword1">Vaše heslo:</label>
                <input v-model="password" type="password" class="form-control" id="loginInputPassword1" />
              </div>
              <div class="row">
                <div class="col-sm-8">
                  <button
                    type="submit"
                    style="border-radius: 20px !important; font-weight: bold"
                    @click.prevent="$_emitdata()"
                    class="btn btn-primary"
                  >
                    Přihlásit se
                  </button>
                </div>
                <div class="col">
                  <div style="" class="col-xs-3">
                    <div v-if="account.Fetching == true" style="" class="spinner-border myspinner" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <p class="error">{{ alert.message }}</p>
            <p class="mt-4">
              <strong>Developed by</strong>
              <img src="../assets/img/snadnee-logo.svg" alt="snadnee" class="mb-1 ml-1" />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
/* eslint-disable */
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    email: '',
    password: '',
  }),
  computed:{
      ...mapState({ alert: 'alert' }),
            ...mapState({ account: 'account' }),
    },
mounted(){

},
created(){
  document.title = this.$route.name;
  this.clear()
  this.clearmsgAccount()
},
  methods: {
    ...mapActions('account', ['login', 'logout','clearmsgAccount']),
    ...mapActions('alert', ['clear']),
    $_emitdata(){
      const { email, password } = this
      this.login({ email,password })
    },
  }

}
</script>

<style lang="css" scoped>
.error{
  color: red !important;
}
.login-page{
  height: 100vh;
}
.login-page-div{
    height: 100vh;
}
</style>
