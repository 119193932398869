export const config = {
  // production & development

  /* */
  getUrl: '/api',
  setUrl: '/api',
  /* */

  // local:

  /* * /
  getUrl: 'http://127.0.0.1:8000/api',
  setUrl: 'http://127.0.0.1:8000/api'
  /* */
}
