import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { account } from './account.module'
import { alert } from './alert.module'
import { destination } from './destination.module'
import { participant } from './participant.module'
import { term } from './term.module'
import { address } from './address.module'
import { invoice } from './invoice.module'
Vue.use(Vuex)
const state = {
  // Current state of the application lies here.
}
const getters = {
  // Compute derived state based on the current state. More like computed property.
}
const actions = {}

const mutations = {
  // Mutate the current state
}

// You can assign a store to variable and export
export const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state,
  getters,
  mutations,
  actions,
  modules: {
    account,
    alert,
    destination,
    participant,
    term,
    address,
    invoice,
  },
})
