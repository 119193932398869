import axios from 'axios'
import { config } from './config.js'
export const participantService = {
  create,
  getAll,
  getListById,
  update,
  getParticipantById,
  getAllComment,
  createComment,
  _deleteComment,
  sendEmail,
  getTemplates,
  getAllContract,
  getUncomplete,
  createContract,
  delete: _delete,
  storno: _storno,
}

function getAuthToken() {
  const userData = window.localStorage.getItem('user')
  const user = userData ? JSON.parse(userData) : null
  if (user) {
    return `Bearer ${user.data.api_token}`
  }
}

function getBasicHeaders() {
  return {
    headers: {
      Accept: 'application/json',
      Authorization: getAuthToken(),
    },
  }
}

async function getTemplates() {
  try {
    const res = await axios.get(`${config.getUrl}/mail/templates/`, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}
async function getUncomplete() {
  try {
    const res = await axios.get(`${config.getUrl}/participant/uncomplete`, getBasicHeaders())
    //    console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}
async function sendEmail(email) {
  //  console.log(email)
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
    },
    ...email,
  }
  try {
    const res = await axios.post(`${config.getUrl}/mail/send/`, requestOptions, getBasicHeaders())
    //    console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}
async function getAllComment(id) {
  try {
    const res = await axios.get(`${config.getUrl}/comment/list/${id}`, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}
async function getAllContract(id) {
  try {
    const res = await axios.get(`${config.getUrl}/contract/list/${id}`, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function getAll() {
  try {
    const res = await axios.get(`${config.getUrl}/participant/list`, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}
async function createContract(contract) {
  //  console.log(participant, '@@@')
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
    },
    ...contract,
  }
  //  console.log(requestOptions, '@@@')
  try {
    const res = await axios.post(`${config.setUrl}/contract/create`, requestOptions, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function createComment(comment) {
  //  console.log(participant, '@@@')
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
    },
    ...comment,
  }
  //  console.log(participantType,'@@@')
  try {
    const res = await axios.post(`${config.setUrl}/comment/add`, requestOptions, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function create(participant) {
  //  console.log(participant, '@@@')
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
    },
    ...participant,
  }
  //  console.log(participantType,'@@@')
  try {
    const res = await axios.post(`${config.setUrl}/participant/add`, requestOptions, getBasicHeaders())
    //   console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function getListById(id) {
  try {
    const res = await axios.get(`${config.getUrl}/participant/list/${id}`, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function getParticipantById(id) {
  try {
    const res = await axios.get(`${config.getUrl}/participant/${id}`, getBasicHeaders())
    //    console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function update(participant) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
    },
    ...participant,
  }
  //  console.log(participantType,'@@@')
  try {
    const res = await axios.put(`${config.setUrl}/participant/update`, requestOptions, getBasicHeaders())
    // console.log(res.data)
    return res.data
  } catch (error) {
    //  console.log(error.response.data)
    return Promise.reject(error)
  }
}
async function _deleteComment(id) {
  try {
    const res = await axios.delete(`${config.getUrl}/comment/${id}`, getBasicHeaders())
    //    console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}
// prefixed function name with underscore because delete is a reserved word in javascript
async function _delete(id) {
  try {
    const res = await axios.delete(`${config.getUrl}/participant/${id}`, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}

async function _storno(id) {
  try {
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
      },
    }

    const res = await axios.put(`${config.getUrl}/participant/${id}/storno`, requestOptions, getBasicHeaders())
    //  console.log(res.data)
    return res.data
  } catch (error) {
    return Promise.reject(error)
  }
}
