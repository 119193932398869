<template lang="html">
  <div class="">
    <Navbar />
    <Alert :success="participant.success.length != 0" :fail="participant.failed.length != 0" />
    <div class="topbar has-image">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4 col-md-2 col-2">
            <div class="d-flex align-items-md-center">
              <div class="my-2 mr-4 mr-md-5">
                <button class="btn bordered-custom-round btn-round" type="button" @click="$router.go(-1)">
                  <span class="sr-only">Zpět</span>
                  <img src="../assets/img/ico-back.svg" alt="Zpět" class="mx-auto" />
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-10 text-lg-center">
            <h1 class="mb-1">Přidat účastníka</h1>
            <p v-if="destination.temp.data.length != 0" class="d-md-none">
              <img src="../assets/img/flags/flag-dominican-republic.svg" alt="Dominikána 2020" class="flag" />
              {{ destination.temp.data[0].name }}
            </p>
          </div>
          <div class="col-lg-4 col-md-6 d-none d-md-block text-right">
            <p v-if="destination.temp.data.length != 0">
              <flag :squared="false" class="flag flag-lg" :iso="`${destination.temp.data[0].iso}`" />
              {{ destination.temp.data[0].name }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="card">
        <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form">
          <form class="card-body">
            <h2 class="card-title">1. Základní údaje a adresa</h2>
            <div class="form-row">
              <div class="col-md">
                <fieldset class="px-3 pt-2">
                  <div class="form-group">
                    <label for="addParticipantName">Jméno a příjmení:</label>
                    <ValidationProvider v-slot="{ errors }" name="name" rules="required">
                      <vue-bootstrap-typeahead
                        id="addParticipantName"
                        v-model="formData.name"
                        class=""
                        :data="contactData"
                        @hit="findData($event)"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label for="addParticipantEmail">E-mail:</label>
                    <ValidationProvider v-slot="{ errors }" name="email" rules="required|email">
                      <input
                        id="addParticipantEmail"
                        v-model="formData.email"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label for="addParticipantPhone">Telefon:</label>
                    <ValidationProvider v-slot="{ errors }" name="phone" rules="required">
                      <input
                        id="addParticipantPhone"
                        v-model="formData.phone"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label for="addParticipantBirth">Datum narození:</label>
                    <ValidationProvider v-slot="{ errors }" name="dob" rules="required">
                      <date-pick
                        v-model="formData.dob"
                        :input-attributes="{ readonly: true }"
                        class=""
                        :display-format="'DD. MM. YYYY'"
                      ></date-pick>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label for="addParticipantIdCardNum">Číslo občanského průkazu:</label>
                    <ValidationProvider v-slot="{ errors }" name="dob" rules="required">
                      <input
                        id="addParticipantIdCardNum"
                        v-model="formData.id_card_number"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </fieldset>
              </div>
              <div class="col-md">
                <fieldset class="px-3 pt-2">
                  <div class="form-group">
                    <label for="addParticipantStreet">Ulice:</label>
                    <ValidationProvider v-slot="{ errors }" name="street" rules="required">
                      <input
                        id="addParticipantStreet"
                        v-model="formData.street"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label for="addParticipantCity">Město:</label>
                    <ValidationProvider v-slot="{ errors }" name="city" rules="required">
                      <input
                        id="addParticipantCity"
                        v-model="formData.city"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label for="addParticipantZIP">PSČ:</label>
                    <ValidationProvider v-slot="{ errors }" name="zip" rules="required">
                      <input
                        id="addParticipantZIP"
                        v-model="formData.zip"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label for="addParticipantCountry">Stát:</label>
                    <ValidationProvider v-slot="{ errors }" name="country" rules="required">
                      <select
                        id="addParticipantCountry"
                        v-model="formData.country"
                        class="custom-select custom-select-sm"
                      >
                        <option disabled value="country" selected>Země</option>
                        <option v-for="country in closeCountriesData" :key="country.id" :value="`${country.name}`">
                          {{ country.human_name }}
                        </option>
                      </select>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <label for="addParticipantCountry">Státní příslušnost:</label>
                    <ValidationProvider v-slot="{ errors }" name="country" rules="required">
                      <select
                        id="addParticipantCountry"
                        v-model="formData.nationality"
                        class="custom-select custom-select-sm"
                      >
                        <option disabled value="country" selected>Země</option>
                        <option v-for="country in closeCountriesData" :key="country.id" :value="`${country.name}`">
                          {{ country.human_name }}
                        </option>
                      </select>
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </fieldset>
              </div>
            </div>
            <hr class="my-4" />
            <h2 class="card-title">2. Spolucestující</h2>
            <fieldset v-for="(pass, index) in pass" :key="`${index}-pass`" class="mt-2">
              <div class="row mt-2">
                <div class="col-md-6 form-group">
                  <label for="addTravellerName">Jméno a příjmení:</label>
                  <input id="addTravellerName" v-model="pass.name" type="text" class="form-control form-control-sm" />
                </div>
                <div class="col-md-6 form-group">
                  <label for="addTravellerName">Datum narození:</label>
                  <date-pick
                    v-model="pass.dob"
                    :input-attributes="{ readonly: true }"
                    class=""
                    :display-format="'DD. MM. YYYY'"
                  ></date-pick>
                </div>
                <div class="col-md-6 form-group">
                  <label for="addTravellerIdCardNum">Číslo občanského průkazu:</label>
                  <input
                    id="addTravellerIdCardNum"
                    v-model="pass.id_card_number"
                    type="text"
                    class="form-control form-control-sm"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label for="addTravellerStreet">Ulice:</label>
                  <input
                    id="addTravellerStreet"
                    v-model="pass.street"
                    type="text"
                    class="form-control form-control-sm"
                  />
                </div>
                <div class="col-md-6 form-group pr-md-5">
                  <label for="addTravellerCity">Město:</label>
                  <input id="addTravellerCity" v-model="pass.city" type="text" class="form-control form-control-sm" />
                </div>
                <div class="col-md-6 form-group">
                  <label for="addTravellerZip">PSČ:</label>
                  <input id="addTravellerZip" v-model="pass.zip" type="text" class="form-control form-control-sm" />
                </div>
                <div class="col-md-6 form-group">
                  <label for="addTravellerCountry">Stát:</label>
                  <select id="addTravellerCountry" v-model="pass.country" class="custom-select custom-select-sm">
                    <option disabled value="country" selected>Země</option>
                    <option v-for="country in closeCountriesData" :key="country.id" :value="`${country.name}`">
                      {{ country.human_name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6 form-group">
                  <label for="addTravellerCountry">Státní příslušnost:</label>
                  <select id="addTravellerCountry" v-model="pass.nationality" class="custom-select custom-select-sm">
                    <option disabled value="country" selected>Státní příslušnost</option>
                    <option v-for="country in closeCountriesData" :key="country.id" :value="`${country.name}`">
                      {{ country.human_name }}
                    </option>
                  </select>
                </div>
                <img
                  class="delete-button mx-4 mb-2 ml-auto"
                  width="30px"
                  src="../assets/img/cancel.svg"
                  alt="delete"
                  @click.prevent="deleteRowPass(index)"
                />
              </div>
            </fieldset>
            <a class="link-new my-3 ml-3 ml-sm-4" @click.prevent="addRowPass">
              <span style="font-weight: 400; font-size: 16px" class="text-primary">
                přidat dalšího spolucestujícího
              </span>
              &nbsp;&nbsp;
              <img src="../assets/img/ico-new.svg" alt="přidat další Termín" />
            </a>
            <hr class="my-4" />
            <h2 class="card-title">3. Termíny, balíčky</h2>
            <fieldset class="mb-2">
              <div class="row">
                <div class="col-lg-6 col-md-9 d-sm-flex align-items-center pr-md-5">
                  <strong class="tabs-title pl-3">1. termín</strong>
                  <ul id="formTabs01" class="nav nav-tabs form-tabs flex-fill" role="tablist">
                    <li class="nav-item">
                      <a
                        id="date01-existing-tab"
                        class="nav-link active"
                        data-toggle="tab"
                        href="#date01-existing"
                        role="tab"
                        aria-controls="date01-existing"
                        aria-selected="true"
                      >
                        vybrat z existujících
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        id="date01-manual-tab"
                        class="nav-link"
                        data-toggle="tab"
                        href="#date01-manual"
                        role="tab"
                        aria-controls="date01-manual"
                        aria-selected="false"
                      >
                        zadat ručně
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <hr class="dotted" />
              <div class="tab-content px-3">
                <div id="date01-existing" class="tab-pane active" role="tabpanel" aria-labelledby="date01-existing-tab">
                  <div class="row">
                    <div class="col-md-6 pr-md-5">
                      <div class="form-group">
                        <label for="addParticipantDate01SelectDate">Datum:</label>

                        <select
                          id="addParticipantDate01SelectDate"
                          v-model="startDate"
                          class="custom-select custom-select-sm"
                          @change="updateTerm1($event)"
                        >
                          <option v-for="term1 in getPublicTerms()" :key="term1.id" :value="`${term1.id}`">
                            {{
                              `${changeDateFormatTo(term1.startDate)} - ${changeDateFormatTo(term1.endDate)} #${
                                term1.code
                              }`
                            }}
                          </option>
                        </select>
                      </div>

                      <div class="form-group">
                        <label for="addParticipantDate01SelectVoucher">Dárkový kupón:</label>
                        <input v-model="coupon" placeholder="žádný" type="text" class="form-control form-control-sm" />
                      </div>
                    </div>
                    <div class="col-md-6 pl-md-5">
                      <div class="form-group">
                        <label for="addParticipantDate01SelectPackage">Balíček:</label>
                        <select
                          id="addParticipantDate01SelectPackage"
                          v-model="package_id"
                          class="custom-select custom-select-sm"
                          @change="findValue(package_id)"
                        >
                          <option v-for="p in getPublicPackages()" :key="p.id" :value="p.id">
                            {{ p.name }}
                            <span>{{ p.price }}</span>
                          </option>
                        </select>
                        <input v-model="firstprice" type="hidden" name="" />
                      </div>
                      <div class="form-group">
                        <label for="addParticipantDate01SelectTransport">Doprava:</label>
                        <select
                          id="addParticipantDate01SelectTransport"
                          v-model="transport_id"
                          class="custom-select custom-select-sm addParticipantDate01SelectTransport"
                        >
                          <option
                            v-for="transport in destination.temp.data[0].transports"
                            :key="transport.id"
                            :value="transport.id"
                          >
                            {{ transport.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="date01-manual" class="tab-pane" role="tabpanel" aria-labelledby="date01-manual-tab">
                  <div class="row">
                    <div class="col-md-6 pr-md-5">
                      <div class="form-group">
                        <div class="">
                          <label for="">Kód:</label>
                          <input v-model="code" type="text" class="form-control form-control-sm" required />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-sm form-group">
                          <label for="addParticipantDate01DefineFrom">Datum od:</label>
                          <date-pick
                            v-model="startDate"
                            :input-attributes="{ readonly: true }"
                            class=""
                            :display-format="'DD. MM. YYYY'"
                          ></date-pick>
                        </div>
                        <div class="col-sm form-group">
                          <label for="addParticipantDate01DefineTo">Datum do:</label>
                          <date-pick
                            v-model="endDate"
                            :input-attributes="{ readonly: true }"
                            class=""
                            :display-format="'DD. MM. YYYY'"
                          ></date-pick>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="addParticipantDate01DefineVoucher">Dárkový kupón:</label>
                        <input v-model="coupon" placeholder="žádný" type="text" class="form-control form-control-sm" />
                      </div>
                    </div>
                    <div class="col-md-6 pl-md-5">
                      <div class="form-group">
                        <label :for="`package_name-${index}`">Balíček:</label>
                        <input
                          :id="`package_name-${index}`"
                          v-model="packageName"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <!--  <span class="error">{{ errors[0] }}</span> -->
                      </div>
                      <div class="form-group">
                        <label :for="`package_price-${index}`">Cena:</label>
                        <input
                          :id="`package_price-${index}`"
                          v-model="price"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <!-- <span class="error">{{ errors[0] }}</span> -->
                      </div>
                      <div class="form-group">
                        <label for="addParticipantDate01DefineTransport">Doprava:</label>
                        <select
                          id="addParticipantDate01DefineTransport"
                          v-model="transport_id"
                          class="custom-select custom-select-sm"
                        >
                          <option
                            v-for="transport in destination.temp.data[0].transports"
                            :key="transport.id"
                            :value="transport.id"
                          >
                            {{ transport.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset v-for="(term, index) in term" :key="index">
              <div class="row">
                <div class="col-lg-6 col-md-9 d-sm-flex align-items-center pr-md-5">
                  <strong class="tabs-title">{{ index + 2 }}. termín</strong>
                  <ul :id="`formTabs${index}`" class="nav nav-tabs form-tabs flex-fill" role="tablist">
                    <li class="nav-item">
                      <a
                        :id="`date${index}-existing-tab`"
                        class="nav-link"
                        data-toggle="tab"
                        :href="`#date${index}-existing`"
                        role="tab"
                        :aria-controls="`date${index}-existing`"
                        aria-selected="false"
                      >
                        vybrat z existujících
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        :id="`date${index}-manual-tab`"
                        class="nav-link active"
                        data-toggle="tab"
                        :href="`#date${index}-manual`"
                        role="tab"
                        :aria-controls="`date${index}-manual`"
                        aria-selected="true"
                      >
                        zadat ručně
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-md-1 ml-auto pt-3 text-right pr-4">
                  <img
                    class="delete-button"
                    width="30px"
                    src="../assets/img/cancel.svg"
                    alt="delete"
                    @click.prevent="deleteRowTerm(index)"
                  />
                </div>
              </div>
              <hr class="dotted" />
              <div class="tab-content">
                <div
                  :id="`date${index}-existing`"
                  class="tab-pane"
                  role="tabpanel"
                  :aria-labelledby="`date${index}-existing-tab`"
                >
                  <div class="row">
                    <div class="col-md-6 pr-md-5">
                      <div class="form-group">
                        <label for="addParticipantDate02SelectDate">Datum:</label>
                        <select
                          id="addParticipantDate02SelectDate"
                          v-model="term.id"
                          class="custom-select custom-select-sm"
                        >
                          <option v-for="term3 in getPublicTerms()" :key="term3.id" :value="`${term3.id}`">
                            {{ `${changeDateFormatTo(term3.startDate)} - ${changeDateFormatTo(term3.endDate)}` }}
                          </option>
                        </select>
                      </div>

                      <div class="form-group">
                        <label for="addParticipantDate02SelectVoucher">Dárkový kupón:</label>
                        <input
                          id=""
                          v-model="term.coupon"
                          placeholder="žádný"
                          type="text"
                          class="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 pl-md-5">
                      <div class="form-group">
                        <label for="addParticipantDate02SelectPackage">Balíček:</label>
                        <select
                          id="addParticipantDate02SelectPackage"
                          v-model="term.packageName"
                          class="custom-select custom-select-sm"
                          @change="addValue(term.packageName, index)"
                        >
                          <option v-for="p1 in getPublicPackages()" :key="p1.id" :value="p1.name">
                            {{ p1.name }}
                            <span>{{ p1.price }}</span>
                          </option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="addParticipantDate02SelectTransport">Doprava:</label>
                        <select
                          id="addParticipantDate02SelectTransport"
                          v-model="term.transport_id"
                          class="custom-select custom-select-sm"
                        >
                          <option
                            v-for="transport in destination.temp.data[0].transports"
                            :key="transport.id"
                            :value="transport.id"
                          >
                            {{ transport.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  :id="`date${index}-manual`"
                  class="tab-pane active"
                  role="tabpanel"
                  :aria-labelledby="`date${index}-manual-tab`"
                >
                  <div class="row">
                    <div class="col-md-6 pr-md-5">
                      <div class="form-group">
                        <div class="">
                          <label for="">Kód:</label>
                          <input v-model="term.code" type="text" class="form-control form-control-sm" required />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-sm form-group">
                          <label for="addParticipantDate02DefineFrom">Datum od:</label>
                          <date-pick
                            v-model="term.startDate"
                            :input-attributes="{ readonly: true }"
                            class=""
                            :display-format="'DD. MM. YYYY'"
                          ></date-pick>
                        </div>
                        <div class="col-sm form-group">
                          <label for="addParticipantDate02DefineTo">Datum do:</label>
                          <date-pick
                            v-model="term.endDate"
                            :input-attributes="{ readonly: true }"
                            class=""
                            :display-format="'DD. MM. YYYY'"
                          ></date-pick>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="addParticipantDate02DefineVoucher">Dárkový kupón:</label>
                        <input
                          id="addParticipantDate02DefineVoucher"
                          v-model="term.coupon"
                          type="text"
                          class="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 pl-md-5">
                      <div class="form-group">
                        <label for="addParticipantDate02DefinePackage">Balíček:</label>
                        <input
                          id="addParticipantDate02DefinePackage"
                          v-model="term.package_id"
                          type="text"
                          class="form-control form-control-sm"
                        />
                      </div>
                      <div class="form-group">
                        <label for="addParticipantDate02DefinePrice">Cena:</label>
                        <input
                          id="addParticipantDate02DefinePrice"
                          v-model="term.price"
                          type="text"
                          class="form-control form-control-sm"
                          required
                        />
                      </div>
                      <div class="form-group">
                        <label for="addParticipantDate02SelectTransport">Doprava:</label>
                        <select
                          id="addParticipantDate02SelectTransport"
                          v-model="term.transport_id"
                          class="custom-select custom-select-sm"
                        >
                          <option
                            v-for="transport in destination.temp.data[0].transports"
                            :key="transport.id"
                            :value="transport.id"
                          >
                            {{ transport.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <a class="link-new my-3 ml-3 ml-sm-4" @click.prevent="addRowTerm">
              <span style="font-weight: 400; font-size: 16px" class="text-primary">přidat další termín</span>
              &nbsp;&nbsp;
              <img src="../assets/img/ico-new.svg" alt="přidat další balíček" />
            </a>
            <fieldset class="mt-4 px-3">
              <div class="row">
                <div class="col-md-6 pr-md-5">
                  <div class="form-row price-overall">
                    <div class="col mt-2">Cena celkem:</div>
                    <div class="col text-right">
                      <strong>{{ totalItem }} Kč</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-center my-2">
                <div class="col-md pr-md-5">
                  <div class="form-row align-items-center">
                    <div class="col-lg-9 col-sm-8 col-6">Uhrazeno:</div>
                    <div class="col-lg-3 col-sm-4 col-6">
                      <ValidationProvider v-slot="{ errors }" name="paid" rules="numeric">
                        <input
                          id="addDestinationPackagePrice"
                          v-model="formData.paid"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
                <div class="col-md pl-md-5">
                  <div class="progress mt-5 mt-md-2 mb-2">
                    <div
                      class="progress-bar progress-bar-striped bg-info"
                      role="progressbar"
                      :style="`width: ${calcPercent(totalItem)}%`"
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <div class="form-row">
                    <p class="col-lg m-0">
                      Uhrazeno:
                      <strong>{{ calcPercent(totalItem) }}%</strong>
                    </p>
                    <p class="col-lg text-lg-right m-0">
                      Zbývá uhradit:
                      <strong>{{ calcDiff(totalItem) }} Kč</strong>
                    </p>
                  </div>
                </div>
              </div>
            </fieldset>
            <div class="row">
              <div class="col-md-3">
                <button
                  style=""
                  type="button"
                  class="button-style-round btn btn-primary mt-4 ml-3 ml-sm-4 mb-3"
                  @click.prevent="$_emitdata()"
                >
                  Uložit účastníka
                </button>
              </div>
              <div class="col">
                <div
                  v-if="participant.Fetching === true"
                  style="margin-top: 26px"
                  class="spinner-border myspinner"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <p class="text-center d-sm-none mt-4">
        <a href="">
          <img src="../assets/img/ico-logout.svg" alt="Odhlásit se" class="mr-2" />
          Odhlásit se
        </a>
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Navbar from '@/components/Navbar.vue'
import Alert from '@/components/Alert.vue'
import '../assets/js/jquery.min.js'
import '../assets/js/bootstrap.bundle.min.js'
import {config} from '@/services/config.js'
import {mapState, mapActions, mapGetters} from 'vuex'
import axios from 'axios'

export default {
  components: {
    Navbar,
    Alert,
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },

  data() {
    return {
      prices: [],
      firstprice: 0,
      totalBill: 0,
      prevRoute: null,
      startDate: '',
      endDate: '',
      price: 0,
      packageName: '',
      package_id: '',
      transport_id: '',
      coupon: '',
      code: '',
      pass: [],
      term: [],
      pname: '',
      tid: '',
      countriesData: [],
      closeCountriesData: [],
      formData: {
        name: '',
        email: '',
        street: '',
        city: '',
        country: '',
        phone: '',
        dob: '',
        zip: '',
        paid: 0,
        destinationId: '',
        passengers: [],
        term: [],
      },
    }
  },

  created() {
    document.title = this.getTitle
    if (this.destination.temp.data.length === 0) {
      this.$router.push('/')
    }
    this.clearmsgPar()
    this.listCountries()
    this.get_All_Address()
    const currentD = this.destination.temp.data[0].id
    //  console.log(currentD)
    this.$_getPackage(currentD)
  },

  computed: {
    ...mapState({destination: 'destination'}),
    ...mapState({currentTerm: 'term'}),
    ...mapState({participant: 'participant'}),
    ...mapState({address: 'address'}),
    contactData() {
      const arr = []
      this.address.addresss.data.forEach((e) => arr.push(`${e.name} #${e.id}`))
      //      console.log(arr)
      return arr
    },
    getTitle() {
      if (typeof this.destination.temp.data[0] != 'undefined')
        return this.destination.temp.data[0].name
    },
    totalItem: function () {
      var self = this
      let sum = parseFloat(this.firstprice) || 0

      sum += parseFloat(this.price) || 0
      this.prices.forEach(function (item) {
        //let price = self.findValueCustom(item.name)
        sum += parseFloat(item.price) || 0
      })
      this.term.forEach(function (item) {
        if (!Number.isNaN(item.price)) {
          //    console.log('NOT NaN')
          sum += parseFloat(item.price) || 0
        }
      })

      return sum
    },
  },

  watch: {
    getTitle() {
      if (typeof this.destination.temp.data[0] != 'undefined')
        document.title = this.destination.temp.data[0].name
    },
  },

  methods: {
    ...mapActions('participant', [
      'create',
      '_delete',
      'update',
      'clearmsgPar',
    ]),
    ...mapActions('destination', ['get_All_Packages']),
    ...mapActions('address', ['get_All_Address', 'getById']),

    getAuthToken() {
      const userData = window.localStorage.getItem('user')
      const user = userData ? JSON.parse(userData) : null
      if (user) {
        return `Bearer ${user.data.api_token}`
      }
    },

    getBasicHeaders() {
      return {
        headers: {
          Accept: 'application/json',
          Authorization: this.getAuthToken(),
        },
      }
    },

    getPublicTerms() {
      return this.currentTerm.current.data.filter((item) => item.custom != 1)
    },
    getPublicPackages() {
      return this.destination.packages.data.filter((item) => item.custom != 1)
    },
    findData(event) {
      // <name> #<id>
      const customer_id = event.split(' #')[1]
      const userData = this.address.addresss.data.find(
        (user) => user.id == customer_id
      )

      this.formData.name = userData.name
      this.formData.email = userData.email
      this.formData.street = userData.street
      this.formData.city = userData.city
      this.formData.country = userData.country
      this.formData.zip = userData.zip
      this.formData.phone = userData.phone
      this.formData.dob = userData.dob
      this.formData.nationality = userData.nationality
      this.formData.id_card_number = userData.id_card_number
    },
    changeDateFormatTo(date) {
      const [dd, mm, yy] = date.split(/-/g)
      return `${yy}. ${mm}. ${dd}`
    },
    $_getPackage(id) {
      //    console.log(id)
      this.get_All_Packages(id)
    },
    updateTerm1(event) {
      //  console.log(event.target.value)
      this.tid = event.target.value
    },
    addValue(name, key) {
      //  console.log(index)
      //const id = event.target.value
      const obj = this.destination.temp.data[0].packages.find(
        (o) => o.name == name
      )
      if (typeof obj != 'undefined') {
        var index = this.prices.findIndex((x) => x.id == key)
        if (index === -1) {
          this.prices.push({
            id: key,
            price: obj.price,
          })
        } else {
          this.prices[index].price = obj.price
        }
      }
    },

    findValue(id) {
      //  console.log(typeof name)
      if (typeof id != 'undefined') {
        //    console.log(name)
        const obj = this.destination.temp.data[0].packages.find(
          (o) => o.id == id
        )
        if (typeof obj != 'undefined') {
          this.firstprice = obj.price
        }
      }
    },

    findValueCustom(name) {
      //  console.log(typeof name)
      if (typeof name != 'undefined') {
        //    console.log(name)
        const obj = this.destination.temp.data[0].packages.find(
          (o) => o.name == name
        )
        if (typeof obj != 'undefined') {
          return obj.price
        }
        return 0
      }
      return 0
    },
    async $_emitdata() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) {
        console.log('Error')
      } else {
        this.pass.push({
          name: this.pname,
        })
        this.term.push({
          id: this.tid,
          coupon: this.coupon,
          code: this.code,
          packageName: this.packageName,
          package_id: this.package_id,
          transport_id: this.transport_id,
          price: this.price,
          startDate: this.startDate,
          endDate: this.endDate,
        })
        //    console.log(this.term)
        this.formData.term.push(...this.term)
        this.formData.passengers.push(...this.pass)
        this.formData.destinationId = this.destination.temp.data[0].id
        console.log(this.formData)

        this.create(this.formData)
        if (this.participant.failed.length != 0) {
          this.formData = []
        }

        this.term = []
        this.pass = []
        window.scrollTo(0, 0)
      }
    },
    addRowPass() {
      this.pass.push({
        name: '',
        dob: '',
        street: '',
        city: '',
        zip: '',
        id_card_number: '',
        country: '',
        nationality: '',
      })
    },
    deleteRowPass(index) {
      this.pass.splice(index, 1)
    },

    addRowTerm() {
      this.term.push({
        id: '',
        startDate: '',
        endDate: '',
        coupon: '',
        code: '',
        packageName: '',
        package_id: '',
        transport_id: '',
        price: 0,
      })
    },
    deleteRowTerm(index) {
      this.term.splice(index, 1)

      var key = this.prices.findIndex((x) => x.id == index)
      this.prices.splice(key, 1)
    },
    calcDiff(value) {
      return Math.abs(value - this.formData.paid)
    },
    calcPercent(value) {
      if (value != 0) {
        const result = ((this.formData.paid / value) * 100).toFixed(2)
        if (result > 100) {
          return 100
        } else {
          return result
        }
      } else {
        return 0
      }
    },
    async listCountries() {
      const res = await axios.get(
        `${config.getUrl}/countries/all`,
        this.getBasicHeaders()
      )
      this.countriesData = res.data
      this.closeCountriesData = this.countriesData.filter(
        (item) => item.close == 1
      )
    },
  },
}
</script>

<style lang="css" scoped>
.error {
  color: red !important;
}

.bordered-custom-round {
  background-color: white;
  color: black;
  border: 2px solid #da4c43;
  border-radius: 20px !important;
  font-weight: bold;
}

/* .topbar {
} */
</style>
