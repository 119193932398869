<template lang="html">
  <div class="">
    <Navbar />
    <main>
      <Alert :success="term.success.length != 0" :fail="term.failed.length != 0" />
      <div class="topbar has-image">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5">
              <div class="d-flex align-items-md-center">
                <div class="my-2 mr-4 mr-md-5">
                  <button @click="$router.go(-1)" class="btn bordered-custom-round btn-round" type="button">
                    <span class="sr-only">Zpět</span>
                    <img src="../assets/img/ico-back.svg" alt="Zpět" class="mx-auto" />
                  </button>
                </div>
                <div v-if="term.temp.Fetched" class="">
                  <div v-for="t in term.temp.data" :key="t.endDate">
                    <h1 class="mb-1" style="font-size: 32px">
                      {{ changeDateFormatTo(t.startDate) }} -
                      {{ changeDateFormatTo(t.endDate) }}
                    </h1>
                    <p
                      style="font-weight: 600"
                      v-if="destination.temp.Fetched == true && destination.temp.data.length != 0"
                    >
                      <flag :squared="false" class="flag flag-lg" :iso="`${getFlag}`" />
                      {{ getTitle }}
                    </p>
                    <div class="d-md-none mt-3">
                      <div
                        v-if="term.Deleting"
                        style=""
                        class="spinner-border spinner-border-sm myspinner mr-1"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <!-- <button
                        @click.prevent="$_deletefun"
                        class="btn bordered-custom-round btn-round mr-2"
                        type="button"
                      >
                        <span class="sr-only">Delete</span>
                        <img
                          width="20px"
                          src="../assets/img/delete.svg"
                          alt="Delete"
                          class="mx-auto"
                        />
                      </button> -->
                      <router-link to="/email/participant">
                        <button class="btn bordered-custom-round btn-round mr-2" type="button">
                          <span class="sr-only">Zpráva</span>
                          <img src="../assets/img/ico-message.svg" alt="Zpráva" class="mx-auto" />
                        </button>
                      </router-link>
                      <router-link
                        v-if="destination.temp.Fetched && destination.temp.data.length != 0"
                        :to="`/destination/update/${destination.temp.data[0].id}`"
                      >
                        <button class="btn bordered-custom-round btn-round mr-2" type="button">
                          <span class="sr-only">Editovat destinaci</span>
                          <img src="../assets/img/ico-edit.svg" alt="Editovat destinaci" class="mx-auto" />
                        </button>
                      </router-link>
                      <router-link class="btn btn-primary btn-round" to="/participant/add">
                        <span class="sr-only">Přidat účastníka</span>
                        <img src="../assets/img/ico-add.svg" alt="Přidat účastníka" />
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-7 d-none d-md-block text-right mt-3 mt-lg-0">
              <div v-if="term.Deleting" style="" class="spinner-border spinner-border-sm myspinner mr-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              <!-- <button
                @click.prevent="$_deletefun"
                class="btn bordered-custom-round btn-round mr-2"
                type="button"
              >
                <span class="sr-only">Delete</span>
                <img
                  width="20px"
                  src="../assets/img/delete.svg"
                  alt="Delete"
                  class="mx-auto"
                />
              </button> -->
              <router-link to="/email/participant">
                <button class="btn bordered-custom-round btn-round mr-2" type="button">
                  <span class="sr-only">Zpráva</span>
                  <img src="../assets/img/ico-message.svg" alt="Zpráva" class="mx-auto" />
                </button>
              </router-link>
              <router-link
                v-if="destination.temp.Fetched && destination.temp.data.length != 0"
                :to="`/destination/update/${destination.temp.data[0].id}`"
              >
                <button class="btn bordered-custom mr-2" type="button">
                  <span style="font-weight: 400" class="">Editovat destinaci</span>
                </button>
              </router-link>
              <router-link to="/participant/add">
                <button style="" type="button" class="btn btn-primary button-style-round">
                  <span class="">Přidat účastníka</span>
                  <img style="margin: 0px" src="../assets/img/ico-add.svg" alt="Přidat účastníka" class="ml-sm-2" />
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row align-items-center mb-2">
          <div class="col-lg-7 col-md-5 d-none d-md-block">
            <h2 style="" class="mb-2">Seznam účastníků</h2>
          </div>
          <div class="col-lg-3 col-md-4">
            <form @submit.prevent="searchData()">
              <div class="collapse d-sm-block" id="collapseSearch">
                <div class="input-group mb-2 mb-md-3">
                  <input
                    @input="searchData()"
                    v-model="searchQuery"
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="Hledat v seznamu"
                    id="filterSearch"
                    aria-describedby="filterSearchButton"
                    aria-label="Hledat v seznamu"
                  />
                  <div style="margin-left: 2px" class="input-group-append">
                    <button
                      @click.prevent="searchData()"
                      class="btn btn-search btn-sm"
                      type="button"
                      id="filterSearchButton"
                    >
                      Vyhledat
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-lg-2 col-md-3 d-none d-sm-block text-right">
            <p v-if="this.participant.current.Fetched" class="small mb-1">
              <img src="../assets/img/ico-items.svg" alt="Celkem" class="mr-2" />
              <span class="my-text-bold">{{ this.participant.current.data.length }}&nbsp;účastníků</span>
            </p>
          </div>
        </div>
        <div class="card">
          <ul class="nav pseudotable flex-column">
            <li class="nav-item pseudoheader">
              <span class="form-row">
                <span class="col-md col-sm-6 table-head">Jméno</span>
                <span class="col-md col-sm-6 table-head">Telefon</span>
                <span class="col-md col-sm-6 table-head">E-mail</span>
                <span class="col-md col-sm-6 table-head">Uhrazeno</span>
              </span>
            </li>
            <div v-if="this.participant.current.Fetching" class="d-flex justify-content-center">
              <div class="spinner-border myspinner" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <li v-else v-for="item in this.filteredData" :key="item.id" class="nav-item">
              <router-link :to="`/participant/${item.id}`" class="nav-link form-row">
                <span class="col-md col-sm-6 table-text my-text-bold">
                  {{ item.name }}
                </span>
                <span class="col-md col-sm-6 table-text my-text-bold">
                  {{ item.phone }}
                </span>
                <span class="col-md col-sm-6 table-text my-text-bold">
                  {{ item.email }}
                </span>
                <span class="col-md col-sm-6 table-text my-text-bold">
                  <span class="payment-status" :class="checkStatus(item.paid_status)">
                    {{ changeDateFormatTo(item.invoice_due_on) }}
                  </span>
                </span>
              </router-link>
            </li>
            <li v-if="this.participant.current.data == 0">
              <span>Žádný záznam</span>
            </li>
          </ul>
        </div>
        <p class="text-center d-sm-none mt-4">
          <a href="">
            <img src="../assets/img/ico-logout.svg" alt="Odhlásit se" class="mr-2" />
            Odhlásit se
          </a>
        </p>
      </div>
    </main>
  </div>
</template>
<script>
/* eslint-disable */
import Navbar from '@/components/Navbar.vue'
import Alert from '@/components/Alert.vue'
import '../assets/js/jquery.min.js'
import '../assets/js/bootstrap.bundle.min.js'
import { mapState, mapActions, mapGetters } from 'vuex'
import axios from 'axios'
export default {
  data() {
    return {
      searchQuery: '',
      filteredData: [],
      iso: '',
    }
  },
  components: {
    Navbar,
    Alert,
  },
  methods: {
    ...mapActions('participant', [
      'create',
      'get_All_Participant',
      'getListById',
      'update',
    ]),
    ...mapActions('destination', ['getDestinationById']),
    checkStatus(status) {
      console.log(status)
      switch (status) {
        case (0, 10):
          return 'unpaid'
          break
        case 20:
          return 'partly'
          break
        case 30:
          return 'paid'
          break
        default:
          return 'unpaid'
          break
      }
    },
    $_deletefun() {
      const termID = this.$router.currentRoute.params.id
      this._delete(termID)
    },
    changeDateFormatTo(datetime) {
      if (!datetime) return ''
      const [date, rest] = datetime.split(/T/g)
      const [dd, mm, yy] = date.split(/-/g)
      return `${yy}. ${mm}. ${dd}`
    },
    ...mapActions('term', ['getTermById', '_delete', 'clearmsg']),
    getRandomColor() {
      const colors = [
        'Tomato',
        'Orange',
        'DodgerBlue',
        'MediumSeaGreen',
        'Gray',
        'SlateBlue',
        'Violet',
        'LightGray',
      ]
      return colors[Math.floor(Math.random() * colors.length)]
    },
    searchData() {
      if (this.searchQuery.length > 0) {
        this.filteredData = this.participant.current.data.filter((item) => {
          return (
            item.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            item.email.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            item.phone.toLowerCase().includes(this.searchQuery.toLowerCase())
          )
        })
      } else {
        this.filteredData = this.participant.current.data
      }
      //  console.log(this.filteredData)
    },
  },
  mounted() {
    const termID = this.$router.currentRoute.params.id
    this.getTermById(termID).then(function () {})

    //   console.log(this.getID(),'@')
    setTimeout(() => {
      this.getDestinationById(this.term.temp.data[0].destination_id)
    }, 1000)

    //  console.log(this.destination.temp[0])

    this.getListById(termID)
  },
  created() {
    document.title = this.getTitle
    this.clearmsg()
    //  console.log(this.term.temp.data[0])
  },
  computed: {
    ...mapState({ participant: 'participant' }),
    ...mapState({ term: 'term' }),
    ...mapState({ destination: 'destination' }),
    getID() {
      if (typeof this.destination.temp.data[0] != 'undefined')
        return this.destination.temp.data[0].destination_id
    },
    filldata() {
      return this.participant.current.data
    },
    getTitle() {
      if (typeof this.destination.temp.data[0] != 'undefined')
        return this.destination.temp.data[0].name
    },
    getFlag() {
      if (typeof this.destination.temp.data[0] != 'undefined')
        return this.destination.temp.data[0].iso
    },
  },
  watch: {
    getID(val) {
      //  console.log('changed',val)
    },
    getFlag(val) {
      //  console.log('changed',val)
      //    console.log(this.iso,'###')
    },
    filldata() {
      if (typeof this.destination.temp.data[0] != 'undefined')
        this.filteredData = this.participant.current.data
    },
    getTitle() {
      document.title = this.destination.temp.data[0].name
    },
  },
}
</script>

<style lang="css" scoped>
.bordered-custom {
  background-color: white;
  color: black;
  border: 2px solid #da4c43; /* Green */
  border-radius: 20px !important;
  font-weight: bold;
}
.bordered-custom-round {
  background-color: white;
  color: black;
  border: 2px solid #da4c43; /* Green */
  border-radius: 20px !important;
  font-weight: bold;
}
.bordered-custom > span {
  padding: 12px;
  color: #da4c43;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  /* > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  } */
}
</style>
