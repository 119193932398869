import { addressService } from '../services'
import router from '../router.js'
const state = {
  addresss: {
    data: [],
    Fetching: 'true',
    Fetched: 'false',
  },
  baseAddress: {
    data: [],
    Fetching: 'true',
    Fetched: 'false',
  },
  current: {
    data: [],
    Fetching: 'true',
    Fetched: 'false',
  },
  temp: {
    data: [],
    Fetching: 'true',
    Fetched: 'false',
  },
  emailList: {
    data: [],
    Fetching: 'true',
    Fetched: 'false',
  },
  msg: '',
  success: '',
  failed: '',
  Fetching: null,
  Fetched: null,
  Deleting: null,
  Deleted: null,
}

const actions = {
  clearmsg({ commit }) {
    commit('clearAllMsg')
  },
  get_All_Address({ commit }) {
    commit('getAllRequest')

    addressService.getAll().then(
      address => commit('getAllSuccess', address),
      error => commit('getAllFailure', error)
    )
  },
  get_Base_All_Address({ commit }) {
    commit('getBaseAllRequest')

    addressService.getBaseAll().then(
      baseAddress => commit('getBaseAllSuccess', baseAddress),
      error => commit('getBaseAllFailure', error)
    )
  },
  getById({ commit }, id) {
    commit('getAddressRequest')

    addressService.getById(id).then(
      address => commit('getCurrentSuccess', address),
      error => commit('getCurrentFailure', error)
    )
  },

  update({ commit }, address) {
    const id = address.id
    commit('FetchRequest')
    addressService.update(address).then(
      address => {
        commit('successMsg')
        router.push(`/address/${id}`)
      },
      error => commit('failedMsg', error)
    )
    // error => console.log(error.toString())
  },
  create({ commit }, address) {
    addressService.create(address).then(
      address => {
        commit('successMsg')
        router.push(`/address/${address.id}`)
      },
      error => commit('failedMsg', error)

      //  error => console.log(error.toString())
    )
  },
  _delete({ commit }, id) {
    commit('deleteRequest', id)

    addressService.delete(id).then(
      address => {
        commit('deleteAddressSuccess', id)
        router.push('/address')
      },
      error => commit('deleteAddressFailure', error)
    )
  },
}
/* eslint-disable*/
const mutations = {
  FetchRequest(state){
    state.Fetched = false,
    state.Fetching = true
  },
  clearAllMsg(state){
    state.success = '',
    state.failed = '',
    state.msg = '',
    state.Fetched = null,
    state.Fetching = null
    state.Deleting = '',
    state.Deleted = null
  },
  successMsg(state){
    state.success = "Request was successfull, Created successfully",
    state.Fetched = true,
    state.Fetching = false
  },
  failedMsg(state,error){
    state.failed = error.response.data,
    state.Fetched = true,
    state.Fetching = false
  },
  getAllRequest (state) {
    state.addresss.Fetched =  false,
    state.addresss.Fetching =  true
  },
  getBaseAllRequest (state) {
    state.baseAddress.Fetched =  false,
    state.baseAddress.Fetching =  true
  },
  getAddressRequest (state) {
    state.current.Fetched =  false,
    state.current.Fetching =  true
  },
  getAllSuccess (state, address) {
    state.addresss = { ...address },
    state.addresss.Fetched =  true,
    state.addresss.Fetching = false,
    state.msg = "Request is successfull."
  },
  getBaseAllSuccess (state, baseAddress) {
    state.baseAddress = { ...baseAddress },
    state.baseAddress.Fetched =  true,
    state.baseAddress.Fetching = false,
    state.msg = "Request is successfull."
  },
  getCurrentSuccess (state, address) {
    state.current = { ...address },
    state.current.Fetched = true,
    state.current.Fetching = false,
    state.emailList = { ...address }
  },
  getCurrentAddressSuccess (state, address) {
    state.temp = { ...address },
    state.temp.Fetched = true,
    state.temp.Fetching = false
  },
  getCurrentFailure (state, error) {
    state.msg = { error }
  },
  getAllFailure (state, error) {
    state.msg = { error }
  },
  getBaseAllFailure (state, error) {
    state.msg = { error }
  },
  deleteAddressSuccess (state, id) {
    // remove deleted user from state
    state.Deleting = false,
    state.Deleted = true,
    state.success = "contact is Deleted",
    state.msg = "contact is Deleted.",
    state.addresss.data = state.addresss.data.filter(address => address._id !== id)
  },
  deleteAddressFailure (state, error) {
    state.msg = error.response.data
    state.failed = error.response.data
    state.Deleting = false,
    state.Deleted = true
  },
   deleteRequest(state){
    state.Deleting = true,
    state.Deleted = false
  },

}

export const address = {
  namespaced: true,
  state,
  actions,
  mutations
}
