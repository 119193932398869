import { termService } from '../services'
import router from '../router.js'
const state = {
  terms: {
    data: [],
    Fetching: '',
    Fetched: 'false',
  },
  current: {
    data: [],
    Fetching: '',
    Fetched: 'false',
  },
  temp: {
    data: [],
    Fetching: null,
    Fetched: null,
  },
  upcoming: {
    data: [],
    Fetching: null,
    Fetched: null,
  },
  msg: '',
  Deleting: null,
  Deleted: null,
  success: '',
  failed: '',
  Fetching: null,
  Fetched: null,
}

const actions = {
  clearmsg({ commit }) {
    commit('clearAllMsg')
  },
  getUpcoming({ commit }) {
    termService.getUpcoming().then(
      term => commit('getUpcomingSuccess', term),
      error => commit('getUpcomingFailure', error)
    )
  },
  get_All_Term({ commit }) {
    commit('getAllRequest')

    termService.getAll().then(
      term => commit('getAllSuccess', term),
      error => commit('getAllFailure', error)
    )
  },
  getListById({ commit }, id) {
    commit('getTermRequest')

    termService.getListById(id).then(
      term => commit('getCurrentSuccess', term),
      error => commit('getCurrentFailure', error)
    )
  },

  getTermById({ commit }, id) {
    commit('getTermRequest')

    termService.getTermById(id).then(
      term => {
        commit('getCurrentTermSuccess', term)
        console.log(term)
      },
      error => commit('getCurrentFailure', error)
    )
  },
  update({ commit }, term) {
    termService.update(term).then(
      term =>
        termService.getAll().then(
          term => commit('getAllSuccess', term),
          error => commit('getAllFailure', error)
        )
      // error => console.log(error.toString())
    )
  },
  create({ commit }, term) {
    termService.create(term).then(
      term =>
        termService.getAll().then(
          term => commit('getAllSuccess', term),
          error => commit('getAllFailure', error)
        )
      //  error => console.log(error.toString())
    )
  },
  _delete({ commit }, id) {
    commit('deleteRequest')

    termService.delete(id).then(
      term => {
        commit('deleteTermSuccess', id)
        router.go(-1)
      },
      error => commit('deleteTermFailure', error)
    )
  },
}
/* eslint-disable*/
const mutations = {
  clearAllMsg (state) {
    state.success = ''
    state.failed = ''
    state.msg = ''
    state.Fetched = null
    state.Fetching = null
    state.temp.Fetched = null
    state.temp.Fetching = null
    state.Deleted = null
    state.Deleting = null
  },
  FetchRequest(state){
    state.Fetched = false,
    state.Fetching = true
  },
  deleteTermSuccess (state, id) {
    // remove deleted user from state
    state.Deleting = false,
    state.Deleted = true,
    state.success = "Term is Deleted",
    state.msg = "Term is Deleted.",
    state.terms.data = state.terms.data.filter(term => term._id !== id)
  },
  deleteTermFailure (state, error) {
    state.msg = { error },
    state.failed = "Term is Deleted"
    state.Deleting = false,
    state.Deleted = true
  },
   deleteRequest(state){
    state.Deleting = true,
    state.Deleted = false
  },
  getAllRequest (state) {
    state.terms.Fetched =  false,
    state.terms.Fetching =  true
  },
  getTermRequest (state) {
    state.current.Fetched =  false,
    state.current.Fetching =  true
  },
  getAllSuccess (state, term) {
    state.terms = { data: term },
    state.terms.Fetched =  true,
    state.terms.Fetching = false,
    state.msg = "Request is successfull."
  },
  getUpcomingSuccess (state, term) {
    state.upcoming = { data:term },
    state.upcoming.Fetched = true,
    state.upcoming.Fetching = false
  },
  getUpcomingFailure (state, error) {
    state.upcoming.Fetched = true,
    state.upcoming.Fetching = false
  },
  getCurrentSuccess (state, term) {
    state.current = { ...term },
    state.current.Fetched = true,
    state.current.Fetching = false
  },
  getCurrentTermSuccess (state, term) {
    state.temp = { ...term },
    state.temp.Fetched = true,
    state.temp.Fetching = false
  },
  getCurrentFailure (state, error) {
    state.msg = { error }
  },
  getAllFailure (state, error) {
    state.msg = { error }
  },
  /*deleteTermSuccess (state, id) {
    // remove deleted user from state
    state.msg = "Room is Deleted.",
    state.terms.data = state.terms.data.filter(term => term._id !== id)
  },*/

}

export const term = {
  namespaced: true,
  state,
  actions,
  mutations
}
